import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  button: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: window.BRAND.primary_color,
    color: '#fff!important',
    textTransform: 'none',
    fontSize: theme.typography.h5.fontSize,
    borderRadius: theme.spacing(1),
  },
  description: {
    marginTop: theme.spacing(0.5),
    color: '#fff!important',
    fontSize: theme.typography.h6.fontSize,
  },
  playIcon: {
    fontSize: theme.typography.h3.fontSize,
  },
  playButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'fit-content',
    cursor: 'pointer',
  },
}));

export const PlayButton = ({ onClickPlayButton }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.playButtonContainer}
      onClick={() => onClickPlayButton()}
    >
      <Button className={classes.button}>
        <PlayArrowIcon className={classes.playIcon} />
      </Button>
      <Box className={classes.description}>Play</Box>
    </Box>
  );
};
