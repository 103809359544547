import React from 'react';
import is from 'is_js';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import SwiperCore, { Virtual, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { COLOR, truncateString, addModeToUrl, getAccessModeData } from 'global';
import { AccessModeFlag, AccessModeSource } from 'components';
import LazyImage from '../LazyImage';
import useSwiper from './hooks/useSwiper';
import 'swiper/swiper-bundle.min.css';
import './index.css';

// install Virtual & Navigation modules
SwiperCore.use([Virtual, Navigation]);

const SliderTooltip = (props) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} placement="top" />;
};

export default ({ carouselData, mode, setSwiper }) => {
  const classes = useStyles({ carouselData, mode });
  const {
    slidesPerView,
    sizes,
    placeholderClassName,
    breakpoints,
    onClickSlide,
  } = useSwiper({ carouselData });

  const subscriptionList = useSelector(
    ({ subscriptions: { subscriptionList } }) =>
      subscriptionList
        .filter(({ subscribed }) => subscribed)
        .map(({ code }) => code),
  );

  const getAccessData = (data) => getAccessModeData(data, subscriptionList);

  const onInit = (swiper) => {
    setSwiper(swiper);
  };

  return (
    <Swiper
      virtual
      spaceBetween={0}
      direction="horizontal"
      effect="fade"
      onInit={onInit}
      slidesPerView={slidesPerView}
      breakpoints={breakpoints}
    >
      {carouselData.items.mapWithKey((item, index) => {
        const accessData = getAccessData(item.modes);
        const tooltipTitle = truncateString(item.description || '', 25);
        const url = addModeToUrl(item.url, mode);
        const [{ sources }, { width }] = [accessData || {}, sizes];
        const onClick = () => onClickSlide(url);

        return (
          <SwiperSlide key={item.key} virtualIndex={index} onClick={onClick}>
            <SliderTooltip title={tooltipTitle}>
              <Box className={classes.sliderItem}>
                <Box className={classes.itemContainer}>
                  {item.image ? (
                    <LazyImage
                      {...{ ...sizes, alt: item.name, src: item.image }}
                    />
                  ) : (
                    <Box className={classes[placeholderClassName]} />
                  )}
                  {accessData?.label && (
                    <AccessModeFlag label={accessData.label} />
                  )}
                </Box>
                {!!accessData?.sources?.length && (
                  <AccessModeSource {...{ sources, width, url }} />
                )}
              </Box>
            </SliderTooltip>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: COLOR.b_white_light,
    paddingBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    color: 'white',
    '& *': { display: 'inline-block' },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  titleLink: {
    color: 'white',
    textDecoration: 'none !important',
    fontSize: theme.typography.body1.fontSize,
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
    '&:hover': { color: theme.palette.primary.main },
    '& span, & svg': { verticalAlign: 'top' },
  },
  arrows: {
    position: 'absolute',
    right: 0,
    display: (props) => (props.carouselData.length <= 3 ? 'none' : 'inherit'),
    '@media screen and (max-width: 1020px)': {
      display: (props) => props.carouselData.length === 3 && 'inherit',
    },
    '@media screen and (max-width: 800px)': {
      display: (props) => props.carouselData.length === 2 && 'inherit',
    },
  },
  arrowButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sliderItem: {
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
  },
  itemContainer: {
    display: 'flex',
    position: 'relative',
  },
  showsItem: {
    width: '210px',
    height: is.safari() ? '56.25%' : 'auto',
  },
  movieItem: {
    width: '148px',
    height: is.safari() ? '142.5%' : 'auto',
  },
  otherItem: {
    height: theme.spacing(18),
    width: theme.spacing(18),
  },
}));
