import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  videoContainer: (props) => ({
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& .jwplayer.jw-flag-aspect-mode': {
      height: '100% !important',
      display: 'block !important',
    },
    '& .jw-icon-rewind': {
      display: 'none',
    },
    '& .jw-icon-playback': {
      display: 'none',
    },
    '& .jw-settings-sharing': {
      display: 'none',
    },
    '& .jw-icon-settings': {
      display: 'none',
    },
  }),
}));

export const PlayerRedemption = ({ onComplete }) => {
  const classes = useStyles();
  React.useEffect(() => {
    loadPlayer();
  }, [loadPlayer]);

  const loadPlayer = () => {
    if (window.jwplayer) {
      window.jwplayer.key = 'NlWEzJDuEQ6ohoYwIaUXrE8IAdqST2pYap6uXQ==';

      window.jwplayer('jwPlayer').setup({
        playlist:
          '//cdn.jwplayer.com/v2/playlists/hOeEs0xD?recommendations_playlist_id=OiDoRjGw',
        playbackRateControls: false,
        autostart: true,
        primary: 'html5',
        width: '100%',
        controls: false,
      });

      window.jwplayer('jwPlayer').on('complete', () => onComplete());

      window.jwplayer('jwPlayer').on('error', () => {
        window.jwplayer('jwPlayer').play(true);
      });
    }
  };

  return (
    <Box className={classes.videoContainer}>
      <Box id="jwPlayer" />
    </Box>
  );
};
