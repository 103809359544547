import qs from 'querystring';
import axios from 'axios';

import { SOTALCLOUD } from 'global';
import { setMemberShip } from '../redux/reducers/membership';

export const sotalCloudAuthorize = (username, password) => {
  delete axios.defaults.headers.common.Authorization;
  const headers = {};
  headers['Content-Type'] = 'application/x-www-form-urlencoded';

  const bodyRequest = {
    grant_type: 'password',
    device_id: 'A41A0F70-6B7A-45CB-94B9-ED6E13F692EA',
    client_id: 'cloud:freecast_ottweb_device',
    username,
    password,
    device_model: 'web',
    device_os: 'web',
    device_os_version: '11.4',
    device_app_version: '1.0.0',
    device_app_name: 'sotalcloud',
  };

  return (dispatch) => {
    axios
      .post(
        'https://discovery-ams.sotalcloud.com/sdp/v2/authorize',
        qs.stringify(bodyRequest),
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setMemberShip({ field: 'sotalToken', value: response.data.data }),
          );
        }
      });
  };
};

export const submitUpgradePlan = (token, data) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(`${window.API_SERVICES_DOMAIN}/members/api/v0/upgrade/`, data, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) => error);
};

export const submitDonwgradePlan = (token, data) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(
      `${window.API_SERVICES_DOMAIN}/members/api/v0/downgrade/`,
      { package_slug: data },
      {
        headers,
      },
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export const fetchChargeBee = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    axios
      .get(`${window.API_SERVICES_DOMAIN}/chargebee/api/user/cards/`, {
        headers,
      })
      .then((response) => {
        if (response.data.status.toUpperCase() === 'OK') {
          dispatch(setMemberShip({ field: 'chargeBee', value: response.data }));
        }
      })
      .catch((error) => error);
  };
};

export const updateChargeBee = (token, postData) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(
      `${window.API_SERVICES_DOMAIN}/chargebee/api/user/card/update/`,
      postData,
      {
        headers,
      },
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export const updateCardInfo = (cardInfo) => (dispatch) =>
  dispatch(setMemberShip({ field: 'chargeBee', value: cardInfo }));

export const fetchMembershipPlanList = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    axios
      .get(
        `${window.API_SERVICES_DOMAIN}/chargebee/api/user/subscription/list/`,
        {
          headers,
        },
      )
      .then((response) => {
        if (response.data.status.toUpperCase() === 'OK') {
          return dispatch(
            setMemberShip({ field: 'memberShipAddOns', value: response.data }),
          );
        }
      })
      .catch((error) => error);
  };
};

export const updateMembership = (token, subscribeCode, unSubscribeCode) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(
      `${window.API_SERVICES_DOMAIN}/chargebee/api/user/subscriptions/`,
      {
        subscribe_addon_code: subscribeCode,
        unsubscribe_addon_code: unSubscribeCode,
      },
      {
        headers,
      },
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export const getPackageDetail = (token, slug) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    axios
      .get(`${SOTALCLOUD.API}${slug}/`, {
        headers,
      })
      .then((response) =>
        dispatch(
          setMemberShip({ field: 'sotalCloudPlan', value: response.data }),
        ),
      )
      .catch((error) => error);
  };
};

export const setPackageDetail = (value) => (dispatch) =>
  dispatch(setMemberShip({ field: 'sotalCloudPlan', value }));
