import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { setFilterData } from 'apis/filter';
import {
  PAGE_FILTER,
  MOVIE_RATINGS,
  SHOW_RATINGS,
  getSortValues,
  customUrlSearchParams,
} from 'global';
import { ShowMovieToggle } from './ShowMovieToggle';
import { FreePremiumToggle } from './FreePremiumToggle';
import { FilterDropDown } from './FilterDropDown';

const useStyles = makeStyles(() => ({
  filterBtnContainer: { display: 'inline-block' },
  tags: {
    color: 'white',
    marginLeft: 10,
    marginBottom: 10,
    '& svg': { color: 'white !important' },
  },
}));

export const FilterComponent = ({
  pageslug,
  havePageType,
  haveSort,
  dropdownList,
  pageType,
  onPage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nonUsedKey, setNonUsedKey] = useState(Date.now());
  const [
    showGenres,
    movieGenres,
    filterData,
  ] = useSelector(({ genres, pageData }) => [
    genres.show,
    genres.movie,
    pageData.filterData,
  ]);
  const location = useLocation();

  useEffect(() => {
    if (pageType) {
      const searchParams = customUrlSearchParams(location.search);
      const newFilterData = { ...filterData };
      if (searchParams.ordering) newFilterData.ordering = searchParams.ordering;
      dispatch(
        setFilterData({
          ...newFilterData,
          params: {
            ...filterData.params,
            pageType,
          },
        }),
      );
    }
    return () => {
      dispatch(setFilterData(PAGE_FILTER.FILTER_DATA));
    };
  }, []);

  useEffect(() => {
    const searchParams = customUrlSearchParams(location.search);
    const newFilterData = { ...PAGE_FILTER.FILTER_DATA };
    if (searchParams.ordering) {
      newFilterData.ordering = searchParams.ordering;
      const sortList = getSortValues(pageType);
      newFilterData.selectedOptions = [
        ...newFilterData.selectedOptions,
        {
          type: 'ordering',
          label: sortList.filter((d) => newFilterData.ordering === d.value)[0]
            .label,
        },
      ];
    }
    dispatch(setFilterData(newFilterData));
    setNonUsedKey(Date.now());
  }, [pageslug]);

  const handleSelectedOptionClick = (type, value) => {
    const { params, selectedOptions } = filterData;
    let newOptions = [...selectedOptions];
    newOptions = newOptions.filter((item) => {
      if (item.type === type && item.value === value) return false;
      return true;
    });
    if (type === 'ordering') {
      dispatch(
        setFilterData({
          ...filterData,
          selectedOptions: newOptions,
          ordering: '',
        }),
      );
    } else {
      const newParams = params[type].filter((el) => el !== value);
      let filteredShowGenres = showGenres;
      let filteredMovieGenres = movieGenres;
      let filteredShowRatings = SHOW_RATINGS;
      let filteredMovieRatings = MOVIE_RATINGS;
      if (type === 'genre') {
        filteredShowGenres = filteredShowGenres.filter((g) => g.slug === value);
        filteredMovieGenres = filteredMovieGenres.filter(
          (g) => g.slug === value,
        );
      }
      if (type === 'rating') {
        filteredShowRatings = filteredShowRatings.filter(
          (r) => r.value === value,
        );
        filteredMovieRatings = filteredMovieRatings.filter(
          (r) => r.value === value,
        );
      }
      dispatch(
        setFilterData({
          ...filterData,
          selectedOptions: newOptions,
          params: {
            ...params,
            [type]: newParams,
          },
          isReloadShow:
            filteredShowGenres.length > 0 && filteredShowRatings.length > 0,
          isReloadMovie:
            filteredMovieGenres.length > 0 && filteredMovieRatings.length > 0,
        }),
      );
    }
  };

  const renderSelectedOptions = () => {
    if (!filterData) {
      return null;
    }
    const { selectedOptions, params } = filterData;
    const displayOptions = selectedOptions.map((option) => {
      if (option.type === 'genre') {
        let genre;
        if (params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE) {
          genre = movieGenres.find((g) => g.slug === option.value);
        } else {
          genre = showGenres.find((g) => g.slug === option.value);
        }
        if (genre) {
          return {
            ...option,
            value: genre.slug,
            label: genre.name,
          };
        }
        return false;
      }
      if (option.type === 'rating') {
        let rating;
        if (params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE) {
          rating = MOVIE_RATINGS.find((r) => r.value === option.value);
        } else {
          rating = SHOW_RATINGS.find((r) => r.value === option.value);
        }
        if (rating) {
          return {
            ...option,
            value: rating.value,
            label: rating.label,
          };
        }
        return false;
      }
      return option;
    });

    return (
      <Box textAlign="left">
        {displayOptions.map(
          (option) =>
            option !== false && (
              <Chip
                className={classes.tags}
                label={option.label}
                key={option.key}
                onClick={() =>
                  handleSelectedOptionClick(option.type, option.value)
                }
                onDelete={() =>
                  handleSelectedOptionClick(option.type, option.value)
                }
                color="primary"
                size="small"
                deleteIcon={<ClearIcon />}
              />
            ),
        )}
      </Box>
    );
  };

  const renderFilters = () => (
    <Box className={classes.boxFilter} display="block">
      <Box className={classes.filterBtnContainer} mb={2}>
        {havePageType && <ShowMovieToggle />}
        {haveSort && <FilterDropDown label={'Sort'} dropdown={'SORT'} />}
      </Box>
      <Box className={classes.filterBtnContainer} mb={2}>
        <FreePremiumToggle nonUsedKey={nonUsedKey} onPage={onPage} />
      </Box>
      <Box className={classes.filterBtnContainer} mb={2}>
        {dropdownList.mapWithKey((dropdown) => (
          <FilterDropDown
            key={dropdown.key}
            dropdown={dropdown}
            onPage={onPage}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      {renderFilters()}
      {renderSelectedOptions()}
    </>
  );
};
