import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Hidden } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import { withResizeDetector } from 'react-resize-detector';

import { FavoriteButton } from 'components';
import { COLOR, WIDTH_BREAK_POINT } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    fontSize: theme.typography.h4.fontSize,
    borderBottom: '1px solid #dee2e6 !important',
  },
  desctiptionContainter: (props) => ({
    display: props.width > WIDTH_BREAK_POINT ? 'flex' : 'block',
    justifyContent: props.width > WIDTH_BREAK_POINT ? 'start' : 'center',
    textAlign: props.width > WIDTH_BREAK_POINT ? 'left' : 'center',
  }),
  imgContainer: {
    position: 'relative',
    width: theme.spacing(25),
    '&:hover > div': {
      height: theme.spacing(6),
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '& > div': {
      height: 0,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  img: {
    width: '100%',
    height: '100%',
  },
  favoriteBox: {
    position: 'absolute',
    bottom: 0,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: 0,
    backgroundColor: 'rgba(0, 156, 254, 0.9)',
  },
  textContainer: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: COLOR.b_white_mligth,
    paddingLeft: theme.spacing(2),
  },
  description: {
    fontSize: '1.12rem',
  },
  controlContainer: (props) => ({
    display: props.width > WIDTH_BREAK_POINT ? 'flex' : 'block',
    position: props.width > WIDTH_BREAK_POINT ? 'relative' : 'inherit',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom:
      (props.bIsFromRating || props.genreData === 'No Source') &&
      props.width > WIDTH_BREAK_POINT
        ? theme.spacing(4)
        : theme.spacing(1),
  }),
  controlButtonContainer: (props) => ({
    display: 'flex',
    justifyContent: props.width > WIDTH_BREAK_POINT ? 'start' : 'center',
  }),
  switchButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  switchButtonNormal: {
    backgroundColor: 'rgba(1,1,1,0)',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  switchButtonActive: {
    backgroundColor: theme.palette.primary.main,
  },
  pagination: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    position: props.width > WIDTH_BREAK_POINT ? 'absolute' : 'inherit',
    right: 0,
    '& button.MuiPaginationItem-textPrimary.Mui-selected': {
      color: 'white',
    },
    '& button.MuiPaginationItem-textPrimary': {
      color: `${COLOR.b_white_light}`,
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiPaginationItem-page': {
      '@media screen and (max-width: 643px)': {
        height: 26,
        margin: '0 1px',
        padding: '0 4px',
        minWidth: 26,
        borderRadius: 13,
      },
    },
  }),
  loadingBoxContainer: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.width > WIDTH_BREAK_POINT ? 'start' : 'center',
  }),
  loadingBox: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const OverviewContainer = (props) => {
  const {
    bIsFromGenre,
    bIsFromNetwork,
    bIsFromRating,
    genreData,
    bIsFavorite,
    onFavorite = () => {},
    bIsMovie,
    pageNumber = 1,
    onPage,
    onSwitch,
    model,
    pageCount,
  } = props;
  const classes = useStyles(props);
  const [showsCount, moviesCount] = useSelector((s) => [
    s.pageData.showsCount,
    s.pageData.moviesCount,
  ]);

  return (
    <Box className={classes.container}>
      {(bIsFromGenre || bIsFromNetwork) && (
        <Box className={classes.desctiptionContainter}>
          <Box display="flex" justifyContent="center">
            <Box className={classes.imgContainer}>
              <img
                className={classes.img}
                src={genreData.image}
                alt={genreData.name}
              />
              <Box className={classes.favoriteBox}>
                <FavoriteButton
                  favoriteFlag={bIsFavorite}
                  onFavorite={onFavorite}
                  model={model}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.textContainer}>
            <Box>{bIsFromGenre ? `GENRE - ${genreData.name}` : `Overview`}</Box>
            {!bIsFromGenre && genreData.description && (
              <Box className={classes.description}>{genreData.description}</Box>
            )}
          </Box>
        </Box>
      )}
      {bIsFromRating && (
        <Box className={classes.desctiptionContainter}>
          <Box className={classes.textContainer}>
            {`${bIsMovie ? 'Movies' : 'Shows'} by rating: ${genreData}`}
          </Box>
        </Box>
      )}
      {genreData === 'No Source' && (
        <Box className={classes.desctiptionContainter}>
          <Box className={classes.textContainer}>
            {bIsMovie ? 'Movies' : 'Shows'}
          </Box>
        </Box>
      )}
      <Box className={classes.controlContainer}>
        {(bIsFromGenre || bIsFromNetwork) && (
          <Box className={classes.controlButtonContainer}>
            <Box
              className={clsx(classes.switchButton, {
                [classes.switchButtonActive]: !bIsMovie,
                [classes.switchButtonNormal]: bIsMovie,
              })}
              onClick={() => onSwitch(false)}
            >
              TV SHOWS ({showsCount})
            </Box>
            <Box
              className={clsx(classes.switchButton, {
                [classes.switchButtonActive]: bIsMovie,
                [classes.switchButtonNormal]: !bIsMovie,
              })}
              onClick={() => onSwitch(true)}
            >
              MOVIES ({moviesCount})
            </Box>
          </Box>
        )}
        {pageCount > 0 && (
          <Box className={classes.pagination}>
            <Hidden mdUp>
              <Pagination
                count={pageCount}
                color="primary"
                page={pageNumber}
                boundaryCount={2}
                onChange={(e, number) => onPage(number)}
                siblingCount={0}
                size="small"
              />
            </Hidden>
            <Hidden smDown>
              <Pagination
                count={pageCount}
                color="primary"
                page={pageNumber}
                boundaryCount={2}
                siblingCount={0}
                onChange={(e, number) => onPage(number)}
              />
            </Hidden>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const Overview = withResizeDetector(OverviewContainer, {
  refreshMode: 'throttle',
  refreshRate: 500,
});
