import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, withWidth, isWidthUp, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';

import { COLOR, getItemModel, getFilteredSliderItems } from 'global';
import { FavoriteButton } from '../FavoriteButton';

const useStyles = makeStyles((theme) => ({
  container: {
    '& img': {
      width: '100%',
      backgroundSize: 'cover',
      '@media screen and (min-width: 1201)': { minHeight: '242px' },
    },
    display: 'flex !important',
    position: 'inherit',
    '@media screen and (max-width: 1200px)': { position: 'relative' },
  },
  videoContainer: {
    width: '41.666667%',
    backgroundColor: 'black',
    '@media screen and (max-width: 1440px)': { width: '45%' },
    '@media screen and (max-width: 1200px)': { width: '100%' },
  },
  textContainer: (props) => ({
    backgroundImage:
      'linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0))',
    width: '58.333333%',
    position: 'inherit',
    top: 0,
    left: 0,
    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.75)',
    display: `${
      props.sliderCount === 1 && !props.sliderData[0].image
        ? 'contents'
        : 'flex'
    }`,
    height: 'auto',
    '@media screen and (max-width: 1440px)': { width: '55%' },
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      position: 'absolute',
      height: '100%',
      padding: theme.spacing(4),
    },
  }),
  logo: {
    display: 'block',
    '& img': {
      height: 100,
      width: 'auto',
      '@media (max-width: 1720px)': { height: 80 },
      '@media (max-width: 1535px)': { height: 50 },
    },
  },
  title: (props) => ({
    paddingTop: !isWidthUp('md', props.width) ? theme.spacing(1) : 0,
    fontSize: theme.typography.h4.fontSize,
    color: COLOR.b_white_light,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  }),
  subTitle: (props) => ({
    fontSize: isWidthUp('sm', props.width)
      ? theme.typography.subtitle1.fontSize
      : theme.typography.subtitle2.fontSize,
    color: COLOR.b_white_light,
  }),
  description: (props) => ({
    marginTop: theme.spacing(1),
    fontSize: isWidthUp('sm', props.width)
      ? theme.typography.subtitle1.fontSize
      : theme.typography.subtitle2.fontSize,
    color: COLOR.b_white_light,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    maxHeight: theme.spacing(8),
    margin: '0 auto',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  }),
  button: (props) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    fontSize: isWidthUp('sm', props.width)
      ? theme.typography.h6.fontSize
      : theme.typography.subtitle1.fontSize,
    color: 'white',
    textDecoration: 'none !important',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unmutedButton: {
    position: 'absolute',
    left: '10px',
    top: '5px',
    color: '#fff',
    backgroundColor: '#252525',
    zIndex: '1000',
  },
  slickSlider: (props) => ({
    '& .slick-dots': {
      bottom: theme.spacing(2),
      backgroundColor: 'rgba(0,0,0,0.75)',
      display: 'flex !important',
      justifyContents: 'center',
      alignItems: 'center',
      width: 'auto',
      marginLeft: '20.8333335%',
      transform: 'translateX(-50%)',
      '& li': {
        '& button::before': {
          width: theme.spacing(2.5),
          fontSize: `${theme.typography.overline.fontSize} !important`,
          color: `${COLOR.dot_color} !important`,
          opacity: 0.5,
          textShadow: `0 0 ${COLOR.dot_text_shadow} !important`,
        },
        '& button:focus::before': { opacity: 0.5 },
        '&.slick-active button:focus::before': { opacity: 1 },
        '&.slick-active button::before': {
          width: theme.spacing(2.5),
          color: `${COLOR.dot_color} !important`,
          opacity: '1',
          textShadow: '0 0 $rgba(0,0,0,0.75) !important',
        },
      },
    },
    '& .slider-arrow': {
      cursor: 'pointer',
      zIndex: 10,
      bottom: theme.spacing(2),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      backgroundColor: 'rgba(0,0,0,0.75)',
      position: 'absolute',
    },
    '& .slider-arrow.slider-prev': (props) => ({
      left: `calc(20.8333335% - ${
        props.sliderCount * 15 + theme.spacing(2.5)
      }px)`,
    }),
    '& .slider-arrow.slider-next': {
      right: `calc(79.1666665% - ${
        props.sliderCount * 15 + theme.spacing(2.5)
      }px)`,
    },
    '@media screen and (max-width: 1200px)': {
      width: 'calc(90vw - 350px)',
      margin: '0 auto',
      '& .slider-arrow.slider-prev': {
        left: `calc(50% - ${
          props.sliderCount * 15 + theme.spacing(2.5)
        }px) !important`,
      },
      '& .slider-arrow.slider-next': {
        right: `calc(50% - ${
          props.sliderCount * 15 + theme.spacing(2.5)
        }px) !important`,
      },
      '& .slick-dots': { marginLeft: '50% !important' },
    },
    '@media screen and (max-width: 860px)': {
      width: 'calc(100vw - 350px)',
    },
    '@media screen and (max-width: 799px)': { width: '100%' },
  }),
}));

const PrevArrow = ({ onClickOrig }) => (
  <div
    className={'slider-arrow slider-prev'}
    onClick={onClickOrig}
    aria-hidden="true"
  >
    <svg
      viewBox="64 64 896 896"
      data-icon="left"
      fill="currentColor"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
    </svg>
  </div>
);

const NextArrow = ({ onClickOrig }) => (
  <div
    className={'slider-arrow slider-next'}
    onClick={onClickOrig}
    aria-hidden="true"
  >
    <svg
      viewBox="64 64 896 896"
      data-icon="right"
      fill="currentColor"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z" />
    </svg>
  </div>
);

const checkAllowFavorite = (item) => {
  if (!item.slug) return false;
  const favoriteTypes = ['M', 'S'];
  if (favoriteTypes.indexOf(item.type) === -1) {
    return false;
  }
  let expectUrl = '';
  if (item.type === 'M') {
    expectUrl = `/guide/movie/info/${item.slug}/`;
  } else {
    if (item.type === 'M') {
      expectUrl = `/guide/movie/info/${item.slug}/`;
    } else {
      expectUrl = `/guide/show/info/${item.slug}/`;
    }
    if (item.url.replace(/\s/g, '') !== expectUrl.replace(/\s/g, '')) {
      return false;
    }
  }

  return true;
};

const CustomSliderContainer = React.memo(
  ({ sliderData, favoriteList, ...props }) => {
    const filterItems =
      sliderData && sliderData.items
        ? getFilteredSliderItems(sliderData.items)
        : [];
    const classes = useStyles({
      width: props.width,
      sliderCount: filterItems ? filterItems.length : 0,
      sliderData: filterItems || [],
    });
    const [currentSliderIndex, setCurrentSliderIndex] = React.useState(0);
    const [muted, setMuted] = React.useState(true);

    const slider = React.useRef();
    let sliderContainer = null;
    let playingSlider = null;

    const toggleMute = () => setMuted(!muted);

    const onPrevSlider = () => {
      if (currentSliderIndex === 0) {
        return slider.current.slickGoTo(filterItems.length - 1);
      }
      slider.current.slickPrev();
    };

    const onNextSlider = () => {
      if (currentSliderIndex === filterItems.length - 1) {
        return slider.current.slickGoTo(0);
      }
      slider.current.slickNext();
    };

    const checkSlickRotation = (loaded) => {
      if (props.autoplay !== false) {
        if (!sliderContainer)
          sliderContainer = document.getElementById('front-page-slider');
        if (playingSlider) playingSlider.pause();
        if (sliderContainer) {
          const slick = sliderContainer.querySelector('.slick-current');
          if (slick) {
            const video = slick.querySelector('video');
            if (video) {
              playingSlider = video;
              video.play();
              if (loaded) slider.current.slickPause();
            } else {
              playingSlider = null;
              if (loaded) slider.current.slickPlay();
            }
          }
        }
      }
    };

    const settings = {
      dots: true,
      infinite: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow onClickOrig={onPrevSlider} />,
      nextArrow: <NextArrow onClickOrig={onNextSlider} />,
      onInit: () => checkSlickRotation(false),
      afterChange: (index) => {
        setCurrentSliderIndex(index);
        checkSlickRotation(true);
      },
    };

    return (
      <Box id="front-page-slider" width={1}>
        <Slider {...settings} ref={slider} className={classes.slickSlider}>
          {sliderData &&
            filterItems.mapWithKey((item, index) => (
              <Box key={item.key} className={classes.container}>
                {item.type === 'V' ? (
                  <Box className={classes.videoContainer}>
                    {muted && (
                      <Button
                        variant="contained"
                        className={classes.unmutedButton}
                        onClick={toggleMute}
                        startIcon={<VolumeOffIcon />}
                      >
                        Tap to unmute
                      </Button>
                    )}
                    <ReactPlayer
                      width="100%"
                      height="auto"
                      url={item.video_url}
                      playing={currentSliderIndex === index}
                      volume={1}
                      muted={muted}
                      controls
                      onEnded={() => onNextSlider()}
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e) => e.preventDefault(),
                            controlsList: 'nodownload',
                            disablePictureInPicture: true,
                          },
                        },
                      }}
                    />
                  </Box>
                ) : (
                  item.image && (
                    <Box className={classes.videoContainer}>
                      <img alt={item.name} src={item.image} />
                    </Box>
                  )
                )}
                <Box className={classes.textContainer} p={1}>
                  <Box paddingX={2}>
                    <Box className={classes.logo}>
                      {item.logo && <img alt={item.name} src={item.logo} />}
                    </Box>
                    <Box className={classes.title}>{item.name}</Box>
                    <Box className={classes.subTitle}>{item.title}</Box>
                    <Box className={classes.description}>
                      <Box>{item.description}</Box>
                    </Box>
                    {item.type !== 'V' && item.url !== '' && (
                      <Box display="flex">
                        <Link
                          component={RouterLink}
                          to={item.url}
                          className={classes.button}
                        >
                          <PlayCircleFilledIcon fontSize="large" />
                          <Box paddingLeft={1} className={classes.buttonText}>
                            {item.button_text}
                          </Box>
                        </Link>
                        {checkAllowFavorite(item) && (
                          <FavoriteButton
                            favoriteFlag={
                              favoriteList[getItemModel(item)] &&
                              favoriteList[getItemModel(item)].filter(
                                (favoriteItem) =>
                                  favoriteItem.slug === item.slug,
                              ).length > 0
                            }
                            onFavorite={() => props.onFavorite(item)}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
        </Slider>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.width === nextProps.width &&
    prevProps.sliderData === nextProps.sliderData &&
    prevProps.favoriteList === nextProps.favoriteList,
);

export const CustomSlider = withWidth()(CustomSliderContainer);
