import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { addModeToUrl } from 'global';

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    '&:hover > div': {
      height: '100%',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '& > div': {
      height: 0,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  watchBox: {
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    width: '100%',
    height: 0,
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    textAlign: 'start',
  },
  description: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    textAlign: 'start',
    fontSize: theme.typography.subtitle2.fontSize,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    maxHeight: theme.spacing(8),
    margin: '0 auto',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  watchButton: {
    position: 'absolute',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`,
    fontSize: theme.typography.h6.fontSize,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  viewMoreContainer: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
}));

export const GridList = ({
  listData,
  bIsMovie,
  onPage,
  isAddMoreItem = false,
  viewMoreLink,
  mode,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {listData.mapWithKey((item) => (
        <Grid
          item
          key={item.key}
          xs={6}
          sm={bIsMovie ? 4 : 6}
          md={bIsMovie ? 3 : 4}
          lg={bIsMovie ? 2 : 3}
        >
          <Box className={classes.itemContainer}>
            <img src={item.image} alt={item.name} width="100%" height="100%" />
            <Box className={classes.watchBox}>
              <Box className={classes.title}>{item.name}</Box>
              <Box className={classes.description}>{item.description}</Box>
              <Link
                component={RouterLink}
                to={addModeToUrl(item.url, mode) || '#'}
              >
                <Box className={classes.watchButton}>Watch</Box>
              </Link>
            </Box>
          </Box>
        </Grid>
      ))}
      {isAddMoreItem && (
        <Grid
          item
          xs={6}
          sm={bIsMovie ? 4 : 6}
          md={bIsMovie ? 3 : 4}
          lg={bIsMovie ? 2 : 3}
        >
          <Box
            className={classes.viewMoreContainer}
            onClick={() => {
              onPage();
            }}
          >
            <img
              src={viewMoreLink}
              alt="View More"
              width="100%"
              height="100%"
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
