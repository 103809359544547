import React from 'react';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingSpinner } from 'components';
import { VodPlayer } from './VodPlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent!important',
    borderRadius: theme.spacing(3),
  },
  mainWrapper: {
    background: '#292929',
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    textAlign: 'center',
    color: '#cecece',
    fontWeight: 'bold',
  },
  closeIconWrapper: {
    position: 'absolute',
    right: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#fff',
    cursor: 'pointer',
  },
  closeIcon: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export const PlayerDialog = ({
  maxWidth,
  title = '',
  type,
  open,
  onClose,
  url,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      {...props}
      fullWidth
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      classes={{ root: classes.root, paper: classes.root }}
    >
      <Box className={classes.mainWrapper}>
        <Box className={classes.closeIconWrapper}>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </Box>
        <Box className={classes.title}>{title}</Box>
        {url ? (
          <VodPlayer vodName={title} vodType={type} url={url} />
        ) : (
          <Box
            width={1}
            justifyContent="center"
            alignItems="center"
            height="150px"
            position="relative"
          >
            <LoadingSpinner />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
