import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export const LoadingSpinner = ({ bgColor }) => (
  <Box
    bgcolor={bgColor}
    position={'absolute'}
    top={0}
    width={1}
    height={'100%'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <CircularProgress />
  </Box>
);
