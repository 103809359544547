import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ClearIcon from '@material-ui/icons/Clear';

import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
    textAlign: 'center',
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black}`,
      // [theme.breakpoints.up('md')]: {
      //   maxWidth: '60%',
      // },
    },
  },
  itemContainer: {
    padding: theme.spacing(1),
    position: 'relative',
    '&:hover > div': {
      opacity: 1,
    },
  },
  channelItemLinkBox: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    textDecoration: 'none !important',
  },
  dialogImage: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  dialogClose: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  removeBox: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
  },
  menuIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    background: `rgba(150, 150, 150, 0.8)`,
    color: 'white',
    cursor: 'pointer',
  },
  closeIcon: {
    width: '100%',
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const FavoriteDialog = ({
  openModal,
  setOpenModal,
  favoriteList,
  removeFavorite,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Box className={classes.closeBox}>
            <IconButton
              className={classes.dialogClose}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Box component={ClearIcon} className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Box className={classes.title}>FAVORITES</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {favoriteList &&
            favoriteList.mapWithKey((item) => (
              <Grid item key={item.key} xs={4} md={2}>
                <Box className={classes.itemContainer}>
                  <Link
                    component={RouterLink}
                    to={`${'/channels/free/'}${
                      item.categories &&
                      item.categories.length > 0 &&
                      item.categories[0]
                    }/${item.slug}/`}
                    className={classes.channelItemLinkBox}
                    onClick={() => setOpenModal(false)}
                  >
                    <img
                      src={item.logo_url || item.logo}
                      alt={item.name}
                      className={classes.dialogImage}
                    />
                  </Link>
                  <Box mt={1} color="white" textAlign="center">
                    <FavoriteIcon />
                  </Box>
                  <Box
                    className={classes.removeBox}
                    onClick={() => removeFavorite(item)}
                  >
                    <Box component={ClearIcon} className={classes.menuIcon} />
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
