import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: (props) => (props.fullScreen ? 'fixed' : 'absolute'),
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      color: '#fff',
    },
  },
}));

const DialogTitle = (props) => {
  const {
    children,
    onClose,
    variant = 'h6',
    fullScreen,
    align = 'center',
    ...other
  } = props;
  const classes = useStyles({ fullScreen });
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant={variant} align={align}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '10px 100px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 40px',
    },
    '@media (max-width:425px)': {
      padding: '10px 20px',
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: (props) => ({
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: props.justify,
  }),
}))(MuiDialogActions);

const Dialog = withStyles(() => ({
  paper: {
    borderRadius: 0,
    color: COLOR.t_white_light,
    backgroundColor: COLOR.b_black_light,
  },
}))(MuiDialog);

export const CustomDialog = ({
  children,
  title,
  open,
  maxWidth,
  handleClose,
  button,
  scroll = 'body',
  justify = 'center',
  classes,
  fullScreen,
}) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={open}
    scroll={scroll}
    fullScreen={fullScreen}
    maxWidth={maxWidth}
    fullWidth
    classes={classes || {}}
  >
    <DialogTitle
      id="customized-dialog-title"
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      {title}
    </DialogTitle>
    <DialogContent
      dividers={scroll === 'paper'}
      classes={{ root: classes?.content || '' }}
    >
      {children}
    </DialogContent>
    <DialogActions justify={justify}>{button}</DialogActions>
  </Dialog>
);
