import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchRelatedItems } from 'apis';
import LazyImage from '../LazyImage';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    borderTop: '1px solid',
    borderColor: '#7e7e7e',
    marginTop: `${theme.spacing(3)}px`,
    marginRight: `${theme.spacing(4)}px`,
    paddingTop: `${theme.spacing(2)}px`,
  },
  name: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  imgContainer: {
    marginBottom: '0px',
    marginTop: '0px',
    height: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(
      2,
    )}px`,
  },

  linkContainer: { marginRight: `-${theme.spacing(9)}px` },
  gridContainer: { marginLeft: `-${theme.spacing(2)}px` },
}));

export const RelatedList = React.memo(({ slug, model }) => {
  const relatedItems = useSelector(({ pageData }) => pageData.relatedItems);
  const classes = useStyles();
  const dispatch = useDispatch();
  const max = model === 'related_shows' ? 6 : 4;

  React.useEffect(() => {
    if (slug && model) dispatch(fetchRelatedItems(slug, model));
  }, [dispatch, slug, model]);

  return (
    <Box className={classes.container}>
      <Box mb={2} className={classes.name}>
        PEOPLE ALSO LIKED
      </Box>
      <Box className={classes.linkContainer}>
        <Grid container className={classes.gridContainer}>
          {relatedItems.length > 0 &&
            relatedItems.slice(0, max).mapWithKey((item) => (
              <Grid key={item.key} item xs={6}>
                <Link component={RouterLink} to={item.url}>
                  <Box className={classes.imgContainer}>
                    <LazyImage
                      src={item.image}
                      alt="filter"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
});
