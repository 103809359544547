import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  videoContainer: {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .jwplayer.jw-flag-aspect-mode': {
      height: '100% !important',
      display: 'block !important',
    },
  },
}));

export const VidgoPlayer = ({ channelInfo, forceVolume }) => {
  const classes = useStyles();

  React.useEffect(() => {
    loadPlayer();

    return () => {
      if (window.jwplayer('jwPlayer')) {
        window.jwplayer('jwPlayer').remove();
      }
    };
  }, [loadPlayer]);

  const loadPlayer = useCallback(() => {
    if (channelInfo.streams) {
      const sources = [];

      channelInfo.streams.forEach((stream) => {
        let data = {
          file: stream.data.media_url,
          drm: {},
        };
        let drmData = {};
        stream.data.drm.forEach((drmItem) => {
          if (drmItem.name === 'widevine' || drmItem.name === 'playready') {
            drmData = {
              ...drmData,
              [drmItem.name]: { url: drmItem.server_url },
            };
          }
          if (drmItem.name === 'fairplay') {
            drmData = {
              ...drmData,
              [drmItem.name]: {
                processSpcUrl: drmItem.server_url,
                certificateUrl: drmItem.cert_url,
              },
            };
          }
          if (drmItem.name === 'clearkey') {
            drmData = {
              ...drmData,
              [drmItem.name]: {
                key: drmItem.key,
                keyId: drmItem.keyId,
              },
            };
          }
        });
        data = {
          ...data,
          drm: drmData,
        };
        sources.push(data);
      });

      if (window.jwplayer) {
        window.jwplayer.key = 'NlWEzJDuEQ6ohoYwIaUXrE8IAdqST2pYap6uXQ==';

        window.jwplayer('jwPlayer').setup({
          autostart: true,
          displaytitle: false,
          displaydescription: false,
          playlist: [{ sources }],
          mute: forceVolume,
        });
        window.jwplayer('jwPlayer').on('time', setTime);
        window.jwplayer('jwPlayer').on('displayClick', onPause);
      }
    }
  }, [channelInfo, forceVolume]);

  const setTime = () => {};

  const onPause = () => {};

  return (
    <Box className={classes.videoContainer}>
      <Box id="jwPlayer" />
    </Box>
  );
};
