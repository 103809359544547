import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import moment from 'moment';
import clsx from 'clsx';

import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  categoryBar: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    background: `${COLOR.b_black_mlight}`,
    width: '100%',
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: 0,
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(40)}px)`,
      display: 'flex',
    },
    fontSize: theme.typography.h6.fontSize,
  },
  dateBox: {
    fontSize: theme.typography.h6.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(40),
      borderRight: `2px solid ${COLOR.border_color}`,
    },
  },
  dateValueBox: {
    padding: `${theme.spacing(0.5)}px 0`,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(27),
    },
  },
  timeBox: {
    borderRight: `2px solid ${COLOR.border_color}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeBoxNoBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeLabelBox: {
    padding: `${theme.spacing(0.5)}px 0`,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeNavBox: {
    padding: `${theme.spacing(0.5)}px 0`,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(13),
    },
    background: `${COLOR.b_black_mlight}`,
    overflow: 'hidden',
    borderLeft: `2px solid ${COLOR.border_color}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  timeNavBoxDisable: {
    color: 'grey',
  },
  timeNavBoxNormal: {
    color: 'white',
  },
  timeNextBox: {
    position: 'absolute',
    right: 0,
  },
  earlierIcon: {
    transform: 'rotate(180deg)',
  },
  timeNavBoxSM: {
    padding: `${theme.spacing(0.5)}px 0`,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      width: 0,
    },
    background: `${COLOR.b_black_mlight}`,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

export const TimeBar = ({ onClickTimeNavigation, timeOffsetValue }) => {
  const classes = useStyles();

  const scrollBarWidth = React.useMemo(() => {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }, []);

  const getDateBox = () => {
    const nowDate = moment();
    const now = moment(nowDate).utcOffset(0).valueOf();
    const startAt = moment(nowDate)
      .subtract(nowDate.get('minutes'), 'minutes')
      .subtract(nowDate.get('seconds'), 'seconds')
      .utcOffset(0)
      .valueOf();
    const endAt = moment(nowDate)
      .add(59 - nowDate.get('minutes'), 'minutes')
      .add(60 - nowDate.get('seconds'), 'seconds')
      .utcOffset(0)
      .valueOf();
    /* eslint-disable */
    let l = now - startAt;
    let r = endAt - now;
    const pos = l / (l + r);
    const CW = 100 / 3; // current wn
    l = CW * pos;
    r = CW - l;
    const PW = Math.min(50 - l, CW); // prev width
    const NW = Math.min(50 - r, CW); // next width
    const BW = Math.max(50 - PW - l, 0); // left
    const EW = Math.max(50 - NW - r, 0); // right
    /* eslint-enable */

    const widthArray = [
      Math.max(BW - CW / 2, 0),
      Math.min(BW, CW / 2),
      Math.max(PW - CW / 2, 0),
      Math.min(PW, CW / 2),
      CW / 2,
      CW / 2,
      Math.min(NW, CW / 2),
      Math.max(NW - CW / 2, 0),
      Math.min(EW, CW / 2),
      Math.max(EW - CW / 2, 0),
    ];

    let timeOffset = [-2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2, 2.5];
    timeOffset = timeOffset.map((item) => item + timeOffsetValue * 0.5);

    return (
      <Box className={classes.header}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          style={{ width: `calc(100% - ${scrollBarWidth}px)` }}
        >
          {widthArray.mapWithKey(
            (item, index) =>
              item > 0 && (
                <Box
                  width={`${item}%`}
                  className={
                    index === 9 || widthArray[index + 1] === 0
                      ? classes.timeBoxNoBorder
                      : classes.timeBox
                  }
                  key={item.key || index}
                >
                  <Box className={classes.timeLabelBox}>
                    {moment(nowDate)
                      .add(timeOffset[index], 'hours')
                      .subtract(nowDate.get('minutes'), 'minutes')
                      .add(0, 'milliseconds')
                      .format('hh:mm a')}
                  </Box>
                </Box>
              ),
          )}
        </Box>
        <Box
          className={clsx(classes.timeNavBox, classes.timeNextBox, {
            [classes.timeNavBoxDisable]: timeOffsetValue === 16,
            [classes.timeNavBoxNormal]: timeOffsetValue !== 16,
          })}
          onClick={() => {
            if (timeOffsetValue < 16) {
              onClickTimeNavigation(true);
            }
          }}
        >
          Later
          <DoubleArrowIcon />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      className={classes.categoryBar}
      borderBottom={`2px solid ${COLOR.border_color}`}
    >
      <Box className={classes.dateBox}>
        <Box
          className={clsx(classes.timeNavBoxSM, {
            [classes.timeNavBoxDisable]: timeOffsetValue === -16,
            [classes.timeNavBoxNormal]: timeOffsetValue !== -16,
          })}
          onClick={() => {
            if (timeOffsetValue > -16) {
              onClickTimeNavigation(false);
            }
          }}
        >
          <DoubleArrowIcon className={classes.earlierIcon} />
        </Box>
        <Box className={classes.dateValueBox}>
          {moment().format(`dddd - h:mm A`)}
        </Box>
        <Box
          className={clsx(classes.timeNavBoxSM, {
            [classes.timeNavBoxDisable]: timeOffsetValue === 16,
            [classes.timeNavBoxNormal]: timeOffsetValue !== 16,
          })}
          onClick={() => {
            if (timeOffsetValue < 16) {
              onClickTimeNavigation(true);
            }
          }}
        >
          <DoubleArrowIcon />
        </Box>
        <Box
          className={clsx(classes.timeNavBox, {
            [classes.timeNavBoxDisable]: timeOffsetValue === -16,
            [classes.timeNavBoxNormal]: timeOffsetValue !== -16,
          })}
          onClick={() => {
            if (timeOffsetValue > -16) {
              onClickTimeNavigation(false);
            }
          }}
        >
          <DoubleArrowIcon className={classes.earlierIcon} />
          Earlier
        </Box>
      </Box>
      {getDateBox()}
    </Box>
  );
};
