import React from 'react';
import { Box, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iframe: { width: 'calc(100% - 56px)' },
  iframeExpanded: { width: 'calc(100% - 280px)' },
}));

export const IframePage = ({ iframeUrl, backUrl, expanded }) => {
  const classes = useStyles();
  const currentIframeUrl =
    window.location.protocol.includes('https') && !iframeUrl.includes('https')
      ? iframeUrl.replace('http', 'https')
      : iframeUrl;

  return (
    <>
      <Box p={1} pl={2} style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <Button
          variant="contained"
          component={Link}
          to={backUrl}
          color="primary"
          style={{ color: 'white', fontWeight: 'bold' }}
          size="small"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Box>
      <iframe
        className={`${classes.iframe} ${
          expanded ? classes.iframeExpanded : ''
        }`}
        src={currentIframeUrl}
        title="title"
        id="frame-one"
        frameBorder="0"
        style={{
          transition: 'all 1s',
          position: 'fixed',
          backgroundColor: 'black',
          height: '100%',
          zIndex: '100',
        }}
        scrolling="yes"
      />
    </>
  );
};
