import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import { MODE, paramsToObject, useQuery } from 'global';

import { setMode as _setMode } from 'apis';

const useStyles = makeStyles((theme) => ({
  filterBtn: {
    color: '#fff',
    background: '#252525',
    marginLeft: 10,
    cursor: 'pointer',
    lineHeight: '40px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: '700',
    position: 'relative',

    padding: '0 20px',
    borderRadius: 0,
    '&:hover': { backgroundColor: theme.palette.primary.main },

    '@media (max-width: 576px)': {
      lineHeight: '30px',
      fontSize: 14,
      padding: '0px 10px',
    },
  },
  filterBtnSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    color: 'white',
    fontSize: theme.typography.body1.fontSize,
  },
}));

export const FreePremiumToggle = ({ onPage }) => {
  const currentMode = useSelector((s) => s.pageData.mode);
  const params = useQuery();
  const [mode, setMode] = useState(
    currentMode || params.get('mode') || MODE.PREMIUM,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    if (params.mode !== undefined && mode === MODE.FREE) setMode(MODE.FREE);
    else setMode(MODE.PREMIUM);
  }, []);

  const handleClick = (modeType) => {
    let newMode = '';
    if (modeType !== mode) {
      newMode = modeType;
      if (modeType === MODE.FREE) setMode(MODE.FREE);
      else setMode(MODE.PREMIUM);

      dispatch(_setMode(newMode));
      history.push(generateHref(modeType));
      onPage(1);
    }
  };

  const generateHref = (modeType) => {
    const urlParams = new URLSearchParams(history.location.search);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    params.page = 1;
    params.mode = modeType;
    if (modeType === MODE.PREMIUM) delete params.mode;
    const newSearch = `?${new URLSearchParams(params).toString()}`;
    return history.location.pathname + newSearch;
  };

  const renderButton = (modeType) => {
    const buttonProps = {
      className: clsx(classes.filterBtn, {
        [classes.filterBtnSelected]: mode === modeType,
      }),
      variant: 'contained',
      style: { color: 'white' },
    };

    buttonProps.onClick = () => handleClick(modeType);
    return <Button {...buttonProps}>{modeType.toUpperCase()}</Button>;
  };

  return (
    <Box className={'filter-btn-container'} ml={1}>
      <span className={classes.label}>Filters:</span>
      {renderButton(MODE.FREE)}
      {renderButton(MODE.PREMIUM)}
    </Box>
  );
};
