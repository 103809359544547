import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
    flexWrap: 'wrap',
  },
});

export const XCardActions = ({ children, className }) => {
  const classes = useStyles();
  return (
    <CardActions className={clsx(classes.root, className)}>
      {children || 'Card Actions'}
    </CardActions>
  );
};
