import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoadingSpinner } from '../../LoadingSpinner';

const useStyles = makeStyles(() => ({
  videoContainer: {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));

export const EmbeddedPlayer = ({ data }) => {
  const classes = useStyles();

  return data ? (
    <Box
      className={classes.videoContainer}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  ) : (
    <Box className={classes.loadingContainer}>
      <Box
        width={1}
        height={1}
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <LoadingSpinner />
      </Box>
    </Box>
  );
};
