import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'membership',
  initialState: {
    // for chargeBee
    chargeBee: undefined,
    // for membership plans
    memberShipAddOns: undefined,
    // for sotalCloude
    sotalToken: undefined,
    // for package details
    sotalCloudPlan: undefined,
  },
  reducers: {
    onSetMemberShip: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export default slice.reducer;

const { onSetMemberShip } = slice.actions;

export const setMemberShip = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetMemberShip({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
