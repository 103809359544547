import React from 'react';
import { withStyles, makeStyles, Switch, Box } from '@material-ui/core';

const IOSSwitch = withStyles((theme) => ({
  root: (props) => ({
    width: props.width,
    height: props.height,
    padding: 0,
    margin: '0 8px',
  }),
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: (props) => ({
    width: props.height - 2,
    height: props.height - 2,
  }),
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[600],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '0px',
    marginRight: '0px',
  },
  customLabel: {
    fontSize: '14px',
    marginTop: 2,
    cursor: 'pointer',
  },
  switchContainer: {
    marginTop: 2,
    marginBottom: 2,
  },
}));

const CustomSwitch = ({ checked = false, onChange, ...props }) => {
  const classes = useStyles();
  const Label = 'span';

  return (
    <Box className={classes.switchContainer}>
      <Label className={classes.customLabel} onClick={onChange}>
        {checked ? 'On' : 'Off'}
      </Label>
      <IOSSwitch {...props} checked={checked} onChange={onChange} />
    </Box>
  );
};

export const ToggleSwitch = ({
  defaultChecked,
  onChange,
  width,
  height,
  name,
}) => {
  const [state, setState] = React.useState({
    checked: defaultChecked || false,
  });

  const handleChange = (event) => {
    setState({ ...state, checked: !state.checked });
    onChange(event);
  };

  return (
    <CustomSwitch
      checked={state.checked}
      width={width || 30}
      height={height || 15}
      name={name}
      onChange={handleChange}
    />
  );
};
