import React from 'react';
import clsx from 'clsx';
import { Box, Typography, Button, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSeasonEpisodesByMode, fetchSeasonEpisodesbySeasonId } from 'apis';
import { LoadingSpinner } from 'components';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import is from 'is_js';
import Fade from '@material-ui/core/Fade';
import { EpisodesContainer } from './EpisodesContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '0px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexWrap: 'flex',
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  seasonElement: {
    borderBottom: '1px solid #7e7e7e !important',
  },
  seasonElementWrapper: {
    cursor: 'pointer',
    height: theme.spacing(4),
    '& svg': { verticalAlign: 'top', marginTop: theme.spacing(0.5) },
  },
  seasonTitle: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: theme.typography.h6.fontSize,
  },
  button: {
    marginRight: theme.spacing(1),
    color: 'white',
    width: theme.spacing(22),
    borderRadius: theme.spacing(1),
    '&.MuiButton-outlined': {
      border: '1px solid white',
    },
  },
  buttonGreen: { '&.MuiButton-contained': { backgroundColor: '#477410' } },
  buttonOrange: { '&.MuiButton-contained': { backgroundColor: '#dc6403' } },
  buttonNone: { '&.MuiButton-contained': { color: '#477410' } },
  warning: {
    border: '1px solid gray',
    borderRadius: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  activeFilterBorder: {
    height: theme.spacing(0.5),
    width: theme.spacing(22),
    marginTop: 0,
    backgroundColor: '#e0e0e0',
    borderRadius: theme.spacing(0.5),
  },
  filterGreen: { backgroundColor: '#477410' },
  filterOrange: { backgroundColor: '#dc6403' },
}));

export const SeasonsComponent = React.memo(({ show, token, sotalToken }) => {
  const [current, setCurrent] = React.useState();
  const [type, setType] = React.useState('free');
  const [currentSeason, setCurrentSeason] = React.useState([]);
  const [totalByType, setTotalByType] = React.useState({
    free: 0,
    subscribed: 0,
    premium: 0,
  });
  const [seasonData, seasonEpisodes] = useSelector(({ shows }) => [
    shows.allSources,
    shows.seasonEpisodes,
  ]);
  const [maxLenght, setMaxLength] = React.useState(5);
  const dispatch = useDispatch();
  const classes = useStyles();
  const textButton = React.useMemo(
    () =>
      currentSeason.length > maxLenght
        ? 'See More Seasons'
        : 'See Less Seasons',
    [maxLenght, currentSeason],
  );
  const filterData = {
    free: {
      buttonClass: classes.buttonGreen,
      filterClass: classes.filterGreen,
      type: 'free',
      buttonText: 'Free',
    },
    subscribed: {
      buttonClass: classes.buttonOrange,
      filterClass: classes.filterOrange,
      type: 'subscribed',
      buttonText: 'Subscribed',
    },
    premium: {
      buttonClass: classes.buttonNone,
      filterClass: '',
      type: 'premium',
      buttonText: 'Premium',
    },
  };

  const getTotalEpisodesByType = (seasonDataType) =>
    seasonDataType?.reduce(
      (total, season) => total + season.episodes.length,
      0,
    );

  const onClickSeason = (season, force) => {
    if (
      !current ||
      !current.number ||
      current.number !== season.number ||
      force
    ) {
      setCurrent(season);
      if (sotalToken) {
        dispatch(
          fetchSeasonEpisodesbySeasonId(
            show.slug,
            season.id,
            token,
            sotalToken,
          ),
        );
      }
    } else {
      setCurrent(undefined);
    }
  };

  const onShowMoreSeasons = () => {
    if (currentSeason.length < maxLenght) {
      setMaxLength(5);
      return;
    }
    const newMax = maxLenght + 5;
    setMaxLength(newMax);
  };

  const selectType = (t) => {
    setType(t);
    if (seasonData) {
      setCurrentSeason(seasonData[t]);
      onClickSeason(seasonData[t][0], true);
    }
  };

  React.useEffect(() => {
    if (!sotalToken) {
      if (show.slug) dispatch(fetchSeasonEpisodesByMode(show.slug, token));
    } else if (show.seasons && show.seasons.length > 0) {
      const sortedSeasons = [...show.seasons];
      sortedSeasons.sort((a, b) => b.number - a.number);
      setCurrentSeason(sortedSeasons);
      onClickSeason(sortedSeasons[0], true);
    }
  }, [show]);

  React.useEffect(() => {
    if (!sotalToken) {
      if (!is.empty(seasonData)) {
        if (seasonData.free.length > 0) {
          setType('free');
          setCurrentSeason(seasonData.free);
          onClickSeason(seasonData.free[0], true);
        } else if (seasonData.subscribed.length > 0) {
          setType('subscribed');
          setCurrentSeason(seasonData.subscribed);
          onClickSeason(seasonData.subscribed[0], true);
        } else {
          setType('premium');
          setCurrentSeason(seasonData.premium);
          onClickSeason(seasonData.premium[0], true);
        }
        setTotalByType({
          free: getTotalEpisodesByType(seasonData.free),
          subscribed: getTotalEpisodesByType(seasonData.subscribed),
          premium: getTotalEpisodesByType(seasonData.premium),
        });
      }
    }
  }, [seasonData]);

  const renderSeason = (season, index) => (
    <Box
      pb={1}
      pt={1}
      className={classes.seasonElement}
      key={`season_${index}`}
    >
      <Box pb={1} pt={1} mx={1} key={`season_${index}`}>
        <Box
          display="flex"
          className={classes.seasonElementWrapper}
          onClick={() => onClickSeason(season, false)}
        >
          <Box className={classes.seasonTitle}>{season.name}</Box>
          <Box flexShrink={1}>
            {current && season.number === current.number ? (
              <RemoveCircleOutlineOutlinedIcon />
            ) : (
              <ControlPointIcon />
            )}
          </Box>
        </Box>
        {current &&
          ((
            sotalToken
              ? seasonEpisodes?.length > 0
              : current.episodes.length > 0
          ) ? (
            <Collapse in={season.number === current.number} timeout="auto">
              <EpisodesContainer
                type={type}
                seasonNumber={current.number}
                episodes={sotalToken ? seasonEpisodes : current.episodes}
                show={show}
                token={token}
                sotalToken={sotalToken}
              />
            </Collapse>
          ) : (
            (sotalToken ? !seasonEpisodes : true) &&
            season.number === current.number && (
              <Box
                width={1}
                justifyContent="center"
                alignItems="center"
                height="150px"
                position="relative"
              >
                <LoadingSpinner />
              </Box>
            )
          ))}
      </Box>
    </Box>
  );

  const renderContainer = () => {
    if (!sotalToken && is.empty(seasonData))
      return (
        <Box
          width={1}
          justifyContent="center"
          alignItems="center"
          height="150px"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      );
    return currentSeason?.length > 0 ? (
      <>
        {currentSeason.slice(0, maxLenght).map(renderSeason)}
        {currentSeason.length > 5 && (
          <Box style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              style={{ color: 'white' }}
              onClick={onShowMoreSeasons}
            >
              {textButton}
            </Button>
          </Box>
        )}
      </>
    ) : (
      <Box className={classes.warning}>
        There are no {type} seasons to load.
      </Box>
    );
  };

  const renderButtonFilter = (data) => (
    <Box display="inline-block" mr={1}>
      <Button
        size="large"
        variant={'contained'}
        className={clsx(classes.button, data.buttonClass)}
        onClick={() => selectType(data.type)}
      >
        {data.buttonText} ({totalByType[data.type]})
      </Button>
      {type === data.type && (
        <Fade in="true">
          <Box className={clsx(classes.activeFilterBorder, data.filterClass)} />
        </Fade>
      )}
    </Box>
  );

  return (
    <Box mt={1} mb={3}>
      {!sotalToken && Object.keys(seasonData).length > 0 && (
        <Box ml={1} mb={2}>
          {seasonData.free.length > 0 && renderButtonFilter(filterData.free)}
          {seasonData.subscribed.length > 0 &&
            renderButtonFilter(filterData.subscribed)}
          {seasonData.premium.length > 0 &&
            renderButtonFilter(filterData.premium)}
        </Box>
      )}

      {((currentSeason && currentSeason.length > 0) ||
        Object.keys(seasonData).length > 0) && (
        <Box mt={1} mx={1} mb={1}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {currentSeason.length} Seasons
          </Typography>
        </Box>
      )}

      {renderContainer()}
    </Box>
  );
});
