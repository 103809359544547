import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '15px',
    color: COLOR.t_white_light,
    margin: '0px',
    lineHeight: '15px',
    height: '15px',
    display: 'block',
    fontWeight: '700',
  },
}));

export const FormLabel = ({ label, className }) => {
  const classes = useStyles();
  return (
    <Box component={'label'} className={clsx(classes.root, className)}>
      {label}
    </Box>
  );
};
