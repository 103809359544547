import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export const TabList = ({ searchResult, onChangeTab, tabIndex }) => {
  const classes = useStyles();
  const memorizedTabs = React.useMemo(
    () =>
      searchResult.mapWithKey((item, index) => (
        <Box
          key={item.key}
          className={clsx(classes.tabItem, {
            [classes.tabItemNormal]: index !== tabIndex,
            [classes.tabItemSelected]: index === tabIndex,
          })}
          onClick={() => onChangeTab(index)}
        >
          {item.title}
        </Box>
      )),
    [searchResult, tabIndex], //eslint-disable-line
  );

  return (
    <Box className={classes.tabBox} mb={0.5}>
      {memorizedTabs}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  tabBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  tabItem: {
    padding: `6px 24px`,
    margin: '0 12px 0 0',
    textAlign: 'center',
    fontSize: '18px',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  tabItemNormal: {
    borderTop: `none`,
    borderLeft: `none`,
    borderRight: `none`,

    '&:hover': {
      backgroundColor: window.BRAND.primary_color,
      borderRadius: 10,
    },
  },
  tabItemSelected: {
    background: window.BRAND.primary_color,
    borderRadius: 10,
  },
}));
