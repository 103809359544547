import { nanoid } from 'nanoid';
import _extend from 'lodash/extend';
import _head from 'lodash/head';
import _map from 'lodash/map';
import is from 'is_js';

/**
 * mapWithKey - Similar to the regular map function, but we're adding a new 'key' attribute to be used on react lists
 * @param {function} callback
 */
// eslint-disable-next-line
Array.prototype.mapWithKey = function setKeyAndIndex(callback) {
  // if not object, return regular 'map' callback (e.g item and index)
  if (is.not.object(_head(this))) {
    return _map(this, (item, index) => callback(item, index));
  }

  return _map(this, (item, index) => {
    const arrayOfObjectsWithKey = _extend({ key: nanoid() }, item);
    return callback(arrayOfObjectsWithKey, index);
  });
};
