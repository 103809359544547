import axios from 'axios';
import { REDEMPTION } from 'global';

export const fetchStart = (code) =>
  axios.post(REDEMPTION.start, { code_transid: code });

export const registerWithCode = (
  data,
  code,
  setSuccess,
  setCodeUsed,
  setErrors,
  setLoading,
) =>
  axios.post(`${REDEMPTION.register + code}/`, data).then(
    (response) => {
      if (response.data.status === 'ok') {
        setSuccess(true);
        setCodeUsed(true);
      }
    },
    (error) => {
      setErrors(error.response.data.message);
      setLoading(false);
    },
  );
