import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';

import ReactPlayer from 'react-player';
import { LoadingSpinner } from 'components';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',

    '&:hover #muteButton': {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  muteContainer: (props) => ({
    opacity: props.muted ? 1 : 0,
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    background: `${COLOR.b_black_mlight}`,
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
  }),
}));

export const UrlPlayer = React.memo(
  ({
    url,
    isPlaying = true,
    isLoop = false,
    playerRef = null,
    controls = false,
  }) => {
    const [ready, setReady] = React.useState(false);
    const [muted, setMuted] = React.useState(false);
    const classes = useStyles({ muted });

    return (
      <Box className={classes.videoContainer}>
        <ReactPlayer
          width="100%"
          height="100%"
          url={url}
          muted={muted}
          playing={isPlaying}
          controls={controls}
          loop={isLoop}
          onReady={() => setReady(true)}
          config={{
            file: {
              attributes: {
                onContextMenu: (e) => e.preventDefault(),
                controlsList: 'nodownload',
                disablePictureInPicture: true,
              },
            },
          }}
          ref={playerRef}
        />
        {!controls && (
          <Box
            id="muteButton"
            className={classes.muteContainer}
            onClick={() => setMuted((muted) => !muted)}
          >
            {muted ? <VolumeOffRoundedIcon /> : <VolumeUpRoundedIcon />}
            <Box ml={1}>{muted ? 'TAP TO UNMUTE' : 'TAP TO MUTE'}</Box>
          </Box>
        )}
        {!ready && !controls && (
          <Box className={classes.loadingContainer}>
            <Box
              width={1}
              height={1}
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <LoadingSpinner />
            </Box>
          </Box>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.url === nextProps.url &&
    prevProps.isLoop === nextProps.isLoop &&
    prevProps.isPlaying === nextProps.isPlaying,
);
