import React from 'react';
import is from 'is_js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { keyframes } from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

export const ImageWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) =>
    is.existy(minHeight) ? minHeight : '100%;'};
`;

export const loadingAnimation = keyframes`
  0% {
    background-color: #252525;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #252525;
  }
`;

export const Placeholder = styled.div`
  background-color: #252525 !important;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
  width: 100%;
  height: 100%;
`;

export const StyledImage = React.memo(styled(LazyLoadImage)`
  left: 0;
  width: 100%;
  height: 100%;
`);

export const LoadingSpinnerContainer = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinner = styled(CircularProgress)`
  color: #dadada;
`;
