import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { COLOR } from 'global';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 5px',
    border: 'none !important',
    borderBottom: `1px solid ${COLOR.border_black_light2} !important`,
    backgroundColor: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    lineHeight: '30px',
    height: 'auto',
    width: '100%',
    fontSize: '15px',
    color: COLOR.t_white_light,
    wordBreak: 'break-all',
  },
}));

export const FormControl = ({ children, className, style }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)} style={style}>
      {children || 'Form Control'}
    </Box>
  );
};
