import React from 'react';
import { Box, withWidth, isWidthUp } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: isWidthUp('sm', props.width)
      ? theme.typography.h6.fontSize
      : theme.typography.subtitle1.fontSize,
    color: 'white',
    cursor: 'pointer',
  }),
  buttonText: {
    buttonText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const FavoriteButtonContainer = ({
  width,
  favoriteFlag = false,
  onFavorite,
  model = '',
}) => {
  const classes = useStyles({ width });
  const history = useHistory();

  const redirectTo = () => history.push(`/guide/favorite/${model}/`);

  return (
    <Box
      className={classes.container}
      onClick={() => (favoriteFlag ? redirectTo() : onFavorite())}
    >
      {favoriteFlag ? (
        <FavoriteIcon fontSize="large" />
      ) : (
        <FavoriteBorderIcon fontSize="large" />
      )}
      <Box paddingLeft={1} className={classes.buttonText}>
        {!favoriteFlag ? 'Add to Favorites' : 'View Favorites'}
      </Box>
    </Box>
  );
};

export const FavoriteButton = withWidth()(FavoriteButtonContainer);
