import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { COLOR, FAVORITE_TYPE } from 'global';

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    color: `${COLOR.b_white_light}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${COLOR.b_white_light}`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(15),
      marginRight: theme.spacing(15),
    },
  },
  linkItem: {
    textDecoration: 'none !important',
  },
}));

export const NoContentAlert = ({ favoriteType, onClickHere }) => {
  const classes = useStyles();

  const getFavoriteName = () => {
    switch (favoriteType) {
      case FAVORITE_TYPE.MOVIES:
        return 'Movies';
      case FAVORITE_TYPE.SHOWS:
        return 'Shows';
      case FAVORITE_TYPE.MOVIEGENRES:
        return 'Movie Genres';
      case FAVORITE_TYPE.NETWORKS:
        return 'Networks';
      case FAVORITE_TYPE.CHANNELS:
        return 'Channels';
      default:
        return 'Video Libraries';
    }
  };

  return (
    <Box className={classes.linkContainer}>
      <>
        You currently have 0 item in My Favorite {getFavoriteName()}.
        <Link
          className={classes.linkItem}
          component={RouterLink}
          to={'#'}
          onClick={() => {
            onClickHere();
          }}
        >
          Click here
        </Link>
        &nbsp;to add your favorite {getFavoriteName()}.
      </>
    </Box>
  );
};
