import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  FAVORITE_TYPE,
  truncateByLength,
  getChannelCategory,
  getURLFavoriteItem,
} from 'global';
import LazyImage from '../LazyImage';

const MAX_LENGTH = 125;

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'white',
  },
  imgContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: 0,
    height: '100%',
    '& img': { position: 'relative', top: 0, left: 0 },
    '&:hover > div': {
      opacity: 1,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '& > div': {
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  imgContainerShow: {
    position: 'relative',
    paddingBottom: 0,
    height: '100%',
    '& img': { position: 'relative', top: 0, left: 0 },
  },
  imgContainerMovie: {
    paddingBottom: 0,
    height: '100%',
    '& img': { position: 'relative', top: 0, left: 0 },
  },
  channelItem: {
    borderRadius: theme.spacing(2),
  },
  watchButton: {
    position: 'absolute',
    bottom: 0,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: 0,
    backgroundColor: 'rgba(0, 156, 254, 0.9)',
  },
  watchText: {
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  watchTextBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  icon: {
    position: 'absolute',

    opacity: 0,
    top: theme.spacing(2),
    width: theme.spacing(3),
    height: `${theme.spacing(3)}px !important`,
    color: 'rgb(175, 24, 29)',
    background: `rgba(255,255,255,0.5)`,
    borderRadius: `50%`,
    '&:hover': { background: `rgba(255,255,255,0.7)` },
  },
  favoriteIcon: {
    cursor: 'pointer',
    left: theme.spacing(2),
    '& svg': { width: 18 },
  },
  removeBtn: {
    cursor: 'pointer',
    right: theme.spacing(2),
    '& svg': {
      width: 30,
      height: 30,
      marginLeft: '-3px',
      marginTop: '-3px',
    },
  },
  name: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    '& a': {
      color: 'white !important',
      fontWeight: 'bold',
      textDecoration: 'none !important',
    },
  },
  releaseDate: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
  },
  linkItem: {
    textDecoration: 'none !important',
    cursor: 'pointer',
  },
  noRated: {
    marginLeft: 7,
    color: 'gray',
  },
  description: {
    fontSize: theme.typography.subtitle2.fontSize,
    textAlign: 'start',
  },
}));

export const FavoriteItem = React.memo(
  ({ item, favoriteType, onRemoveFavorite }) => {
    const [isExtended, setIsExtended] = React.useState(false);
    const classes = useStyles({ type: favoriteType });
    const url = getURLFavoriteItem(item, favoriteType);
    const getAiredYear = (date) => new Date(date).getFullYear();
    const isMovieOrShows = () =>
      [FAVORITE_TYPE.MOVIES, FAVORITE_TYPE.SHOWS].includes(favoriteType);

    const favoriteItemSize =
      favoriteType === FAVORITE_TYPE.MOVIES
        ? { xsImg: 4, xsDetail: 8, marginLeft: 1 }
        : { xsImg: 12, xsDetail: 12, marginLeft: 0 };

    return (
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={favoriteItemSize.xsImg}>
            <Box
              className={clsx(classes.imgContainer, {
                [classes.imgContainerShow]:
                  favoriteType === FAVORITE_TYPE.SHOWS,
                [classes.imgContainerMovie]:
                  favoriteType === FAVORITE_TYPE.MOVIES,
              })}
              mb={2}
            >
              {isMovieOrShows() ? (
                <LazyImage src={item.image} width="100%" height="100%" alt="" />
              ) : (
                <Link
                  component={RouterLink}
                  to={
                    favoriteType === FAVORITE_TYPE.CHANNELS
                      ? `/channels/free/${getChannelCategory(item)}/${
                          item.slug
                        }/`
                      : url
                  }
                >
                  <LazyImage
                    alt={item.slug}
                    src={item.image}
                    className={clsx('', {
                      [classes.channelItem]: [
                        FAVORITE_TYPE.CHANNELS,
                        FAVORITE_TYPE.NETWORKS,
                        FAVORITE_TYPE.MOVIEGENRES,
                      ].includes(favoriteType),
                    })}
                    width="100%"
                    height="100%"
                  />
                </Link>
              )}
              {isMovieOrShows() && (
                <Box className={classes.watchButton}>
                  <Link
                    component={RouterLink}
                    to={item.url}
                    className={classes.watchText}
                  >
                    <Box className={classes.watchTextBox} pt={3} pb={2}>
                      WATCH NOW
                    </Box>
                  </Link>
                </Box>
              )}
              <Box className={`${classes.icon} ${classes.favoriteIcon}`}>
                <FavoriteIcon />
              </Box>
              <Box
                className={`${classes.icon} ${classes.removeBtn}`}
                onClick={() => onRemoveFavorite(item)}
              >
                <RemoveCircleOutlineIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={favoriteItemSize.xsDetail}>
            <Box marginLeft={favoriteItemSize.marginLeft}>
              <Box display={isMovieOrShows() ? 'flex' : 'inherit'}>
                <Box className={classes.name}>
                  <Link component={RouterLink} to={item.url}>
                    {item.name}
                  </Link>
                </Box>
                {isMovieOrShows() && (
                  <Box className={classes.releaseDate}>
                    (
                    {favoriteType === FAVORITE_TYPE.MOVIES
                      ? item.release_year
                      : getAiredYear(item.first_aired)}
                    )
                  </Box>
                )}
              </Box>
              {isMovieOrShows() && (
                <>
                  <Box display="flex" alignItems="center" marginTop={1}>
                    <Box className={classes.name}>Rating</Box>
                    <Box className={classes.releaseDate}>-</Box>
                    {item.rating ? (
                      <Link
                        className={classes.linkItem}
                        component={RouterLink}
                        to={`/guide/${favoriteType}s/?rating=${item.rating}&page=1&page_size=23&ordering=`}
                      >
                        <Box className={classes.releaseDate}>
                          {item.rating_name}
                        </Box>
                      </Link>
                    ) : (
                      <Box className={classes.noRated}>No Rated</Box>
                    )}
                  </Box>
                  <Box className={classes.description} marginTop={1}>
                    <>
                      {item.description.length < MAX_LENGTH || isExtended
                        ? item.description
                        : truncateByLength(item.description, MAX_LENGTH, '...')}
                      {item.description.length > MAX_LENGTH && (
                        <Link
                          className={classes.linkItem}
                          onClick={() => setIsExtended(!isExtended)}
                        >
                          {isExtended ? ' Read Less' : ' Read More'}
                        </Link>
                      )}
                    </>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
);
