import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import { LoadingSpinner } from '../LoadingSpinner';
import { LinksBox } from './LinksBox';

const useStyles = makeStyles((theme) => ({
  container: {},
  name: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  episodeItem: {
    padding: `${theme.spacing(1)}px 0`,
    borderBottom: '1px solid #dee2e6',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.subtitle1.fontSize,
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  button: {
    color: 'white',
    minWidth: theme.spacing(27),
    position: 'absolute',
    right: '0px',
  },
}));

const PageSize = 6;

export const EpisodeList = ({ episodeList, linksInfo, onLoadLinks }) => {
  const classes = useStyles();

  const [limitCount, setLimitCount] = React.useState(PageSize);
  const [showLinks, setShowLinks] = React.useState({});

  React.useEffect(() => {
    setLimitCount(PageSize);
  }, [episodeList]);

  const getFilteredList = () =>
    episodeList.filter((_, index) => index < limitCount);

  const updateLinks = (item) => {
    setShowLinks((showLinks) => ({
      ...showLinks,
      [item.number]: !showLinks[item.number],
    }));
    if (!linksInfo[item.number]) {
      onLoadLinks(item.number);
    }
  };

  return episodeList && episodeList.length > 0 ? (
    <Box className={classes.container}>
      <Box className={classes.name}>{episodeList[0].season.name}</Box>
      {getFilteredList().mapWithKey((item) => (
        <Box key={item.key}>
          <Box
            className={classes.episodeItem}
            onClick={() => {
              updateLinks(item);
            }}
          >
            <Box display="flex">
              <Box flex="none">
                S{item.season.number} E{item.number}
              </Box>
              <Box>&nbsp;-&nbsp;</Box>
              <Box>{item.name}</Box>
            </Box>
            <Box
              color="white"
              component={
                showLinks[item.number]
                  ? RemoveCircleOutline
                  : AddCircleOutlineIcon
              }
            />
          </Box>
          {showLinks[item.number] && (
            <Box>
              {linksInfo[item.number] ? (
                <Box>
                  <LinksBox itemInfo={linksInfo[item.number]} />
                </Box>
              ) : (
                <Box
                  width={1}
                  justifyContent="center"
                  alignItems="center"
                  height="10vh"
                  position="relative"
                >
                  <LoadingSpinner />
                </Box>
              )}
            </Box>
          )}
        </Box>
      ))}
      {episodeList.length > PageSize && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          py={1}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              if (limitCount < episodeList.length) {
                return setLimitCount((limitCount) => limitCount + PageSize);
              }
              setLimitCount(PageSize);
            }}
          >
            {limitCount < episodeList.length
              ? 'See More Episodes'
              : 'See Less Episodes'}
          </Button>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      width={1}
      justifyContent="center"
      alignItems="center"
      height="20vh"
      position="relative"
    >
      <LoadingSpinner />
    </Box>
  );
};
