import React from 'react';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import FastRewindRoundedIcon from '@material-ui/icons/FastRewindRounded';
import FastForwardRoundedIcon from '@material-ui/icons/FastForwardRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import moment from 'moment';

import { COLOR } from 'global';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: theme.spacing(1.5),
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: window.BRAND.primary_color,
  },
}))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: theme.spacing(20),
  },
  stickyHeader: {
    backgroundColor: `${COLOR.b_black_light}`,
  },
  body: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  recordingList: {
    background: `${COLOR.b_black_light}`,
    height: `calc(60vh - ${theme.spacing(9)}px)`,
  },
  recordingListHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  recordingHeaderItemBox: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`,
  },
  watchLiveBox: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  recordingHeaderNameBox: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  precentBox: {
    width: theme.spacing(30),
    padding: `0 ${theme.spacing(1)}px`,
  },
  container: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    margin: `0 ${theme.spacing(3)}px`,
    padding: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(40),
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100% - 50px)`,
    },
  },
  rowItem: {
    cursor: 'pointer',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsItemBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsItemBoxBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    border: `2px solid white`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  recordMardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.5),
    background: 'red',
  },
  smallRecIcon: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  smallRecBox: {
    color: 'white',
    fontSize: '0.75rem',
  },
  channelLogoImage: {
    width: theme.spacing(6),
    marginRight: theme.spacing(1),
    height: '100%',
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black}`,
    },
  },
  tableLabelBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const RecordingList = React.memo(
  ({
    showEpgView,
    dvrStatus,
    dvrList,
    controlDvr,
    catchUpForwardBackward,
    catchUpRestart,
    removeDvr,
    dvrSlug,
    isPlaying,
  }) => {
    const classes = useStyles();
    const [opendIndex, setOpendIndex] = React.useState();

    const [removeSlug, setRemoveSlug] = React.useState(null);

    const handleClose = () => {
      setRemoveSlug(null);
    };

    const getRemoveConfirmDlg = () => (
      <Dialog
        className={classes.modal}
        open={Boolean(removeSlug)}
        onClose={handleClose}
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <Box>Are you sure to delete?</Box>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              removeDvr(removeSlug, 0, false);
              handleClose();
            }}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );

    const getOptionsBox = (dvrSlug, status) => (
      <Box mt={1} display="flex">
        {status === 'recorded' && (
          <Box
            className={classes.optionsItemBoxBorder}
            onClick={() => {
              controlDvr(dvrSlug, true);
            }}
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PlayArrowRoundedIcon />
            </Box>
            <Box className={classes.recordingHeaderNameBox}>Watch Now</Box>
          </Box>
        )}
        <Box
          className={classes.optionsItemBoxBorder}
          onClick={() => {
            setRemoveSlug(dvrSlug);
          }}
        >
          <Box mt={0.5} mr={0.5} color="white">
            <ClearRoundedIcon />
          </Box>
          <Box className={classes.recordingHeaderNameBox}>Delete</Box>
        </Box>
      </Box>
    );

    const getControlsBar = () => (
      <Box mt={1} display="flex">
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(false);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastRewindRoundedIcon />
          </Box>
          <Box className={classes.recordingHeaderNameBox}>Rewind</Box>
        </Box>
        {dvrSlug && !isPlaying ? (
          <Box
            className={classes.optionsItemBoxBorder}
            onClick={() => controlDvr(dvrSlug, true)}
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PlayArrowRoundedIcon />
            </Box>
            <Box className={classes.recordingHeaderNameBox}>Play</Box>
          </Box>
        ) : (
          <Box
            className={classes.optionsItemBoxBorder}
            onClick={() => controlDvr(dvrSlug, false)}
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PauseRoundedIcon />
            </Box>
            <Box className={classes.recordingHeaderNameBox}>Pause</Box>
          </Box>
        )}
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(true);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastForwardRoundedIcon />
          </Box>
          <Box className={classes.recordingHeaderNameBox}>Forward</Box>
        </Box>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpRestart();
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.blue_light}>
            <ReplayRoundedIcon />
          </Box>
          <Box className={classes.recordingHeaderNameBox}>Start Over</Box>
        </Box>
      </Box>
    );

    return (
      <Box id="recordingList" className={classes.recordingList}>
        <Grid container className={classes.recordingListHeader}>
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            className={classes.recordingHeaderItemBox}
          >
            <Box className={classes.watchLiveBox} onClick={() => showEpgView()}>
              <Box mt={0.5} mr={0.5} color="white">
                <ArrowBackIosRoundedIcon />
              </Box>
              <Box className={classes.recordingHeaderNameBox}>Watch Live</Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={dvrStatus && dvrStatus.dvr_limit_minutes ? 3 : 6}
            className={classes.recordingHeaderNameBox}
            style={{ justifyContent: 'start' }}
          >
            <Box fontSize={'h5.fontSize'}>My Recordings</Box>
          </Grid>
          {dvrStatus && dvrStatus.dvr_limit_minutes && (
            <Grid
              item
              sm={12}
              md={6}
              className={classes.recordingHeaderItemBox}
              style={{ justifyContent: 'flex-end' }}
            >
              <Box className={classes.recordingHeaderNameBox}>Storage:</Box>
              <Box className={classes.precentBox}>
                <CustomLinearProgress
                  variant="determinate"
                  value={
                    (dvrStatus.dvr_recorded / dvrStatus.dvr_limit_minutes) * 100
                  }
                />
              </Box>
              <Box className={classes.recordingHeaderNameBox}>
                {dvrStatus.dvr_available.toFixed(0)} Mins Remaining (
                {Math.round(
                  (dvrStatus.dvr_available / dvrStatus.dvr_limit_minutes) * 100,
                )}
                %)
              </Box>
            </Grid>
          )}
        </Grid>
        {dvrList && dvrList.length > 0 && (
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="center">
                    Available Until
                  </StyledTableCell>
                  <StyledTableCell align="center">Channel</StyledTableCell>
                  <StyledTableCell align="center">Date & Time</StyledTableCell>
                  <StyledTableCell align="center">Run Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dvrList.mapWithKey((row, index) => (
                  <TableRow
                    key={row.key}
                    className={classes.rowItem}
                    onClick={() => setOpendIndex(index)}
                  >
                    <StyledTableCell component="th" scope="row">
                      <Box display="flex" alignItems="center" mt={2}>
                        <Box className={classes.tableLabelBox}>{row.name}</Box>
                        {row.status === 'recording' && (
                          <Box className={classes.recordMardBox}>
                            <Box mr={0.5} color="white">
                              <FiberManualRecordIcon
                                className={classes.smallRecIcon}
                              />
                            </Box>
                            <Box className={classes.smallRecBox}>REC</Box>
                          </Box>
                        )}
                      </Box>
                      {index === opendIndex &&
                        (row.slug === dvrSlug
                          ? getControlsBar()
                          : getOptionsBox(row.slug, row.status))}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box mt={2}>
                        {moment(row.available_until).format('MM/DD/YYYY')}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box className={classes.channelLogoImage}>
                          <img
                            src={row.channel.logo || ''}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </Box>
                        <Box className={classes.tableLabelBox}>
                          {row.channel.name}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box mt={2}>
                        {row.create_time &&
                          moment(row.epg.start_time).format(
                            'ddd MM/DD - h:mm A',
                          )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box mt={2}>
                        {row.epg &&
                          moment
                            .duration(row.epg.duration, 'minute')
                            .asMinutes()
                            .toFixed(0)}
                        &nbsp;Minutes
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {getRemoveConfirmDlg()}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.dvrStatus === nextProps.dvrStatus &&
    prevProps.dvrList === nextProps.dvrList &&
    prevProps.dvrSlug === nextProps.dvrSlug &&
    prevProps.isPlaying === nextProps.isPlaying,
);
