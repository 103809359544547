import React from 'react';
import { Box, Grid, CardMedia, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { staticUrl, addHttps } from 'global';

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    backgroundSize: 'cover',
  },
  media_logo: {
    height: theme.spacing(12),
    marginBottom: theme.spacing(4),
    backgroundSize: 'contain',
  },
  container: {
    maxWidth: theme.spacing(60),
    justifyContent: 'center',
  },
  loginContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  loginWrap: {
    backgroundImage: `url('${staticUrl('members/login/img/login-bg.jpg')}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    minHeight: '640px',
  },
}));

export const BaseLogin = ({ children, supportUrl, singUp }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.loginWrap}
      width={1}
      height="100%"
      position="relative"
    >
      <Box className={classes.loginContainer}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <CardMedia
              className={classes.media_logo}
              image={window.BRAND.logo}
            />
            <Box mx={1} elevation={3} position="relative">
              <Box component={Paper} p={4} borderRadius={0}>
                {children}
                {supportUrl && (
                  <Box
                    pt={2}
                    fontSize="body1.fontSize"
                    className={classes.text}
                  >
                    Need Help?{' '}
                    <a className={classes.link} href={window.BRAND.support_url}>
                      Click Here for Live Support.
                    </a>
                  </Box>
                )}
                {singUp && (
                  <Box
                    pt={1}
                    pb={1}
                    fontSize="body1.fontSize"
                    className={classes.text}
                  >
                    Don't have an {window.BRAND.site_name} account?&nbsp;
                    <a
                      href={addHttps(window.BRAND.sale_page)}
                      className={classes.link}
                    >
                      Sign Up now!
                    </a>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
