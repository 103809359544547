import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { makeStyles, Box } from '@material-ui/core';
import { PUBLIC_KEY } from 'global';

const useStyles = makeStyles(() => ({
  error: {
    border: '1px solid red',
    display: 'inline-block',
    padding: 5,
  },
  messageError: {
    color: 'red',
    fontWeight: 'bolder',
    margin: '5px 0',
  },
}));

export const Recaptcha = ({ isCaptchaError, setFormState }) => {
  const classes = useStyles();

  const handleCaptchaChange = (value) => {
    setFormState({ grecaptcha: value, isCaptchaError: false });
  };
  const asyncScriptOnLoad = () => {
    document.querySelector('body').style.position = 'unset';
  };
  return (
    <Box style={{ position: 'relative', minHeight: '40px' }}>
      <Box className={isCaptchaError ? classes.error : ''}>
        <ReCAPTCHA
          sitekey={PUBLIC_KEY.RECAPTCHA_PUBLIC_KEY}
          onChange={handleCaptchaChange}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />
        {isCaptchaError && (
          <Box className={classes.messageError}>
            Please verify that you are not a robot.
          </Box>
        )}
      </Box>
    </Box>
  );
};
