import axios from 'axios';
import {
  LOGIN_JWT,
  LOGIN_LOADING_STATE,
  RESET_PASSWORD,
  LOGIN_SHADOW_JWT,
} from 'global';
import { setAuth } from 'redux/reducers/auth';

const setAuthAfterError = (dispatch, response) =>
  dispatch(
    setAuth({
      loginStatus: LOGIN_LOADING_STATE.FAILURE,
      loginData: response.data,
    }),
  );

const setAuthAfterSuccess = (dispatch, response) => {
  if (response.data.access) {
    return dispatch(
      setAuth({
        loginStatus: LOGIN_LOADING_STATE.SUCCESS,
        loginData: response.data,
        jwt_token: response.data.access,
        refresh_token: response.data.refresh,
      }),
    );
  }
  return setAuthAfterError(dispatch, response);
};

export const login = (username, password) => (dispatch) => {
  dispatch(setAuth({ loginStatus: LOGIN_LOADING_STATE.LOADING }));
  return axios
    .post(LOGIN_JWT.API, {
      username,
      password,
    })
    .then((response) => setAuthAfterSuccess(dispatch, response))
    .catch((error) => setAuthAfterError(dispatch, error.response));
};

export const loginShadow = (username) => (dispatch) =>
  axios
    .post(LOGIN_SHADOW_JWT.API, { username })
    .then((response) => setAuthAfterSuccess(dispatch, response))
    .catch((error) => setAuthAfterError(dispatch, error.response));

export const logout = () => (dispatch) => {
  dispatch(setAuth({}));
};

export const resetPasswordRequest = (data) => (dispatch) =>
  axios
    .post(RESET_PASSWORD.REQUEST_RESET, data)
    .then((response) =>
      dispatch(
        setAuth({ resetPasswordRequest: { status: response.data.status } }),
      ),
    )
    .catch((error) =>
      dispatch(
        setAuth({
          resetPasswordRequest: {
            status: 'ERROR',
            error: error.response.data,
          },
        }),
      ),
    );

export const resetPasswordValidateRequest = (uidb64, token) => (dispatch) => {
  const url = RESET_PASSWORD.REQUEST_PASSWORD.replace('{0}', uidb64).replace(
    '{1}',
    token,
  );

  return axios
    .get(url)
    .then((response) => {
      if (response.data.status === 'OK') {
        return dispatch(setAuth({ resetPasswordValidate: { status: 'OK' } }));
      }
      return dispatch(setAuth({ resetPasswordValidate: { status: 'FAIL' } }));
    })
    .catch(() => {
      console.log(`FAIL`);
      return dispatch(setAuth({ resetPasswordValidate: { status: 'FAIL' } }));
    });
};

export const resetPasswordConfirmRequest = (uidb64, token, data) => (
  dispatch,
) => {
  const url = RESET_PASSWORD.REQUEST_PASSWORD.replace('{0}', uidb64).replace(
    '{1}',
    token,
  );

  return axios
    .post(url, data)
    .then((response) =>
      dispatch(setAuth({ resetPasswordConfirm: { status: 'OK' } })),
    )
    .catch((error) =>
      dispatch(setAuth({ resetPasswordConfirm: { status: 'FAIL' } })),
    );
};

export const verifyToken = (loginInfoStatus) => (dispatch) => {
  if (loginInfoStatus.jwt_token) {
    axios
      .post(LOGIN_JWT.VERIFY, { token: loginInfoStatus.jwt_token })
      .then((response) => {
        if (response.data?.code === 'token_not_valid') dispatch(logout());
      })
      .catch(() => {
        dispatch(logout());
      });
  }
};
