import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useTheme, TextField, Typography } from '@material-ui/core';

import { XButton, LoadingSpinner } from 'components';
import { COLOR } from 'global';
import {
  resetPasswordValidateRequest,
  resetPasswordConfirmRequest,
} from 'apis';
import { BaseLogin } from './Base';

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: COLOR.b_bg,
      fontSize: '14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
        borderColor: theme.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        boxShadow: `0px 0px 5px 1px ${theme.palette.text.secondary}`,
        borderColor: theme.palette.text.secondary,
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  text: { color: '#333333' },
}));

const ResetNoActive = () => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <Box color="gray" fontSize="h4.fontSize">
        Password Reset
      </Box>
      <Box mt={2}>
        <Typography variant="h5" component="h5">
          This password reset has been used.
        </Typography>
      </Box>

      <Box mt={2}>
        <XButton
          label="Try Again"
          bkcolor={theme.palette.common.black}
          textcolor="white"
          variant="contained"
          onClick={() => history.push('/members/password/reset/')}
          width={1}
        />
      </Box>
    </>
  );
};

const ResetSuccess = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Box color="gray" fontSize="h4.fontSize">
        Password Reset Complete
      </Box>
      <Box mt={2} className={classes.text}>
        Your password has been set.
      </Box>
      <Box mt={2} className={classes.text}>
        You may go ahead and log in now.
      </Box>
      <Box mt={2}>
        <XButton
          label="Return to Login"
          bkcolor={theme.palette.common.black}
          textcolor="white"
          variant="contained"
          onClick={() => history.push('/login')}
          width={1}
        />
      </Box>
    </>
  );
};

export const ResetConfirm = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { uidb64, token } = useParams();
  const dispatch = useDispatch();
  const [validToken, resetPasswordConfirm] = useSelector(({ auth }) => [
    auth.resetPasswordValidate.status,
    auth.resetPasswordConfirm,
  ]);

  const [mounted, setMounted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [resetInfo, setResetInfo] = React.useState({
    new_password: '',
    new_password_confirm: '',
  });
  const [active, setActive] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [validation, setValidation] = React.useState({
    newPasswordError: false,
    newPasswordConfirmError: false,
  });

  React.useEffect(() => {
    dispatch(resetPasswordValidateRequest(uidb64, token));
  }, []);

  React.useEffect(() => {
    if (validToken === 'OK') {
      setActive(true);
    }
    if (validToken) setMounted(true);
  }, [validToken]); //eslint-disable-line

  React.useEffect(() => {
    if (resetPasswordConfirm.status === 'OK') {
      setSuccess(true);
    }
  }, [resetPasswordConfirm]);

  const isValid = () => {
    const validatedData = {
      newPasswordError: resetInfo.new_password.length === 0,
      newPasswordConfirmError: resetInfo.new_password_confirm.length === 0,
      notMatchError: resetInfo.new_password !== resetInfo.new_password_confirm,
      shortError: resetInfo.new_password.length < 6,
    };
    setValidation(validatedData);
    return !(
      validatedData.newPasswordError ||
      validatedData.newPasswordConfirmError ||
      validatedData.notMatchError ||
      validatedData.shortError
    );
  };

  const changeValue = (e) => {
    e.preventDefault();
    setResetInfo({ ...resetInfo, [e.target.name]: e.target.value });
  };

  const changePassword = () => {
    if (isValid() && !loading) {
      setLoading(true);
      const data = { password: resetInfo.new_password };
      dispatch(resetPasswordConfirmRequest(uidb64, token, data));
    }
  };

  return (
    <BaseLogin supportUrl singUp>
      {mounted ? (
        active ? (
          !success ? (
            <>
              <Box color="gray" fontSize="h4.fontSize">
                Password Reset
              </Box>
              <Box mt={2} className={classes.text}>
                Please enter your new password twice so we can verify you typed
                it in correctly.
              </Box>

              <Box mt={2} textAlign="left">
                <Box fontSize="body1.fontSize" color="gray">
                  New Password
                </Box>
                <TextField
                  error={
                    validation.shortError ||
                    validation.newPasswordError ||
                    validation.notMatchError
                  }
                  type="password"
                  variant="outlined"
                  size="small"
                  className={classes.textfield}
                  placeholder="New Password"
                  helperText={
                    validation.newPasswordError
                      ? 'This field should be filled out.'
                      : ''
                  }
                  name="new_password"
                  value={resetInfo.new_password}
                  onChange={(e) => changeValue(e)}
                />
              </Box>

              <Box mt={2} textAlign="left">
                <Box fontSize="body1.fontSize" color="gray">
                  New Password Confirmation
                </Box>
                <TextField
                  error={
                    validation.shortError ||
                    validation.newPasswordConfirmError ||
                    validation.notMatchError
                  }
                  type="password"
                  variant="outlined"
                  size="small"
                  className={classes.textfield}
                  placeholder="New Password Confirmation"
                  helperText={
                    validation.newPasswordConfirmError
                      ? 'This field should be filled out.'
                      : validation.notMatchError
                      ? 'The password confirmation field not match.'
                      : validation.shortError
                      ? 'The password is too short.'
                      : ''
                  }
                  name="new_password_confirm"
                  value={resetInfo.new_password_confirm}
                  onChange={(e) => changeValue(e)}
                />
              </Box>

              <Box mt={2}>
                <XButton
                  label={
                    loading ? 'Changing Password...' : 'Change My Password'
                  }
                  bkcolor={theme.palette.common.black}
                  textcolor="white"
                  variant="contained"
                  onClick={changePassword}
                  width={1}
                />
              </Box>
            </>
          ) : (
            <ResetSuccess />
          )
        ) : (
          <ResetNoActive />
        )
      ) : (
        <Box position="relative" height="135px">
          <LoadingSpinner />
        </Box>
      )}
    </BaseLogin>
  );
};
