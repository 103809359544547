export const fields = {
  first_name: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['First Name is required!'],
  },
  last_name: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['Last name is required!'],
  },
  email_1: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
    validationMessages: ['Email is required!', 'It is not email.'],
  },
  pwd_1: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
      minLength: 6,
    },
    validationMessages: ['Password is required!', 'Password is too short.'],
  },
  pwd_2: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['Password is required!'],
  },
  gender: {
    invalid: false,
    value: 'M',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['Genre is required!'],
  },
  phone_number: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['Phone is required!'],
  },
  address: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {},
    validationMessages: [],
  },
  age_range: {
    invalid: false,
    value: 0,
    valid: false,
    validationRules: {},
    validationMessages: ['Select Age range!'],
  },
  postal_code: {
    invalid: false,
    value: '',
    valid: false,
    validationRules: {
      isRequired: true,
    },
    validationMessages: ['Zip/Postal Code is required!'],
  },
  tos: {
    invalid: false,
    value: false,
    valid: false,
    validationRules: {},
    validationMessages: ['You need accept Term of Condition.'],
  },
};
