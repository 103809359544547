import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  LOGIN_LOADING_STATE,
  generateHomePageUrl,
  generateLoginPageUrl,
} from 'global';

/**
 *
 * @param {string} path
 * @param {string} loginStatus
 * @param {component} Component
 * @param {boolean} exact if true route is exact
 * @param {boolean} userLogged if true route is for user logged
 */

export const RedirectRoute = ({
  path,
  loginStatus,
  Component,
  exact,
  userLogged = false,
}) => {
  const redirectPath = userLogged
    ? generateLoginPageUrl()
    : generateHomePageUrl();

  const checkLoginStatus = () =>
    userLogged
      ? loginStatus === LOGIN_LOADING_STATE.SUCCESS
      : loginStatus !== LOGIN_LOADING_STATE.SUCCESS;

  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        checkLoginStatus() ? <Component /> : <Redirect to={redirectPath} />
      }
    />
  );
};
