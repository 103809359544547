export const HREF = {
  RADIO: {
    STATION_LIST: '/radio/stations/genres/{0}/',
    STATION_INFO: '/radio/station/genres/{0}/{1}/',
  },
  SUBSCRIPTIONS: {
    SERVICE: '/subscriptions/',
    SERVICE_LIST: '/subscriptions/',
    SERVICE_INFO: '/subscriptions/{0}/',
  },
};
