import React from 'react';
import is from 'is_js';
import {
  ImageWrapper,
  LoadingSpinner,
  StyledImage,
  LoadingSpinnerContainer,
  Placeholder,
} from './styles';

const LazyImage = ({ src, alt, ...props }) => {
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
    <ImageWrapper {...props}>
      <Placeholder ref={refPlaceholder}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </Placeholder>

      <StyledImage afterLoad={removePlaceholder} alt={alt} src={src} />
    </ImageWrapper>
  );
};

export default React.memo(
  LazyImage,
  ({ alt, src }, nextImage) =>
    is.equal(alt, nextImage.alt) && is.equal(src, nextImage.src),
);
