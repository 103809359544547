import React, { useState } from 'react';
import _keys from 'lodash/keys';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterData } from 'apis/filter';
import { v4 as uuidV4 } from 'uuid';

const togglersConfig = {
  shows: { label: 'TV SHOWS', id: uuidV4() },
  movies: { label: 'MOVIES', id: uuidV4() },
};

export const ShowMovieToggle = ({ page_type: pageType }) => {
  const filterData = useSelector((s) => s.pageData.filterData);
  const dispatch = useDispatch();
  const [value, setValue] = useState(pageType);

  const handleClick = (pageType) => {
    if (pageType !== value) {
      setValue(pageType);
      dispatch(
        setFilterData({
          ...filterData,
          params: {
            ...filterData.params,
            pageType,
          },
        }),
      );
    }
  };

  return (
    <>
      {_keys(togglersConfig).map((key) => {
        const { id, label } = togglersConfig[key];
        return (
          <span key={id} aria-hidden="true" onClick={() => handleClick(key)}>
            {label}
          </span>
        );
      })}
    </>
  );
};
