import React, { useMemo } from 'react';
import _keys from 'lodash/keys';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  FormGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CheckSharp from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import { COLOR, validate, openPopUp } from 'global';
import { Link } from 'react-router-dom';
import _head from 'lodash/head';
import { LoadingSpinner } from '../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(20)}px`,
    },
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(25),
  },
  formGroup: {
    maxWidth: theme.spacing(40),
    minWidth: theme.spacing(40),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      borderRadius: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '60%',
      },
    },
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  menuIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: 'white',
  },
  dialogBack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    background: 'none',
    position: 'absolute',
    top: 0,
    padding: 0,
    color: 'white',
    cursor: 'pointer',
  },
  dialogClose: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  textfield: {
    width: '100%',
    margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    '& .MuiInput-underline': {
      color: 'white',
      '&:before': {
        borderBottomColor: 'white',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  loginButton: {
    borderRadius: theme.spacing(0.5),
    color: 'white',
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    background: `#1c6fee`,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: 'inherit',
  },
  signUpBox: {
    marginLeft: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  name: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    textAlign: 'center',
  },
  successBox: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.h6.fontSize,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  successTitle: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    color: 'white',
  },
  questionMessage: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(1),
    color: 'white',
    textAlign: 'center',
  },
  successMessage: {
    fontSize: theme.typography.h6.fontSize,
    color: '#00ef00',
    textAlign: 'center',
  },
}));

export const PremiumChannelServiceDialog = ({
  premiumService,
  openModal,
  setOpenModal,
  onBack,
  onClose,
  token,
  submitOrderForm,
  onSuccess,
}) => {
  const classes = useStyles();

  const fields = useMemo(
    () => ({
      username: {
        invalid: false,
        value: '',
        valid: false,
        validationRules: {
          isRequired: true,
        },
        validationMessages: ['Username is required!'],
      },
      password: {
        invalid: false,
        value: '',
        valid: false,
        validationRules: {
          isRequired: true,
        },
        validationMessages: ['Password is required!'],
      },
    }),
    [],
  );

  const [formData, setFormData] = React.useState(fields);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    if (openModal) {
      setIsLoading(false);
      setError(false);
      setFormData(fields);
    }
  }, [fields, openModal]);

  const validation = () => {
    let result = false;
    _keys(formData).forEach((inputIdentifier) => {
      const field = validationField(formData[inputIdentifier]);
      if (!result) {
        result = field.invalid;
      }
    });

    return result;
  };

  const validationField = (field) => {
    const validations = validate(field.value, field.validationRules);
    field.invalid = validations.includes(false);
    field.valid = !field.invalid;
    field.messages = [];
    _keys(validations).forEach((key) => {
      if (!validations[key]) {
        field.messages.push(field.validationMessages[key]);
      }
    });
    return field;
  };

  const changeValue = (value, key) => {
    const updatedFormElement = {
      ...formData[key],
      value,
    };

    setFormData({
      ...formData,
      [key]: validationField(updatedFormElement),
    });
  };

  const sendForm = (e) => {
    e.preventDefault();

    // validation
    if (!validation()) {
      const data = {};
      _keys(formData).forEach((formElementId) => {
        if (formData[formElementId].valid) {
          data[formElementId] = formData[formElementId].value;
        }
      });
      // call submit after validate data
      data.package_slug = premiumService.slug;
      setIsLoading(true);
      setError(false);
      submitOrderForm(token, data)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 'success') {
            setIsSuccess(true);
          } else {
            setError(true);
            setErrorMessage(response.response?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(true);
          setErrorMessage(err.message);
        });
    }
  };

  const onSignUpClick = (event) => {
    openPopUp(premiumService.button_url, event);
  };

  return (
    <Dialog
      className={classes.modal}
      classes={{ paper: classes.dialog }}
      open={openModal}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: '0px' }}>
        <Box>
          <Box className={classes.closeBox}>
            {!isSuccess && (
              <Box className={classes.dialogBack} onClick={() => onBack()}>
                <Box
                  component={ArrowBackIosRoundedIcon}
                  className={classes.menuIcon}
                />
                <Box ml={1} fontSize="1rem">
                  Back
                </Box>
              </Box>
            )}
            <IconButton
              className={classes.dialogClose}
              onClick={() => onClose()}
            >
              <Box component={ClearIcon} className={classes.menuIcon} />
            </IconButton>
          </Box>
          {premiumService.slug === 'locast' && isSuccess && (
            <Box className={classes.successTitle}>
              You've successfully connected!
            </Box>
          )}

          <Box className={classes.logoBox}>
            <img
              src={premiumService.logo || ''}
              height="100%"
              alt="Premium Service"
              style={{
                objectFit: 'contain',
              }}
            />
          </Box>
          {premiumService.slug === 'locast' && isSuccess && (
            <Box className={classes.successBox}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="#00ef00"
              >
                <Box className={classes.successMessage}>connected!</Box>
                <Box component={CheckSharp} />
              </Box>

              <Button
                className={classes.loginButton}
                variant="contained"
                color="primary !important"
                onClick={() => onSuccess()}
              >
                Start Watching!
              </Button>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          {premiumService.slug === 'locast' && !isSuccess && (
            <>
              <Box className={classes.questionMessage}>
                Already have an account?
              </Box>
              <FormGroup className={classes.formGroup}>
                <Box mt={1}>
                  <TextField
                    error={formData.username.invalid}
                    type="text"
                    size="small"
                    className={classes.textfield}
                    placeholder="Username"
                    helperText={_head(formData?.username?.messages)}
                    name="username"
                    id="username"
                    value={formData.username.value}
                    onChange={(e) => changeValue(e.target.value, 'username')}
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    error={formData.password.invalid}
                    type="password"
                    size="small"
                    className={classes.textfield}
                    placeholder="Password"
                    helperText={_head(formData?.password?.messages)}
                    name="password"
                    id="password"
                    value={formData.password.value}
                    onChange={(e) => changeValue(e.target.value, 'password')}
                  />
                </Box>
                {isLoading && (
                  <Box
                    width={1}
                    justifyContent="center"
                    alignItems="center"
                    height="10vh"
                    position="relative"
                  >
                    <LoadingSpinner />
                  </Box>
                )}
                {error && (
                  <Box
                    container
                    display="block"
                    alignItems="center"
                    justifyContent="center"
                    color="red"
                    mt={1}
                  >
                    {errorMessage}
                  </Box>
                )}
                {!isLoading && !isSuccess && (
                  <Box alignItems="center" justifyContent="center">
                    <Button
                      className={classes.loginButton}
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        sendForm(e);
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                )}
              </FormGroup>
            </>
          )}
          {!isLoading && (
            <Box
              container
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="white"
              mt={2}
            >
              <Box>{'FREE Live Local Channels - '}</Box>
              <Link
                className={classes.signUpBox}
                to="#"
                onClick={onSignUpClick}
              >
                Sign Up Today
              </Link>
            </Box>
          )}
          {premiumService.slug !== 'locast' && (
            <>
              <Box className={classes.name}>Coming Soon!</Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="white"
                mt={2}
              >
                <Box>Don't have an account?</Box>
                <Link
                  className={classes.signUpBox}
                  to="#"
                  onClick={onSignUpClick}
                >
                  Sign Up Today
                </Link>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
