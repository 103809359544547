import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import { PaymentDialog, UpgradePlanDialog, LoadingSpinner } from 'components';

import {
  submitUpgradePlan,
  updateChargeBee,
  updateCardInfo,
  // fetchMembershipPlanList,
  setPackageDetail,
} from 'apis';

const useStyles = makeStyles((theme) => ({
  buttonGreen: {
    display: 'inline-block',
    verticalAlign: 'top',
    background: '#477410',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: '400',
    minWidth: '100px',
    color: '#fff',
    '&:hover': {
      background: '#477410',
      opacity: '0.8',
    },
  },
}));

export const UpgradeButton = React.memo(({ onUpgraded }) => {
  const dispatch = useDispatch();
  const [
    token,
    chargeBee,
    // memberShipAddOns,
    sotalCloudPlan,
  ] = useSelector(({ auth, membership }) => [
    auth.jwt_token,
    membership.chargeBee,
    // membership.memberShipAddOns,
    membership.sotalCloudPlan,
  ]);

  const classes = useStyles();
  const [openUpgradeModal, setOpenUpgradeModal] = React.useState(false);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);

  const onSubmitedPayment = (cardInfo) => {
    dispatch(updateCardInfo(cardInfo));
    dispatch(setPackageDetail({ ...sotalCloudPlan, subscribed: true }));
    // dispatch(fetchMembershipPlanList(token));

    setOpenPaymentModal(false);
    setOpenUpgradeModal(true);
  };

  const onUpgradePlan = () => {
    setOpenUpgradeModal(false);
    onUpgraded();
  };

  return (
    <>
      {sotalCloudPlan ? (
        <Box mt={1}>
          <Button
            variant="outlined"
            onClick={() => setOpenUpgradeModal(true)}
            className={classes.buttonGreen}
          >
            Upgrade
          </Button>
        </Box>
      ) : (
        <Box
          width={'50%'}
          justifyContent="center"
          alignItems="center"
          height="70px"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      )}
      {openUpgradeModal && (
        <UpgradePlanDialog
          plans={[sotalCloudPlan]}
          token={token}
          openModal={openUpgradeModal}
          setOpenModal={setOpenUpgradeModal}
          updateMembership={submitUpgradePlan}
          onUpgradePlan={onUpgradePlan}
          setOpenPaymentModal={setOpenPaymentModal}
          hasCard={chargeBee && chargeBee.card}
          isSotal
        />
      )}

      {openPaymentModal && (
        <PaymentDialog
          plans={[sotalCloudPlan]}
          openModal={openPaymentModal}
          setOpenModal={setOpenPaymentModal}
          token={token}
          onSubmitedPayment={onSubmitedPayment}
          updateMembership={submitUpgradePlan}
          submitPaymentInfo={updateChargeBee}
          isSotal
        />
      )}
    </>
  );
});
