import React from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: (props) => ({
    backgroundColor: `${props.bkcolor} !important`,
    color: `${props.textcolor} !important`,
    borderRadius: props.radius,
    '&:hover': {
      backgroundColor: `${props.hbkcolor} !important`,
    },
  }),
  label: (props) => ({
    textTransform: props.transform,
  }),
}));

export const XButton = ({
  label = '',
  bkcolor,
  textcolor,
  radius = 0,
  transform = 'none',
  hbkcolor,
  ...props
}) => {
  if (hbkcolor === undefined) hbkcolor = bkcolor;
  const classes = useStyles({
    bkcolor,
    textcolor,
    radius,
    transform,
    hbkcolor,
  });
  const { labelAtributes, ...restProps } = props;
  return (
    <Box component={Button} {...restProps} className={classes.button}>
      <Box
        fontSize="subtitle1.fontSize"
        fontWeight="fontWeightBold"
        {...labelAtributes}
        className={classes.label}
      >
        {label}
      </Box>
    </Box>
  );
};
