import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { LoadingSpinner } from 'components';

const useStyles = makeStyles((theme) => ({
  imageBox: {
    position: 'relative',
    padding: `${theme.spacing(0.5)}px 0`,
    '&:hover > div': {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  shadeBox: {
    background: '#000000cc',
    position: 'absolute',
    top: theme.spacing(0.5),
    left: 0,
    bottom: theme.spacing(0.5),
    right: 0,
    opacity: 0,
    height: 'calc(100% - 13px)',
  },
  imgItem: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  subscribedText: {
    position: 'absolute',
    left: 0,
    bottom: 9,
    background: `${window.BRAND.primary_color}`,
    padding: `0 ${theme.spacing(1)}px`,
    color: 'white',
  },
  removeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: 'red',
    opacity: 0,
    cursor: 'pointer',
  },
  addBox: {
    width: `90%`,
    position: 'absolute',
    left: `50%`,
    top: `50%`,
    transform: `translateX(-50%) translateY(-50%)`,
    opacity: 0,
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
    cursor: 'pointer',
  },
}));

export const AppsItem = React.memo(({ item, toggleSub, onCreateAccount }) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setIsProcessing(false);
  }, [item]);

  return (
    <Box className={classes.imageBox}>
      <img
        src={item.tile_logo || item.image}
        className={classes.imgItem}
        alt=""
      />
      {item.subscribed && <Box className={classes.subscribedText}>ADDED</Box>}
      <Box className={classes.shadeBox}></Box>
      {!isProcessing && item.subscribed && (
        <Box
          className={classes.removeButton}
          onClick={() => {
            setIsProcessing(true);
            toggleSub(item);
          }}
        >
          <CloseRoundedIcon />
        </Box>
      )}
      {!isProcessing && !item.subscribed && (
        <Box className={classes.addBox}>
          <Box
            className={classes.addButton}
            onClick={() => {
              setIsProcessing(true);
              toggleSub(item);
            }}
          >
            <AddCircleOutlineRoundedIcon />
            <Box ml={1}>Add to My Apps</Box>
          </Box>
          <Box
            className={classes.addButton}
            onClick={(event) => onCreateAccount(event, item)}
          >
            <MonetizationOnOutlinedIcon />
            <Box ml={1}>Create Account</Box>
          </Box>
        </Box>
      )}
      {isProcessing && (
        <Box className={classes.shadeBox} style={{ opacity: 1 }}>
          <Box
            width={1}
            justifyContent="center"
            alignItems="center"
            height={1}
            position="relative"
          >
            <LoadingSpinner />
          </Box>
        </Box>
      )}
    </Box>
  );
});
