import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { LoadingSpinner as Loader } from 'components';
import { fetchSourceList, fetchMovieGenreList, fetchShowGenreList } from 'apis';
import { setFilterData } from 'apis/filter';
import {
  paramsToObject,
  PAGE_FILTER,
  MOVIE_RATINGS,
  SHOW_RATINGS,
  chunkArray,
  getBrowser,
} from 'global';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filterOptionList: {
    position: 'absolute',
    background: '#252525',
    left: 0,
    top: 54,
    borderRadius: 6,
    lineHeight: '30px',
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'left',
    padding: '20px 20px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'default',
    minWidth: '100%',
    maxWidth: '60vw',
    overflowY: 'auto',
    zIndex: 10000,
  },
  filterOptionColumn: { minWidth: 'max-content' },
  filterOption: {
    paddingLeft: 20,
    cursor: 'pointer',
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  filterOptionSelected: {
    cursor: 'default !important',
    color: theme.palette.primary.main,
  },
  arrow: {
    fontSize: '40px',
    lineHeight: '9px',
    color: '#252525',
    position: 'absolute',
    top: 32,
    left: '50%',
    marginLeft: '-8px',
  },
  loaderWrapper: {
    margin: '0 auto',
    minWidth: 180,
    minHeight: 150,
  },
  networkList: { left: '-40vw' },
  loading: { left: '0 !important' },
  genreList: {},
  subscriptionList: {},
}));

const getServices = (subscriptionList) => {
  const services = subscriptionList
    .filter((sub) => sub.code !== 'CABLE')
    .map((service) => ({ value: service.code, label: service.name }));
  return services;
};

export const FilterOptionList = ({ dropdown, buttonRef, close, onPage }) => {
  const [dropdownStyles, setDropdownStyles] = React.useState({});
  const wrapperRef = React.useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    subscriptionList,
    showGenres,
    movieGenres,
    sourceList,
    filterData,
  ] = useSelector(({ subscriptions, genres, shows, pageData }) => [
    subscriptions.subscriptionList,
    genres.show,
    genres.movie,
    shows.sourceList,
    pageData.filterData,
  ]);

  let optionList = [];
  if (dropdown === 'GENRE') {
    if (filterData.params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE) {
      optionList = movieGenres;
    } else {
      optionList = showGenres;
    }
    optionList = optionList.map((option) => ({
      value: option.slug,
      label: option.name,
    }));
  }
  if (dropdown === 'SERVICE') {
    optionList = getServices(subscriptionList);
  }
  if (dropdown === 'NETWORK') {
    optionList = sourceList.map((source) => ({
      value: source.id,
      label: source.name,
    }));
  }
  if (dropdown === 'RATING') {
    if (filterData.params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE) {
      optionList = MOVIE_RATINGS;
    } else {
      optionList = SHOW_RATINGS;
    }
  }
  if (dropdown === 'SORT') {
    let dataOrder = 'first_aired';
    if (filterData.params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE) {
      dataOrder = 'release_year';
    }
    optionList = [
      { label: 'A -> Z', value: 'name' },
      { label: 'Z -> A', value: '-name' },
      { label: 'Release Date (newest to oldest)', value: `-${dataOrder}` },
      { label: 'Release Date (oldest to newest)', value: dataOrder },
      { label: 'Popularity (most to least)', value: 'popularity' },
      { label: 'Popularity (least to most)', value: '-popularity' },
    ];
  }
  optionList = chunkArray(optionList, 13);

  React.useEffect(() => {
    if (getBrowser() !== 'safari') return;

    const minWidth =
      dropdown === 'SORT'
        ? '230%'
        : dropdown === 'GENRE' &&
          filterData.params.pageType === PAGE_FILTER.PAGE_TYPE.MOVIE
        ? '217%'
        : dropdown === 'GENRE'
        ? '380%'
        : '';

    setDropdownStyles({ minWidth });
  }, [dropdown, filterData, getBrowser]);

  useEffect(() => {
    if (optionList.length === 0) {
      if (dropdown === 'GENRE') {
        dispatch(fetchShowGenreList());
        dispatch(fetchMovieGenreList());
      }
      if (dropdown === 'NETWORK') {
        dispatch(fetchSourceList());
      }
    }
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = React.useCallback(
    (event) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      )
        close();
    },
    [wrapperRef, buttonRef],
  );

  const addFilterData = (type, value, label) => {
    const { params, selectedOptions } = filterData;
    const newParams = { ...params };
    let newOptions = [...selectedOptions];
    if (newParams[type] === undefined) newParams[type] = [];
    newParams[type] = newParams[type].concat([value]);
    newOptions = newOptions.concat([{ type, label, value }]);
    let filteredShowGenres = showGenres;
    let filteredMovieGenres = movieGenres;
    let filteredShowRatings = SHOW_RATINGS;
    let filteredMovieRatings = MOVIE_RATINGS;
    if (type === 'genre') {
      filteredShowGenres = filteredShowGenres.filter((g) => g.slug === value);
      filteredMovieGenres = filteredMovieGenres.filter((g) => g.slug === value);
    }
    if (type === 'rating') {
      filteredShowRatings = filteredShowRatings.filter(
        (r) => r.value === value,
      );
      filteredMovieRatings = filteredMovieRatings.filter(
        (r) => r.value === value,
      );
    }
    const urlParams = new URLSearchParams(history.location.search);
    const entries = urlParams.entries();
    const paramsArray = paramsToObject(entries);
    paramsArray.page = 1;
    const newSearch = `?${new URLSearchParams(paramsArray).toString()}`;
    onPage(1);
    history.push(history.location.pathname + newSearch);
    dispatch(
      setFilterData({
        ...filterData,
        params: { ...newParams, page: 1 },
        selectedOptions: newOptions,
        isReloadShow:
          filteredShowGenres.length > 0 && filteredShowRatings.length > 0,
        isReloadMovie:
          filteredMovieGenres.length > 0 && filteredMovieRatings.length > 0,
      }),
    );
  };

  const setSort = (value, label) => {
    const { selectedOptions } = filterData;
    const newOrdering = { type: 'ordering', label, value };
    const typeList = selectedOptions.map((option) => option.type);
    let newOptions = [];
    if (typeList.includes('ordering')) {
      newOptions = selectedOptions.map((option) =>
        option.type === 'ordering' ? newOrdering : option,
      );
    } else {
      newOptions = selectedOptions.concat([newOrdering]);
    }
    dispatch(
      setFilterData({
        ...filterData,
        ordering: value,
        selectedOptions: newOptions,
      }),
    );
  };

  const handleClick = (event, value, label) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      genre = [],
      subscription = [],
      websources = [],
      rating = [],
    } = filterData.params;
    if (dropdown === 'GENRE' && !genre.includes(value)) {
      addFilterData('genre', value, label);
    }
    if (dropdown === 'SERVICE' && !subscription.includes(value)) {
      addFilterData('subscription', value, label);
    }
    if (dropdown === 'NETWORK' && !websources.includes(value)) {
      addFilterData('websources', value, label);
    }
    if (dropdown === 'RATING' && !rating.includes(value)) {
      addFilterData('rating', value, label);
    }
    if (dropdown === 'SORT' && filterData.ordering !== value) {
      setSort(value, label);
    }
  };

  const checkInFilter = (value) => {
    const {
      genre = [],
      subscription = [],
      websources = [],
      rating = [],
    } = filterData.params;
    return (
      (dropdown === 'GENRE' && genre.includes(value)) ||
      (dropdown === 'SERVICE' && subscription.includes(value)) ||
      (dropdown === 'NETWORK' && websources.includes(value)) ||
      (dropdown === 'RATING' && rating.includes(value)) ||
      (dropdown === 'SORT' && filterData.ordering === value)
    );
  };

  return (
    <>
      <ArrowDropUpIcon className={classes.arrow} />
      <Box
        ref={wrapperRef}
        style={dropdownStyles}
        className={clsx(classes.filterOptionList, {
          [classes.networkList]: dropdown === 'NETWORK',
          [classes.genreList]: dropdown === 'GENRE',
          [classes.subscriptionList]: dropdown === 'SERVICE',
          [classes.loading]: optionList.length === 0,
        })}
      >
        {(!optionList.length || optionList.length === 0) && (
          <Box className={classes.loaderWrapper}>
            <Loader type="Oval" color="#00BFFF" height="40" width="40" />
          </Box>
        )}
        {optionList.mapWithKey((chunks) => (
          <Box key={chunks.key} className={classes.filterOptionColumn}>
            {chunks.mapWithKey((option) => (
              <Box
                key={option.key}
                className={clsx(classes.filterOption, {
                  [classes.filterOptionSelected]: checkInFilter(option.value),
                })}
                onClick={(event) => {
                  handleClick(event, option.value, option.label);
                }}
              >
                {' '}
                {option.label}{' '}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
};
