import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useTheme, TextField } from '@material-ui/core';
import { XButton } from 'components';
import { COLOR, validateEmail } from 'global';
import { resetPasswordRequest as resetPassword } from 'apis';
import { BaseLogin } from './Base';

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: COLOR.b_bg,
      fontSize: '14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
        borderColor: theme.palette.text.secondary,
      },
      '&:hover fieldset': { borderColor: theme.palette.text.secondary },
      '&.Mui-focused fieldset': {
        boxShadow: `0px 0px 5px 1px ${theme.palette.text.secondary}`,
        borderColor: theme.palette.text.secondary,
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  text: { color: '#333333' },
}));

const ResetSucess = ({ email }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  return (
    <>
      <Box color="gray" fontSize="h4.fontSize">
        Password Reset Successful
      </Box>
      <Box mt={2} className={classes.text}>
        We've emailed your username along with instructions for resetting your
        password to the email address you submitted (<strong>{email}</strong>).
        If you do not see the password reset email in your main inbox, please be
        sure to check your SPAM and JUNK folders.
      </Box>
      <Box mt={2}>
        <XButton
          label="Return to Login"
          bkcolor={theme.palette.common.black}
          textcolor="white"
          variant="contained"
          onClick={() => history.push('/login')}
          width={1}
        />
      </Box>
    </>
  );
};

export const ResetPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const resetPasswordRequest = useSelector((s) => s.auth.resetPasswordRequest);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [resetInfo, setResetInfo] = React.useState({ email: '' });
  const [errors, setErrors] = React.useState([]);
  const [validation, setValidation] = React.useState({
    emailEmptyError: false,
    emailError: false,
  });

  React.useEffect(() => {
    if (resetPasswordRequest && resetPasswordRequest.status === 'OK') {
      setSuccess(true);
      setLoading(false);
    } else if (
      resetPasswordRequest &&
      resetPasswordRequest.status === 'ERROR'
    ) {
      setErrors(
        Object.keys(resetPasswordRequest.error).reduce(
          (b, key) => [...b, ...resetPasswordRequest.error[key]],
          [],
        ),
      );
      setLoading(false);
    }
  }, [resetPasswordRequest]);

  const isValid = () => {
    setValidation({
      emailEmptyError: resetInfo.email.length === 0,
      emailError: !validateEmail(resetInfo.email),
    });
    return resetInfo.email.length > 0 && validateEmail(resetInfo.email);
  };

  const changeValue = (e) => {
    e.preventDefault();
    setResetInfo({ [e.target.name]: e.target.value });
  };

  const sendRequest = () => {
    if (isValid() && !loading) {
      setLoading(true);
      const confirmUrl = `${window.location.origin}/members/password/reset/confirm`;
      dispatch(resetPassword({ ...resetInfo, confirm_url: confirmUrl }));
    }
  };

  return (
    <BaseLogin supportUrl singUp>
      {!success ? (
        <>
          <Box color="gray" fontSize="h4.fontSize">
            Password Reset
          </Box>
          <Box mt={2} className={classes.text}>
            Forgot your username or password?
          </Box>
          <Box mt={2} className={classes.text}>
            Enter your e-mail address below, and we'll e-mail you your username
            along with instructions for setting a new password.
          </Box>
          <Box mt={2} textAlign="left">
            <Box fontSize="body1.fontSize" color="gray">
              Email
            </Box>
            <TextField
              error={
                validation.emailEmptyError ||
                validation.emailError ||
                (resetPasswordRequest.status === 'ERROR' && !loading)
              }
              type="text"
              variant="outlined"
              size="small"
              className={classes.textfield}
              placeholder="Email"
              helperText={`
                ${
                  validation.emailEmptyError
                    ? 'This field should be filled out.'
                    : ''
                }
                ${
                  validation.emailError && !validation.emailEmptyError
                    ? 'This field is not an email format.'
                    : ''
                }
              `}
              name="email"
              value={resetInfo.email}
              onChange={(e) => changeValue(e)}
            />
          </Box>
          {resetPasswordRequest.status === 'ERROR' && !loading && (
            <Box mt={2} color="red">
              {errors.mapWithKey((error) => (
                <Box key={error.key}>* {error}</Box>
              ))}
            </Box>
          )}
          <Box mt={2}>
            <XButton
              label={loading ? 'Sending...' : 'Reset My Password'}
              bkcolor={theme.palette.common.black}
              textcolor="white"
              variant="contained"
              onClick={sendRequest}
              width={1}
            />
          </Box>
        </>
      ) : (
        <ResetSucess email={resetInfo.email} />
      )}
    </BaseLogin>
  );
};
