import axios from 'axios';
import { PROFILE_PAGE } from 'global';
import { setProfile, updateProfile } from 'redux/reducers/profile';

export const fetchMemberProfile = (token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(PROFILE_PAGE.PROFILE_API, {
        headers,
      })
      .then((response) =>
        dispatch(setProfile({ field: 'profile', value: response.data })),
      );
};

export const updateMemberProfile = (token, formData) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .post(PROFILE_PAGE.PROFILE_API, formData, { headers })
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(
            updateProfile({
              errors: [],
              messages:
                response.data && response.data.messages
                  ? response.data.messages
                  : [],
              status: 'ok',
              formData,
            }),
          );
        }
        if (response.data.status === 'fail') {
          return dispatch(
            updateProfile({
              errors: response.data.errors,
              messages:
                response.data && response.data.messages
                  ? response.data.messages
                  : [],
              status: 'fail',
            }),
          );
        }
      });
};
export const upgradeMemberProfile = (token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(PROFILE_PAGE.UPGRADE_API, {
        headers,
      })
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(
            setProfile({
              field: 'upgradeProfile',
              value: {
                status: 'ok',
                renew_url: response.data.renew_url,
                upgrade_url: response.data.upgrade_url,
              },
            }),
          );
        }
        if (response.data.status === 'fail') {
          return dispatch(
            setProfile({
              field: 'upgradeProfile',
              value: { status: 'fail' },
            }),
          );
        }
      });
};

export const cancelMemberProfile = (token, data) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .post(PROFILE_PAGE.CANCEL_API, data, {
        headers,
      })
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(
            setProfile({
              field: 'cancelProfile',
              value: {
                status: 'ok',
                errors: [],
                message: response.data.message,
              },
            }),
          );
        }
        if (response.data.status === 'fail') {
          return dispatch(
            setProfile({
              field: 'cancelProfile',
              value: { status: 'fail', errors: response.data.errors },
            }),
          );
        }
        return dispatch(
          setProfile({
            field: 'cancelProfile',
            value: { status: 'fail', errors: [] },
          }),
        );
      });
};

export const stopAutoRenew = (token) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(PROFILE_PAGE.STOP_RENEW_API, {
        headers,
      })
      .then((response) =>
        dispatch(
          setProfile({ field: 'stopStatus', value: response.data.status }),
        ),
      );
};

export const updateMemberBilling = (token, formData) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .post(PROFILE_PAGE.MEMBER_BILLING_API, formData, {
        headers,
      })
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(
            updateProfile({
              errors: [],
              messages:
                response.data && response.data.messages
                  ? response.data.messages
                  : [],
              status: 'ok',
            }),
          );
        }
        if (response.data.status === 'fail') {
          return dispatch(
            updateProfile({
              errors:
                response.data && response.data.errors
                  ? response.data.errors
                  : [],
              messages:
                response.data && response.data.messages
                  ? response.data.messages
                  : [],
              status: 'fail',
            }),
          );
        }
      });
};
