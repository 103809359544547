import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { getSearchResultsByType } from 'apis';
import Pagination from '@material-ui/lab/Pagination';

export const TablePagination = withRouter(
  ({
    setCurrentPage,
    pageSize = 24,
    currentPage,
    totalItems,
    type,
    staticContext,
    ...props
  }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const classes = useStyles();

    const shouldDisplayPagination = totalItems > pageSize;
    const shouldHidePrevButton = !(currentPage - 1);
    const count = Math.ceil(totalItems / pageSize);

    React.useEffect(() => {
      if (type && currentPage === 1) {
        changeCurrentPage(currentPage);
      }
    }, [type]);

    const changeCurrentPage = (page) => {
      setCurrentPage(page);

      const { search } = location;
      dispatch(
        getSearchResultsByType({
          type,
          params: { search, page },
        }),
      );
    };

    const onChangeCurrentPage = (_, page) => {
      if (currentPage !== page) changeCurrentPage(page);
    };

    if (!shouldDisplayPagination) {
      return null;
    }

    return (
      <Pagination
        {...props}
        className={classes.root}
        color="primary"
        size="small"
        count={count || 0}
        page={currentPage}
        hidePrevButton={shouldHidePrevButton}
        onChange={onChangeCurrentPage}
      />
    );
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },

    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      textDecoration: 'none',
      color: '#fff !important',
    },
  },
}));
