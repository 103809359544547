import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterData } from 'apis/filter';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FilterOptionList } from './FilterOptionList';

const useStyles = makeStyles(() => ({
  filterLabel: {
    paddingLeft: 20,
    paddingRight: 10,
    color: '#ffffff',
  },
  filterBtn: {
    color: '#fff',
    background: '#252525',
    marginLeft: 10,
    cursor: 'pointer',
    lineHeight: '40px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: '700',
    position: 'relative',

    '@media (max-width: 576px)': {
      lineHeight: '30px',
      fontSize: 14,
      padding: '0px 10px',
    },

    '& .fa-caret-up': {
      fontSize: 30,
      lineHeight: 9,
      color: '#252525',
      position: 'absolute',
      top: 45,
      left: '50%',
      marginLeft: '-8px',
    },
    '& svg': {
      verticalAlign: 'middle',
    },
  },
}));

export const FilterDropDown = ({ label, dropdown, onPage }) => {
  const filterData = useSelector((s) => s.pageData.filterData);
  const dispatch = useDispatch();
  const buttonRef = React.useRef();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = (dropdown) => {
    let newDropdown = '';
    if (dropdown !== filterData.dropdown) {
      newDropdown = dropdown;
    }
    dispatch(
      setFilterData({
        ...filterData,
        dropdown: newDropdown,
      }),
    );
  };
  return (
    <>
      {label && <span className={classes.filterLabel}>{label}:</span>}
      <Box ref={buttonRef} className={classes.filterBtn} pl={3} pr={2}>
        <span
          aria-hidden="true"
          role="button"
          onClick={() => {
            handleClick(dropdown);
            setOpen(true);
          }}
        >
          {dropdown} <KeyboardArrowDownIcon />
        </span>
        {filterData && dropdown === filterData.dropdown && open && (
          <FilterOptionList
            dropdown={dropdown}
            buttonRef={buttonRef}
            close={() => setOpen(false)}
            onPage={onPage}
          />
        )}
      </Box>
    </>
  );
};
