import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'movies',
  initialState: {
    movieLinks: [],
    movieInfo: [],
    movieUrl: undefined,
    movieTrailerUrl: undefined,
  },
  reducers: {
    onSetMovies: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export default slice.reducer;

const { onSetMovies } = slice.actions;
export const setMovies = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetMovies({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
