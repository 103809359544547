import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { staticUrl } from 'global';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'white',
    padding: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'space-between',
    left: 0,
    bottom: 0,
    height: 'auto !important',
  },
  labelFree: {
    backgroundColor: '#477410',
  },
  imageKey: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  labelFreeKey: {
    backgroundColor: '#477410',
  },
  labelAuthenticated: {
    backgroundColor: '#dc6403',
  },
  labelSubscribed: {
    backgroundColor: '#dc6403',
  },
  labelSubscriptions: {
    color: '#477410',
    backgroundColor: '#fff',
  },
  labelPPV: {
    backgroundColor: '#059197',
  },
}));

export const AccessModeFlag = (props) => {
  const classes = useStyles();

  switch (props.label) {
    case 'free':
      return (
        <Box className={`${classes.label} ${classes.labelFree}`}>FREE</Box>
      );
    case 'free_unlock':
      return (
        <Box className={`${classes.label} ${classes.labelFreeKey}`}>
          <Box textAlign="center">FREE</Box>
          <img
            className={classes.imageKey}
            alt="lock-open-alt"
            src={staticUrl('images/icons/Unlock_icon_white.png')}
          />
        </Box>
      );
    case 'subscribed':
      return (
        <Box className={`${classes.label} ${classes.labelSubscribed}`}>
          SUBSCRIBED
        </Box>
      );
    case 'authenticated':
      return (
        <Box className={`${classes.label} ${classes.labelAuthenticated}`}>
          AUTHENTICATED
        </Box>
      );
    case 'subscriptions':
      return (
        <Box className={`${classes.label} ${classes.labelSubscriptions}`}>
          PREMIUM
        </Box>
      );
    case 'ppv':
      return (
        <Box className={`${classes.label} ${classes.labelPPV}`}>
          PAY-PER-VIEW
        </Box>
      );
    default:
      return '';
  }
};
