import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  makeStyles,
  Input,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import clsx from 'clsx';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 5px',
    fontSize: '15px',
    color: COLOR.t_white_light,
    '&:before': {
      borderBottom: `1px solid ${COLOR.border_black_light2} !important`,
    },
    '& .MuiInputBase-input': {
      height: '18px',
      boxShadow: `0 0 0 1000px ${COLOR.b_black_light} inset !important`,
      textFillColor: `${COLOR.t_white_light} !important`,
    },
  },
  selectIcon: {
    color: COLOR.t_white_light,
  },
  menu: {
    borderRadius: 0,
    backgroundColor: COLOR.b_black_light,
    border: `1px solid ${COLOR.border_black_light2} !important`,
    '& li': {
      color: COLOR.t_white_light,
      fontSize: '15px',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        color: COLOR.t_white_light,
        backgroundColor: 'rgba(0, 0, 0, 0.4) !important',
      },
    },
  },
}));

export const FormInput = ({
  type = 'text',
  className,
  errorList = [],
  ...props
}) => {
  const classes = useStyles();

  const isTextField = () =>
    type === 'text' ||
    type === 'email' ||
    type === 'password' ||
    type === 'number' ||
    type === 'textarea';

  const renderErrorList = () =>
    props.error &&
    errorList.length > 0 &&
    errorList.mapWithKey((error) => (
      <FormHelperText key={error.key} error={props.error}>
        {typeof error === 'string' ? error : error.message}
      </FormHelperText>
    ));

  const renderTextField = () => (
    <Input
      type={type}
      className={clsx(classes.root, className)}
      fullWidth
      {...props}
    />
  );

  const renderSelectField = () => (
    <Select
      fullWidth
      displayEmpty
      className={clsx(classes.root, className)}
      IconComponent={KeyboardArrowDownIcon}
      classes={{
        icon: classes.selectIcon,
      }}
      MenuProps={{ classes: { paper: classes.menu } }}
      {...props}
    >
      <MenuItem value="">--{props.placeholder}--</MenuItem>
      {props.items.mapWithKey((item) => (
        <MenuItem key={item.key} value={item[0]}>
          {item[1]}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      {isTextField() && renderTextField()}
      {type === 'select' && renderSelectField()}
      {renderErrorList()}
    </>
  );
};
