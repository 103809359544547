import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { COLOR } from 'global';
import { LoadingSpinner } from '../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    margin: `0 ${theme.spacing(10)}px`,
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(25),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      [theme.breakpoints.up('md')]: {
        maxWidth: '60%',
      },
    },
    borderRadius: theme.spacing(2),
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  dialogClose: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  menuIcon: {
    width: '100%',
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  name: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'white',
  },
  buttonBox: {
    borderRadius: theme.spacing(0.5),
    color: 'white',
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    background: `${window.BRAND.primary_color}`,
  },
}));

export const RemovePremiumDialog = ({
  token,
  premiumService,
  openModal,
  setOpenModal,
  onConfirm,
  submitRemovePremium,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRemoved, setIsRemoved] = React.useState(false);
  const [error, setError] = React.useState(false);
  const classes = useStyles();

  const onRemovePremium = () => {
    if (isRemoved) {
      onConfirm();
    } else {
      setIsLoading(true);
      setError(false);
      // call api
      submitRemovePremium(token, premiumService.slug)
        .then((response) => {
          setIsLoading(false);
          if (response.status.toLowerCase() === 'success') {
            setIsRemoved(true);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  };

  return (
    <Dialog
      className={classes.modal}
      classes={{ paper: classes.dialog }}
      open={openModal}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>
        <Box className={classes.closeBox}>
          <IconButton
            className={classes.dialogClose}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <Box component={ClearIcon} className={classes.menuIcon} />
          </IconButton>
        </Box>
        <Box className={classes.name}>
          {isRemoved ? 'Success!' : `Remove ${premiumService.name}`}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          {isRemoved ? (
            <Box className={classes.description}>
              {`You have successfully removed ${premiumService.name}
              from your Channels.`}
            </Box>
          ) : (
            <Box className={classes.description}>
              {`Are you sure you want to remove ${premiumService.name} from your Channels?`}
            </Box>
          )}
          {isLoading && (
            <Box
              width={1}
              justifyContent="center"
              alignItems="center"
              height="10vh"
              position="relative"
            >
              <LoadingSpinner />
            </Box>
          )}
          {error && (
            <Box color="red" mt={1}>
              Remove premium could not be processed at this time. <br />
              Please contact &nbsp;
              <Link to={window.BRAND.support_url} target="_blank">
                Support
              </Link>
              &nbsp; for more information.
            </Box>
          )}
          {!isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                className={classes.buttonBox}
                onClick={() => {
                  onRemovePremium();
                }}
              >
                {isRemoved ? 'Continue' : 'Confirm'}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
