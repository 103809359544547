import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMuteChannels from '../../InformationIcon/hooks/useMuteChannels';

const useStyles = makeStyles(() => ({
  videoContainer: (props) => ({
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',

    '& .jwplayer.jw-flag-aspect-mode': {
      height: '100% !important',
      display: 'block !important',
    },

    '& .jw-slider-horizontal .jw-slider-container': {
      display: props.controls ? 'block' : 'none',
    },
    '& .jw-icon-rewind': {
      display: 'none',
    },
    '& .jw-icon-playback': {
      display: 'none',
    },
    '& .jw-settings-sharing': {
      display: 'none',
    },
    '& .jw-icon-settings': {
      display: 'none',
    },
  }),
}));

export const JWTPlayer = React.memo(
  ({ url, isPlaying = true, isLoop, controls, startPosition = undefined }) => {
    const classes = useStyles({ controls });
    const { hasMutedChannelsBehind } = useMuteChannels();

    React.useEffect(() => {
      if (hasMutedChannelsBehind) {
        return;
      }
      window.jwplayer('jwPlayer').setMute();
    }, [hasMutedChannelsBehind]); //eslint-disable-line

    React.useEffect(() => {
      loadPlayer();

      return () => {
        if (window.jwplayer('jwPlayer')) {
          window.jwplayer('jwPlayer').remove();
        }
      };
    }, [url, isLoop]); //eslint-disable-line

    React.useEffect(() => {
      if (window.jwplayer('jwPlayer')) {
        if (isPlaying) {
          window.jwplayer('jwPlayer').play(true);
        } else {
          window.jwplayer('jwPlayer').pause(true);
        }
      }
    }, [isPlaying]); //eslint-disable-line

    const loadPlayer = () => {
      if (window.jwplayer) {
        window.jwplayer.key = 'NlWEzJDuEQ6ohoYwIaUXrE8IAdqST2pYap6uXQ==';

        window.jwplayer('jwPlayer').setup({
          playlist: [
            {
              sources: [
                {
                  file: url,
                },
              ],
            },
          ],
          playbackRateControls: false,
          autostart: true,
          repeat: isLoop,
          primary: 'html5',
          width: '100%',
        });

        if (startPosition) {
          window.jwplayer('jwPlayer').seek(startPosition);
        }

        window.jwplayer('jwPlayer').on('error', () => {
          if (isPlaying) {
            window.jwplayer('jwPlayer').play(true);
          } else {
            window.jwplayer('jwPlayer').pause(true);
          }
        });
      }
    };

    return (
      <Box className={classes.videoContainer}>
        <Box id="jwPlayer" />
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.url === nextProps.url &&
    prevProps.isLoop === nextProps.isLoop &&
    prevProps.isPlaying === nextProps.isPlaying,
);
