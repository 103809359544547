import React, { Suspense } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CssBaseline, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { RedirectRoute } from 'components';
import { ResetConfirm } from './LoginPage/ResetConfirm';
import { ResetPage } from './LoginPage/ResetPage';
import { StartPage, RegisterPage } from './RedemptionPage';
import { AltLogin } from './LoginPage/AltLogin';
import * as apis from '../apis';

const lazyTimeOut = 1000;

const LoginPage = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./LoginPage')), lazyTimeOut);
    }),
);
const HomePage = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./HomePage')), lazyTimeOut);
    }),
);

const useStyles = makeStyles((theme) => ({
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  img: {
    maxHeight: theme.spacing(8),
  },
  animatedItem: {
    animation: `$myEffect 1000ms`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit 1000ms`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.4,
    },
  },
}));

export const AppRouter = () => {
  const classes = useStyles();
  const loginInfoStatus = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [blink, setBlink] = React.useState(true);
  const settingsLoaded = !!localStorage.getItem('settings');

  const timer = React.useRef();

  React.useEffect(() => dispatch(apis.verifyToken(loginInfoStatus)), [
    loginInfoStatus,
  ]);

  React.useEffect(() => {
    if (['loading', 'failure'].includes(loginInfoStatus.loginStatus)) {
      dispatch(apis.logout());
    }
    timer.current = setInterval(() => {
      setBlink((blink) => !blink);
    }, 1000);
    setTimeout(() => clearInterval(timer.current), lazyTimeOut);

    if (!localStorage.getItem('settings')) apis.getBrandSettings();

    return () => clearInterval(timer.current);
  }, [dispatch, loginInfoStatus]);

  return (
    <Box position="absolute" width={1} height="100%">
      <CssBaseline />
      {settingsLoaded ? (
        <Suspense
          fallback={() => (
            <Box
              position="absolute"
              width={1}
              display="flex"
              height="100%"
              bgcolor="black"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                flexDirection="column"
                className={clsx({
                  [classes.animatedItem]: blink,
                  [classes.animatedItemExiting]: !blink,
                })}
              >
                <img
                  src={window.BRAND.logo}
                  alt="logo"
                  className={classes.img}
                />
                <Box color={'white'} textAlign={'center'}>
                  Loading...
                </Box>
              </Box>
            </Box>
          )}
        >
          <Router>
            <Switch>
              <RedirectRoute
                path="/login/"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={LoginPage}
              />
              <RedirectRoute
                path="/members/alt-login/"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={AltLogin}
              />
              <RedirectRoute
                path="/members/password/reset/"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={ResetPage}
              />
              <RedirectRoute
                path="/members/password/reset/confirm/:uidb64/:token/"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={ResetConfirm}
              />
              <RedirectRoute
                path="/start/"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={StartPage}
              />
              <RedirectRoute
                path="/register/:code"
                exact
                loginStatus={loginInfoStatus.loginStatus}
                Component={RegisterPage}
              />
              <RedirectRoute
                path="/"
                userLogged
                loginStatus={loginInfoStatus.loginStatus}
                Component={HomePage}
              />
            </Switch>
          </Router>
        </Suspense>
      ) : (
        <Box className={classes.center}>loading...</Box>
      )}
    </Box>
  );
};
