import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'search',
  initialState: {
    resultByType: [],
    result: [],
    lastSearch: '',
    relatedShows: [],
    relatedMovies: [],
  },
  reducers: {
    onSetSearch: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onSetResultByType: (state, action) => {
      const { result, type } = action.payload;
      const typeIndex = state.result.findIndex((r) => r.type === type);
      state.result[typeIndex].items = result;
      state.resultByType = result;
    },
  },
});

export default slice.reducer;

const { onSetSearch, onSetResultByType } = slice.actions;
export const setSearch = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetSearch({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
export const setResultByType = (payload) => async (dispatch) => {
  try {
    dispatch(onSetResultByType(payload));
  } catch (e) {
    return console.error(e.message);
  }
};
