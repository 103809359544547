import moment from 'moment';
import _keys from 'lodash/keys';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { STATES_AND_PROVINCES, STATIC_URL, FAVORITE_TYPE } from './constants';

export const addModeToUrl = (url, mode) => {
  if (!url) {
    url = '';
  }
  if (mode) {
    return updateURLParameter(url, 'mode', mode);
  }
  return url;
};

export const addHttps = (url) => {
  if (url?.includes('http:')) url = url.replace('http', 'https');
  if (!/^(?:f|ht)tps?:\/\//.test(url)) return `https://${url}`;
  return url;
};

export const checkStateAndSetState = (state, setState, value) => {
  if (state !== value) setState(value);
};

export const chunkArray = (data, n) => {
  const newArray = [];
  for (let i = 0, j = 0; i < data.length; i += 1) {
    if (i >= n && i % n === 0) j += 1;
    newArray[j] = newArray[j] || [];
    newArray[j].push(data[i]);
  }
  return newArray;
};

export const customUrlSearchParams = (query) =>
  /*eslint-disable */
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
// eslint-disable-next-line
/*eslint-enable */

export const dateformat = (str) => {
  const months = [
    'Jan.',
    'Feb.',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
  if (!str) return '';
  const dateparts = str.split('-');
  const givendate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2]);
  const year = givendate.getFullYear();
  const month = months[givendate.getMonth()];
  const date = givendate.getDate();
  return `${month} ${date}, ${year}`;
};

export const generateHomePageUrl = () => {
  const search = window.location.search.replaceAll('&', '%26');
  const query = new URLSearchParams(search);
  const res = query.get('next') !== '/' ? query.get('next') : '/channels/';
  return res;
};

export const generateLoginPageUrl = () => {
  const path = window.location.pathname + window.location.search;
  return `/login/?next=${path !== '/' ? path : '/channels/'}`;
};

export const getAccessModeData = (data, user_subscriptions = []) => {
  if (!data || !data.modes) {
    return { label: '', sources: [] };
  }
  if (data.modes.includes('free')) {
    const unlockList = data.web_sources.free.filter(
      (source) => source.free_signup_required,
    );
    if (unlockList.length > 0) {
      return { label: 'free_unlock', sources: data.web_sources.free };
    }
    return { label: 'free', sources: data.web_sources.free };
  }
  const subscribedList = data.subscription_services.filter((value) =>
    user_subscriptions.includes(value),
  );
  if (data.modes.includes('authenticated')) {
    if (user_subscriptions.includes('CABLE')) {
      return { label: 'subscribed', sources: data.web_sources.authenticated };
    }
    if (!subscribedList.length) {
      return {
        label: 'subscriptions',
        sources: data.web_sources.authenticated.concat(
          data.web_sources.subscriptions,
        ),
      };
    }
  }
  if (
    data.modes.includes('subscriptions') &&
    (!!subscribedList.length || user_subscriptions.includes('CABLE'))
  ) {
    return { label: 'subscribed', sources: data.web_sources.subscriptions };
  }

  const [mode1, mode2] = data.modes;
  const mode = mode1 === 'free_signup' ? mode2 : mode1;

  let webSources = data.web_sources[mode];
  if (mode === 'subscriptions') {
    webSources = data.web_sources.authenticated.concat(
      data.web_sources.subscriptions,
    );
  }
  return { label: mode, sources: webSources };
};

export const decodeQuery = (params, param) => {
  const [key, value] = param.split('=');
  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
  return params;
};

export const getFilteredSliderItems = (items) => {
  if (!!items.length) {
    return items.filter(
      (item) => !(item.type === 'V' && !ReactPlayer.canPlay(item.video_url)),
    );
  }
  return [];
};

export const getCustomUrlSearchParams = (query) => {
  if (!query) {
    return {};
  }
  const slicedQuery = /^[?#]/.test(query) ? query.slice(1) : query;
  const params = slicedQuery.split('&').reduce(decodeQuery, {});
  return params;
};

export const getFilterGenre = (genre, array) => {
  let newGenre = [];
  if (genre) {
    if (typeof genre === 'string') {
      const filterGenre = array.filter((g) => g.slug === genre);
      if (!!filterGenre.length) newGenre = genre;
    } else {
      genre.forEach((value) => {
        const filterGenre = array.filter((g) => g.slug === value);
        if (!!filterGenre.length) newGenre.push(value);
      });
    }
  }
  return newGenre;
};

export const getFilterRating = (rating, array) => {
  let newRating = [];
  if (rating) {
    if (typeof rating === 'string') {
      const filterRating = array.filter((r) => r.value === rating);
      if (!!filterRating.length) newRating = rating;
    } else {
      rating.forEach((value) => {
        const filterRating = array.filter((r) => r.value === value);
        if (!!filterRating.length) newRating.push(value);
      });
    }
  }
  return newRating;
};

export const getBrowser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge (EdgeHtml)';
    case agent.indexOf('edg') > -1:
      return 'MS Edge Chromium';
    case agent.indexOf('opr') > -1 && !!window.opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'Internet Explorer';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
};

export const getCustomParams = (params, slug, mode, genre) => {
  const customParams = { ...params };
  if (slug) customParams.network = slug;
  if (mode) customParams.mode = mode;
  if (genre) customParams.genre = [genre];
  return customParams;
};

export const getURLFavoriteItem = (item, favoriteType) => {
  let { url } = item;
  if (favoriteType === FAVORITE_TYPE.NETWORKS) {
    url += `?network=${item.slug}&page=1&page_size=23&ordering=`;
  } else if (favoriteType === FAVORITE_TYPE.MOVIEGENRES) {
    url += `&page=1&page_size=23&ordering=`;
  }
  return url;
};

export const checkNetworkGenre = (genre, slug) =>
  (window.location.href.includes('guide/movies/?genre=') && genre) ||
  (window.location.href.includes('/network/') && slug);

export const getItemModel = (item) => {
  if (item.type === 'M') {
    return FAVORITE_TYPE.MOVIES;
  }
  return FAVORITE_TYPE.SHOWS;
};

export const openPopUp = (link, e) => {
  e.preventDefault();
  const popupWidth = window.screen.width * (60 / 100);
  const popupHeight = window.screen.height * (75 / 100);
  const left = (window.screen.width - popupWidth) / 2;
  const top = (window.screen.height - popupHeight) / 2;
  const newWindow = window.open(
    link,
    'name',
    `scrollbars=yes,resizable=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`,
  );
  if (window.focus) newWindow.focus();
  return newWindow;
};

export const getUpgradePlanDialogTextTitle = (
  isConfirmed,
  mainPlans,
  unPlans,
) => {
  if (isConfirmed) return 'Success!';
  if (mainPlans[0].type === 'plan_addon') {
    return mainPlans[0].action === 'upgrade'
      ? 'Upgrade Plan'
      : 'Downgrade Plan';
  }
  if (unPlans && unPlans.length > 0) return 'Cancel AddOns';
  return 'Add AddOns';
};

export const getUpgradePlanDialogDialogContent = (
  isConfirmed,
  isAddOn,
  mainPlans,
  unPlans,
) => {
  if (isConfirmed) {
    return `You have successfully ${
      !isAddOn
        ? mainPlans[0].action === 'upgrade'
          ? 'upgraded'
          : 'downgraded'
        : unPlans && unPlans.length > 0
        ? 'cancelled'
        : 'added'
    } ${!isAddOn ? 'to ' : ' '} ${
      mainPlans && mainPlans.map((plan) => plan.name).toString()
    } ${isAddOn && ' add-on'}`;
  }
  if (isAddOn) {
    return `Please confirm your ${
      unPlans && unPlans.length > 0 ? 'cancellation' : 'upgrade'
    } selection.`;
  }
  return `Are you sure you want to ${
    mainPlans[0].action === 'upgrade' ? 'upgrade' : 'downgrade'
  } to ${mainPlans && mainPlans.map((plan) => plan.name).toString()}?`;
};

export const getUpgradePlanDialogDialogContent2 = (
  mainPlans,
  isAddOn,
  unPlans,
) =>
  `Your new ${
    !mainPlans[0].billing_period || mainPlans[0].billing_period === 'Month'
      ? 'monthly'
      : 'annual'
  } total will be ${
    !isAddOn
      ? ''
      : unPlans && unPlans.length > 0
      ? 'decreased by '
      : 'increased by '
  }`;

export function paramsToObject(entries) {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    // each 'entry' is a [key, value] tupple
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}

export const stateLabel = (state) => {
  if (!state) return '';
  const filters = STATES_AND_PROVINCES.filter((el) => el[0] === state);
  return filters[0][1];
};

export const staticUrl = (path) => STATIC_URL + path;

export const truncateString = (str, noWord) => {
  if (str === undefined || !str) {
    return str;
  }
  return `${str.split(' ').splice(0, noWord).join(' ')}...`;
};

export const truncateByLength = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};

export const getChannelCategory = (item) =>
  item.category_slug || (item.categories?.length > 0 && item.categories[0]);

export const updateURLParameter = (url, param, paramVal) => {
  let newAdditionalURL = '';
  let tempArray = url.split('?');
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = '';
  if (additionalURL) {
    tempArray = additionalURL.split('&');
    for (let i = 0; i < tempArray.length; i += 1) {
      if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = '&';
      }
    }
  }

  const rowsTxt = `${temp}${param}=${paramVal}`;
  return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
};

export function urlQueryToParams(query) {
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  return paramsToObject(entries);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getInterleavedBG = (index, color1, color2) => ({
  backgroundColor: index % 2 === 1 ? color1 : color2,
});

export const getEmpyFilledElement = (start, end, duration, index) => {
  const emptyFilled = {};
  emptyFilled.start_at = start;
  emptyFilled.end_at = end;
  emptyFilled.duration = duration;
  emptyFilled.name = 'Unknown';
  emptyFilled.index = index;
  emptyFilled.uuid = `id_${duration}_Unknown`;
  emptyFilled.description = '';
  emptyFilled.subtitle = '';
  return emptyFilled;
};

export const getTimestamp = (date) =>
  `${moment(date).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
export const addDuration = (date, duration) =>
  `${moment(date)
    .utc()
    .add(moment.duration(duration))
    .format('YYYY-MM-DDTHH:mm:ss')}Z`;
export const subtractDuration = (date, duration) =>
  `${moment(date)
    .utc()
    .subtract(moment.duration(duration))
    .format('YYYY-MM-DDTHH:mm:ss')}Z`;
export const getDuration = (start, end) =>
  moment.duration(moment(end).utc().diff(moment(start))).toISOString();

export const fillEmptySpaces = (data) => {
  const newFilledData = [];
  let diffIndex = 0;

  data.forEach((d, index) => {
    if (index === data.length - 1) {
      d.index += diffIndex;
      newFilledData.push(d);
    } else if (
      index === 0 ||
      !moment(data[index].start_at).isBefore(moment(data[index - 1].end_at))
    ) {
      const c = addDuration(d.end_at, '0:00:00');
      const f = getTimestamp(data[index + 1].start_at);

      if (c === f) {
        newFilledData.push(d);
      } else if (moment(data[index + 1].start_at).isAfter(moment(d.end_at))) {
        d.index += diffIndex;
        diffIndex += 1;
        newFilledData.push(d);
        const startAt = c;
        const endAt = f;
        const duration = getDuration(startAt, endAt);
        const emptyFilled = getEmpyFilledElement(
          startAt,
          endAt,
          duration,
          index + diffIndex,
        );
        newFilledData.push(emptyFilled);
      }
    }
  });

  return newFilledData;
};

export const validateEmail = (mail) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    mail,
  );

export const validate = (value, rules) => {
  const isValid = [];

  // eslint-disable-next-line
  for (const rule in rules) {
    switch (rule) {
      case 'minLength':
        isValid.push(minLengthValidator(value, rules[rule]));
        break;

      case 'maxLength':
        isValid.push(maxLengthValidator(value, rules[rule]));
        break;

      case 'isRequired':
        isValid.push(requiredValidator(value));
        break;

      case 'isEmail':
        isValid.push(emailValidator(value));
        break;

      case 'isCreditCard':
        isValid.push(creditCardNumberValidator(value));
        break;

      case 'isCvv':
        isValid.push(cvvValidator(value));
        break;

      case 'isInteger':
        isValid.push(integerValidator(value));
        break;

      case 'isExpirationDateFormat':
        isValid.push(expirationDateFormatValidator(value));
        break;

      case 'isExpirationDateValue':
        isValid.push(expirationDateValueValidator(value));
        break;

      default:
        isValid.push(true);
    }

    if (isValid.includes(false)) {
      break;
    }
  }

  return isValid;
};

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => value.length >= minLength;

/**
 * maxLength Val
 * @param  value
 * @param  maxLength
 * @return
 */
const maxLengthValidator = (value, maxLength) => value.length <= maxLength;

/**
 * Check to confirm that feild is required
 *
 * @param  value
 * @return
 */
const requiredValidator = (value) => value.trim() !== '';

/**
 * Email validation
 *
 * @param value
 * @return
 */
export const emailValidator = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(String(value).toLowerCase());
};

export const isStrongPassword = (value) => {
  const re = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );
  return re.test(value);
};

/** 4074000200738196
 * Credit Card Nuber Validator
 * @param ccNum
 * @return
 */
const creditCardNumberValidator = (ccNum) => {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  return (
    visaRegEx.test(ccNum) ||
    mastercardRegEx.test(ccNum) ||
    amexpRegEx.test(ccNum) ||
    discovRegEx.test(ccNum)
  );
};

/**
 * Cvv Validator
 * @param cvv
 * @return
 */
const cvvValidator = (cvv) => {
  const cvvRegEx = /^\d{3}$/;
  const cvcRegEx = /^\d{4}$/;
  return cvvRegEx.test(cvv) || cvcRegEx.test(cvv);
};

/**
 * Integer Validator
 * @param number
 * @return
 */
const integerValidator = (number) => {
  const integerRegEx = /^[0-9]+$/;
  return integerRegEx.test(number);
};

/**
 * Expiration Date Format Validator
 * @param string
 * @return
 */
const expirationDateFormatValidator = (expirationDate) => {
  const expirationRegx = /^([0-9]{2})\/([0-9]{4})$/;
  if (expirationRegx.test(expirationDate)) {
    if (expirationDate.split('/')[0] > 0 && expirationDate.split('/')[0] < 13) {
      return true;
    }
  }
  return false;
};

/**
 * Expiration Date value Validator
 * @param string
 * @return
 */
const expirationDateValueValidator = (expirationDate) => {
  const dateMomentObject = moment(expirationDate, 'MM/YYYY').add(30, 'day');
  const dateObject = dateMomentObject.toDate();

  return moment(dateObject).isAfter(new Date());
};

export const validationField = (field) => {
  const validations = validate(field.value, field.validationRules);
  field.invalid = validations.includes(false);
  field.valid = !field.invalid;
  field.messages = [];
  _keys(validations).forEach((key) => {
    if (!validations[key]) {
      field.messages.push(field.validationMessages[key]);
    }
  });
  return field;
};

export const validationRegisterForm = (formData, setFormData, setErrors) => {
  let result = false;
  _keys(formData).forEach((inputIdentifier) => {
    const field = validationField(formData[inputIdentifier]);
    if (!result) result = field.invalid;
  });
  if (formData.age_range.value === 0) {
    const ageRange = { ...formData.age_range };
    ageRange.invalid = true;
    setFormData({ ...formData, age_range: ageRange });
    result = true;
  }
  if (formData.pwd_2.value !== formData.pwd_1.value) {
    setErrors(['Password and Confirm Password are not equals.']);
    result = formData.pwd_2.value !== formData.pwd_1.value;
  }
  return result;
};
