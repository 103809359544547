import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

export const ListHeader = ({ title, ...props }) => {
  const classes = useStyles();

  return (
    <Box {...props} className={classes.titleBox}>
      {title}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
    fontSize: theme.typography.h5.fontSize,
  },
}));
