import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: {},
    upgradeProfile: {},
    cancelProfile: {},
    stopStatus: '',
  },
  reducers: {
    onSetProfile: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onUpdateProfile: (state, action) => {
      const { errors, messages, status, formData } = action.payload;
      let newProfile = {
        ...state.profile,
        errors,
        messages,
        status,
      };

      if (status === 'ok' && formData)
        newProfile = { ...newProfile, ...formData };

      state.profile = newProfile;
    },
  },
});

export default profileSlice.reducer;

const { onSetProfile, onUpdateProfile } = profileSlice.actions;
export const setProfile = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetProfile({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateProfile = (data) => async (dispatch) =>
  dispatch(onUpdateProfile(data));
