import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowEpisodeUrl, getMyIpAddress } from 'apis';

import { v4 as uuidV4 } from 'uuid';
import Cookies from 'js-cookie';
import { PlayerDialog } from './PlayerDialog';

export const EpisodePlayer = ({
  name,
  seasonNumber,
  episodeNumber,
  openPlayerDialog,
  setOpenPlayerDialog,
}) => {
  const dispatch = useDispatch();
  const [
    sotalToken,
    showInfo,
    seasonEpisodes,
    showEpisodeUrl,
    ipAddress,
  ] = useSelector(({ membership, shows, global }) => [
    membership.sotalToken,
    shows.showInfo,
    shows.seasonEpisodes,
    shows.showEpisodeUrl,
    global.ipAddress,
  ]);

  React.useEffect(() => {
    if (!ipAddress) {
      dispatch(getMyIpAddress());
    }
  }, []);

  React.useEffect(() => {
    if (
      !seasonNumber ||
      !episodeNumber ||
      !seasonEpisodes.length ||
      !showInfo?.slug ||
      showInfo?.flags?.status === 'upgrade_required'
    ) {
      return;
    }

    const episode = seasonEpisodes[episodeNumber - 1];

    if (episode?.media?.streams?.length > 0) {
      const stream = episode.media.streams[0];
      dispatch(
        fetchShowEpisodeUrl({
          showSlug: showInfo.slug,
          episodeId: episode.id,
          streamId: stream?.id,
          sotalToken,
        }),
      );
    }
  }, [showInfo, seasonEpisodes, seasonNumber, episodeNumber, sotalToken]);

  const streamUrl = React.useMemo(() => {
    let streamUrl = showEpisodeUrl;
    const episode = seasonEpisodes[episodeNumber - 1];
    if (
      episode?.media?.streams?.length > 0 &&
      episode?.media?.streams[0].type === 'hls' &&
      showEpisodeUrl &&
      ipAddress
    ) {
      let uuid = Cookies.get('uuid');
      if (!uuid) {
        uuid = uuidV4();
        Cookies.set('uuid', uuid);
      }

      streamUrl += streamUrl.includes('?')
        ? '&ads.us_privacy=us_privacy&'
        : '?ads.us_privacy=us_privacy&';
      streamUrl += 'ads.dnt=dnt&';
      streamUrl += `ads.did=${uuid}&`;
      streamUrl += `ip=${ipAddress}&`;
      streamUrl += `ads.site_page=${window.location}`;
    }

    return streamUrl;
  }, [seasonEpisodes, episodeNumber, showEpisodeUrl, ipAddress]);

  return (
    <PlayerDialog
      maxWidth="md"
      title={name}
      type="show"
      open={openPlayerDialog}
      url={streamUrl}
      onClose={() => setOpenPlayerDialog(false)}
    />
  );
};
