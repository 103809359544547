import axios from 'axios';
import * as global from 'global';
import { setGenres } from '../redux/reducers/genres';
import { setMusic } from '../redux/reducers/music';

export const fetchRadioStationDetail = (radioStationSlug) => (dispatch) =>
  axios
    .get(global.RADIO.RADIO_STATION_INFO.replace('{0}', radioStationSlug))
    .then((response) =>
      dispatch(setMusic({ field: 'stationInfo', value: response.data })),
    )
    .catch(() => dispatch(setMusic({ field: 'stationInfo', value: '404' })));

export const fetchRadioStationList = (genre) => (dispatch) =>
  new Promise((resolve) => {
    axios
      .get(global.RADIO.RADIO_STATIONS.replace('{0}', genre))
      .then((response) =>
        dispatch(setMusic({ field: 'stations', value: response.data.results })),
      );
    resolve();
  });

export const fetchRadioGenreList = () => (dispatch) =>
  axios
    .get(global.RADIO.RADIO_GENRES)
    .then((response) =>
      dispatch(setGenres({ field: 'radio', value: response.data })),
    );

export const getRadioStations = ({ params }) => {
  const { search = '', page = 1 } = params;

  return async (dispatch) => {
    dispatch(setMusic({ field: 'radioStations', value: [] }));

    await axios
      .get(`${global.SEARCH.BY_TYPE}radiostations/${search}&page=${page}`)
      .then((response) => {
        dispatch(
          setMusic({ field: 'radioStations', value: response.data.results }),
        );
      })
      .catch(() => {});
  };
};
