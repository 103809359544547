import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sourceContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  sourceItem: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  sourceImg: {
    maxWidth: '100%',
  },
}));

export const AccessModeSource = ({ width, sources, url }) => {
  const classes = useStyles();
  return (
    <Box className={classes.sourceContainer} style={{ maxWidth: width }}>
      {sources.map((s, index) => {
        const key = `sourceItem-${index}`;
        return (
          <Link
            key={key}
            to={url}
            className={classes.sourceItem}
            component={RouterLink}
          >
            <img src={s.image} alt={`source`} className={classes.sourceImg} />
          </Link>
        );
      })}
    </Box>
  );
};
