import axios from 'axios';
import { INTERNATIONAL } from 'global';
import { setMusic } from '../redux/reducers/music';
import { setGlobal } from '../redux/reducers/global';

export const fetchCountryList = () => (dispatch) => {
  axios
    .get(INTERNATIONAL.COUNTRY_LIST)
    .then((response) =>
      dispatch(
        setGlobal({ field: 'countryList', value: response.data.results }),
      ),
    );
};

export const fetchCountryStationList = (countrySlug) => {
  let apiURL = INTERNATIONAL.COUNTRY_STATION_LIST.replace('{0}', countrySlug);
  if (countrySlug === 'united-states') {
    apiURL = INTERNATIONAL.USA_STATION_LIST.replace('{0}', countrySlug);
  }

  return (dispatch) =>
    axios
      .get(apiURL)
      .then((response) =>
        dispatch(setMusic({ field: 'stationListData', value: response.data })),
      );
};
