import React from 'react';
import is from 'is_js';
import { Link as RouterLink } from 'react-router-dom';
import { COLOR, addModeToUrl } from 'global';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VirtualSlider from '../VirtualSlider';
import useSwiper from '../VirtualSlider/hooks/useSwiper';

export const CustomCarousel = React.memo(
  ({ carouselData, mode }) => {
    const classes = useStyles({ carouselData, mode });
    const { setSwiper, onNext, onPrevious } = useSwiper({
      carouselData,
    });

    return (
      <Box width={1} mb={3}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Box dangerouslySetInnerHTML={{ __html: carouselData.title }} />
            {carouselData?.view_more_link && (
              <Link
                component={RouterLink}
                className={classes.titleLink}
                to={addModeToUrl(carouselData.view_more_link, mode)}
              >
                <span>View More</span> <ChevronRightIcon />
              </Link>
            )}
          </Box>
          <Box className={classes.arrows}>
            <Box className={classes.arrowButton} onClick={onPrevious}>
              <ChevronLeftIcon fontSize={'large'} />
            </Box>
            <Box className={classes.arrowButton} onClick={onNext}>
              <ChevronRightIcon fontSize={'large'} />
            </Box>
          </Box>
        </Box>
        {!!carouselData?.items?.length && (
          <VirtualSlider {...{ carouselData, mode, setSwiper }} />
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.carouselData === nextProps.carouselData &&
    prevProps.mode === nextProps.mode,
);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: COLOR.b_white_light,
    paddingBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    color: 'white',
    '& *': { display: 'inline-block' },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  titleLink: {
    color: 'white',
    textDecoration: 'none !important',
    fontSize: theme.typography.body1.fontSize,
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
    '&:hover': { color: theme.palette.primary.main },
    '& span, & svg': { verticalAlign: 'top' },
  },
  arrows: {
    position: 'absolute',
    right: 0,
    display: (props) =>
      props.carouselData.items.length <= 3 ? 'none' : 'inherit',
    '@media screen and (max-width: 1020px)': {
      display: (props) => props.carouselData.items.length === 3 && 'inherit',
    },
    '@media screen and (max-width: 800px)': {
      display: (props) => props.carouselData.items.length === 2 && 'inherit',
    },
  },
  arrowButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sliderItem: {
    paddingRight: theme.spacing(1),
  },
  itemContainer: {
    display: 'flex',
    position: 'relative',
  },
  showsItem: {
    width: '210px',
    height: is.safari() ? '56.25%' : 'auto',
  },
  movieItem: {
    width: '148px',
    height: is.safari() ? '142.5%' : 'auto',
  },
  otherItem: {
    height: theme.spacing(18),
    width: theme.spacing(18),
  },
}));
