import React from 'react';
import { Box, Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#2c2c2c',
    maxWidth: '960px',
    margin: '0 auto',
  },
  cardHeader: {
    borderBottom: 'solid 1px gray',
    padding: '10px 16px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    '& .MuiCardHeader-title': {
      color: 'white',
      fontSize: '1rem',
    },
  },
  embedResponsive: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    '& iframe, & object, & video, & embed, & .embed-responsive-item': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
    },
  },
}));

export const EmbeddedPage = ({ title, content, backUrl, linkText }) => {
  const classes = useStyles();

  return (
    <Box m={3}>
      <Box>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={title} />
          <CardContent className={''}>
            <Box
              className={classes.embedResponsive}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </CardContent>
        </Card>
      </Box>

      <Box justifyContent="center" display="flex" mt={3}>
        <Button
          variant="contained"
          component={Link}
          to={backUrl}
          color="primary"
          style={{ color: 'white', fontWeight: 'bold' }}
          size="large"
          startIcon={<ArrowBackIcon />}
        >
          {linkText}
        </Button>
      </Box>
    </Box>
  );
};
