import _keys from 'lodash/keys';
import is from 'is_js';
import { createSlice } from '@reduxjs/toolkit';
import { MODE, PAGE_FILTER } from 'global';

const slice = createSlice({
  name: 'pageData',
  initialState: {
    data: {},
    enableFilter: false,
    accordions: [],
    accordionsChildren: [],
    rightSideMenus: [],
    isAdsFlagRequired: true,
    currentSlug: '',
    slugUrl: '',
    mode: MODE.FREE,
    showsCount: -1,
    moviesCount: -1,
    pageParams: null,
    hideToggle: false,
    relatedItems: [],
    listData: [],
    filterData: PAGE_FILTER.FILTER_DATA,
  },
  reducers: {
    onSetPageData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onSetPage: (state, action) => {
      const { data } = action.payload;

      if (is.not.empty(data)) {
        _keys(data).forEach((key) => (state[key] = data[key]));
      }
    },
  },
});

export default slice.reducer;

const { onSetPageData, onSetPage } = slice.actions;
export const setPageData = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetPageData({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
export const setPage = (data) => async (dispatch) =>
  dispatch(onSetPage({ data }));
