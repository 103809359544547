import React from 'react';
import { makeStyles, useTheme, Box } from '@material-ui/core';
import { XButton } from 'components';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '160px',
    margin: '0 auto 16px !important',
  },
}));

export const SubmitButton = ({
  label = '',
  radius = '10px',
  lineHeight = '22px',
  width = 1,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box width={width} className={classes.root}>
      <XButton
        label={label}
        bkcolor={theme.palette.primary.main}
        hbkcolor={theme.palette.primary.dark}
        textcolor="white"
        type="submit"
        width={1}
        radius={radius}
        onClick={onClick}
        transform={'uppercase'}
        labelAtributes={{
          fontSize: 'subtitle2.fontSize',
          fontWeight: 'fontWeightRegular',
          lineHeight,
        }}
      />
    </Box>
  );
};
