import React from 'react';
import _keys from 'lodash/keys';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import { LoadingSpinner } from 'components';
import { COLOR, validate } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(25),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      [theme.breakpoints.up('md')]: {
        maxWidth: '60%',
      },
    },
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  menuIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: 'white',
  },
  dialogClose: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    color: 'white',
    cursor: 'pointer',
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(8),
  },
  textfield: {
    width: '100%',
    margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    '& .MuiInput-underline': {
      color: 'white',
      '&:before': {
        borderBottomColor: 'white',
      },
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
    background: '#477410',
  },
  signUpBox: {
    marginLeft: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  name: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    textAlign: 'center',
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'white',
  },
  errorIconBox: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    background: 'red',
    color: 'white',
    marginRight: theme.spacing(3),
  },
}));

export const PaymentDialog = ({
  plans,
  unPlans,
  openModal,
  setOpenModal,
  token,
  onSubmitedPayment,
  updateMembership,
  submitPaymentInfo,
  isSotal,
}) => {
  const classes = useStyles();

  const fields = {
    first_name: {
      invalid: false,
      value: '',
      valid: false,
      validationRules: {
        isRequired: true,
      },
      validationMessages: ['First Name is required!'],
    },
    last_name: {
      invalid: false,
      value: '',
      valid: false,
      validationRules: {
        isRequired: true,
      },
      validationMessages: ['Last name is required!'],
    },
    number: {
      value: '',
      valid: false,
      validationRules: {
        isCreditCard: true,
        isRequired: true,
      },
      validationMessages: [
        'Card Number format is incorrect!',
        'Card Number is required!',
      ],
      invalid: false,
    },
    cvv: {
      value: '',
      valid: false,
      validationRules: {
        isCvv: true,
        isRequired: true,
      },
      validationMessages: [
        'CVC/CVV format is incorrect!',
        'CVC/CVV is required!',
      ],
      invalid: false,
    },
    expiration_date: {
      invalid: false,
      value: '',
      valid: false,
      validationRules: {
        isExpirationDateFormat: true,
        isExpirationDateValue: true,
        isRequired: true,
      },
      validationMessages: [
        'Expiration Date format is incorrect!',
        'Expiration Date should be after current date!',
        'Expiration Date is required!',
      ],
    },
    billing_zip: {
      value: '',
      valid: false,
      validationRules: {
        isInteger: true,
        isRequired: true,
      },
      validationMessages: [
        'Postal Code format is incorrect!',
        'Postal Code is required!',
      ],
      invalid: false,
    },
  };

  const [formData, setFormData] = React.useState(fields);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (openModal) {
      setIsLoading(false);
      setError(false);
      setFormData(fields);
    }
  }, [openModal]);

  const validation = () => {
    let result = false;
    _keys(formData).forEach((inputIdentifier) => {
      const field = validationField(formData[inputIdentifier]);
      if (!result) {
        result = field.invalid;
      }
    });
    return result;
  };

  const validationField = (field) => {
    const validations = validate(field.value, field.validationRules);
    field.invalid = validations.includes(false);
    field.valid = !field.invalid;
    field.messages = [];
    _keys(validations).forEach((key) => {
      if (!validations[key]) {
        field.messages.push(field.validationMessages[key]);
      }
    });
    return field;
  };

  const changeValue = (value, key) => {
    const updatedFormElement = {
      ...formData[key],
      value,
    };

    setFormData({
      ...formData,
      [key]: validationField(updatedFormElement),
    });
  };

  const sendForm = (e) => {
    e.preventDefault();

    // validation
    if (!validation()) {
      const data = {};
      _keys(formData).forEach((formElementId) => {
        if (formData[formElementId].valid) {
          if (formElementId === 'expiration_date') {
            // eslint-disable-next-line camelcase
            const [expiry_month, expiry_year] = formData[
              formElementId
            ].value.split('/');
            Object.assign(data, { expiry_month, expiry_year });
          } else {
            data[formElementId] = formData[formElementId].value;
          }
        }
      });

      // call submit after validate data
      setIsLoading(true);
      setError(false);

      submitPaymentInfo(token, data)
        .then((paymentResponse) => {
          if (paymentResponse.status.toUpperCase() === 'OK') {
            updateMembership(
              token,
              isSotal
                ? { sotalcloud_package_slug: plans[0].slug }
                : { package_slug: plans[0].slug },
              // plans && plans.map((p) => p.code || p.slug),
              // unPlans && unPlans.map((p) => p.code || p.slug),
            )
              .then((response) => {
                setIsLoading(false);
                if (response.status === 'success') {
                  onSubmitedPayment(paymentResponse);
                } else {
                  setError(true);
                }
              })
              .catch((_) => {
                setIsLoading(false);
                setError(true);
              });
          } else {
            setIsLoading(false);
            setError(true);
          }
        })
        .catch((_) => {
          setIsLoading(false);
          setError(true);
        });
    }
  };

  const getBillingSum = () => {
    let sum = 0;
    if (Array.isArray(plans)) {
      const isMonthly =
        !plans[0].billing_period || plans[0].billing_period === 'Month';
      plans.forEach((planItem) => {
        sum += isMonthly ? planItem.price : planItem.annual_price;
      });
    } else {
      sum += plans.price;
    }

    return sum;
  };

  return (
    <Dialog
      className={classes.modal}
      classes={{ paper: classes.dialog }}
      open={openModal}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>
        <Box>
          <Box className={classes.closeBox}>
            <IconButton
              className={classes.dialogClose}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Box component={ClearIcon} className={classes.menuIcon} />
            </IconButton>
          </Box>
          <Box className={classes.name}>Upgrade Plan</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          <form onSubmit={(e) => sendForm(e)}>
            {error && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="red"
                mb={1}
              >
                <Box
                  component={PriorityHighIcon}
                  className={classes.errorIconBox}
                />
                Payment Could Not Be Processed
              </Box>
            )}
            <Box mt={1} display="flex">
              <TextField
                error={formData.first_name.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="First Name"
                helperText={
                  formData.first_name.messages &&
                  formData.first_name.messages[0]
                }
                name="first_name"
                id="first_name"
                value={formData.first_name.value}
                onChange={(e) => changeValue(e.target.value, 'first_name')}
              />
              <TextField
                error={formData.last_name.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="Last Name"
                helperText={
                  formData.last_name.messages && formData.last_name.messages[0]
                }
                name="last_name"
                id="last_name"
                value={formData.last_name.value}
                onChange={(e) => changeValue(e.target.value, 'last_name')}
              />
            </Box>
            <Box mt={1} display="flex">
              <TextField
                error={formData.number.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="Card Number"
                helperText={
                  formData.number.messages && formData.number.messages[0]
                }
                name="number"
                id="number"
                value={formData.number.value}
                onChange={(e) => changeValue(e.target.value, 'number')}
              />
            </Box>
            <Box mt={1} display="flex" justifyContent="center">
              <TextField
                error={formData.cvv.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="CVC / CVV"
                helperText={formData.cvv.messages && formData.cvv.messages[0]}
                name="cvv"
                id="cvv"
                value={formData.cvv.value}
                onChange={(e) => changeValue(e.target.value, 'cvv')}
              />
              <TextField
                error={formData.expiration_date.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="MM / YYYY"
                helperText={
                  formData.expiration_date.messages &&
                  formData.expiration_date.messages[0]
                }
                name="expiration_date"
                id="expiration_date"
                value={formData.expiration_date.value}
                onChange={(e) => changeValue(e.target.value, 'expiration_date')}
              />
            </Box>
            <Box mt={1} display="flex">
              <TextField
                error={formData.billing_zip.invalid}
                type="text"
                size="small"
                className={classes.textfield}
                placeholder="Postal Code"
                helperText={
                  formData.billing_zip.messages &&
                  formData.billing_zip.messages[0]
                }
                name="billing_zip"
                id="billing_zip"
                value={formData.billing_zip.value}
                onChange={(e) => changeValue(e.target.value, 'billing_zip')}
              />
              <TextField className={classes.textfield} style={{ opacity: 0 }} />
            </Box>
            <Box className={classes.description} mt={1}>
              Your Total Today:{' '}
              <span
                className={classes.price}
                style={{ color: window.BRAND.primary_color }}
              >
                ${plans && getBillingSum()}
              </span>
            </Box>
            {isLoading && (
              <Box
                width={1}
                justifyContent="center"
                alignItems="center"
                height="10vh"
                position="relative"
              >
                <LoadingSpinner />
              </Box>
            )}
            {!isLoading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  className={classes.loginButton}
                  component={Button}
                  type="submit"
                  variant="contained"
                  color="white !important"
                >
                  Confirm
                </Box>
              </Box>
            )}
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
