import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from 'components';
import { Box, Typography } from '@material-ui/core';
import { staticUrl, openPopUp, WEBLINK } from 'global';
import { makeStyles } from '@material-ui/core/styles';
import { fetchLinksForEpisode } from 'apis';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  price: {
    color: 'gray',
    textDecoration: 'none',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.subtitle2.fontSize,
  },
  label: {
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    color: 'white',
  },
  labelGreen: { backgroundColor: '#477410' },
  labelOrange: { backgroundColor: '#d46105' },
  linkItem: {
    textDecoration: 'none !important',
    marginBottom: theme.spacing(2),
  },
  loadingContainer: {
    position: 'relative',
    minHeight: '60px',
  },
  sourceContainer: {
    paddingTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  linkBoxItem: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sourceImage: {
    maxWidth: theme.spacing(11),
    maxHeight: theme.spacing(11),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
}));

export const EpisodeLinkContainer = ({
  showSlug,
  episode,
  seasonNumber,
  type,
}) => {
  const dispatch = useDispatch();
  const [token, linksObject] = useSelector(({ auth, shows }) => [
    auth.jwt_token,
    shows.linksPerEpisodes[episode.number],
  ]);
  const [links, setLinks] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(
      fetchLinksForEpisode(showSlug, seasonNumber, episode.number, token),
    );
  }, [showSlug, episode, seasonNumber]);

  React.useEffect(() => {
    if (linksObject && linksObject.sources) {
      if (type === 'premium') {
        setLinks([
          ...linksObject.sources.authenticated,
          ...linksObject.sources.paid,
        ]);
      } else if (type === 'subscribed') {
        setLinks(linksObject.sources.subscription);
      } else {
        setLinks(linksObject.sources.free);
      }
    }
  }, [linksObject]);

  const onLinkClickLink = (link, e) => {
    if (link.display_mode === 'P') openPopUp(WEBLINK.PATH + link.id, e);
  };

  const renderLink = (link, mode) => {
    let linkUrl = '#';
    if (link.display_mode !== 'P') linkUrl = `watch/${link.id}/`;
    return (
      <Link
        key={link.id}
        to={linkUrl}
        onClick={(e) => onLinkClickLink(link, e)}
        className={classes.linkItem}
      >
        <Box
          className={classes.linkBoxItem}
          style={{ verticalAlign: 'top', userSelect: 'none' }}
        >
          <Box className={classes.sourceImage}>
            <img
              src={
                link.source.image ||
                staticUrl('images/websource/freecast-websource.png')
              }
              alt={link.source.name}
              width="100%"
            />
          </Box>
          {mode === 'free' && (
            <Box className={clsx(classes.label, classes.labelGreen)}>FREE</Box>
          )}
          {mode === 'subscribed' && (
            <Box className={clsx(classes.label, classes.labelOrange)}>
              SUBSCRIBED
            </Box>
          )}
          {mode === 'premium' && link.formats && (
            <Box className={classes.price}>
              {link.formats[0].format === 'SD' ? '$' : link.formats[0].format}{' '}
              {link.formats[0].price}
            </Box>
          )}
        </Box>
      </Link>
    );
  };

  return (
    <Box>
      <Box>
        <Typography align="center">Available to watch on:</Typography>
      </Box>
      {!links ? (
        <Box className={classes.loadingContainer}>
          {' '}
          <LoadingSpinner />{' '}
        </Box>
      ) : (
        <Box
          className={classes.sourceContainer}
          justifyContent="center"
          display="flex"
          mt={2}
        >
          {links?.length > 0 ? (
            links.map((link) => renderLink(link, type))
          ) : (
            <Box>
              There are currently no viewing options for this episode. Please
              check back frequently for updates
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
