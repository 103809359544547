import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
  videoContainer: {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

export const YoutubePlayer = React.memo(
  ({
    url,
    videoId,
    isLoop,
    isPlaying = true,
    onDemand = false,
    duration,
    offset = 0,
    onEnded,
    onPause,
  }) => {
    const classes = useStyles();
    const currentSec = React.useRef(0);

    return (
      <Box className={classes.videoContainer}>
        <ReactPlayer
          width="100%"
          height="100%"
          loop={isLoop}
          config={{
            youtube: {
              preload: true,
              playerVars: {
                autoplay: isPlaying,
                rel: 0,
                disablekb: onDemand ? 0 : 1,
                controls: onDemand ? 1 : 0,
                showinfo: onDemand ? 1 : 0,
              },
            },
          }}
          onPause={() => {
            onPause(currentSec.current);
          }}
          onProgress={(info) => {
            currentSec.current = duration * info.played;
          }}
          onEnded={onEnded}
          url={url || `https://www.youtube.com/watch?v=${videoId}?t=${offset}`}
          playing={isPlaying}
        />
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.videoId === nextProps.videoId &&
    prevProps.url === nextProps.url &&
    prevProps.isLoop === nextProps.isLoop &&
    prevProps.isPlaying === nextProps.isPlaying &&
    prevProps.onDemand === nextProps.onDemand,
);
