import React from 'react';
import { Link } from '@material-ui/core';
import { openPopUp } from 'global';

export const LeftMenuLink = ({
  children,
  display_mode: displayMode,
  ...props
}) =>
  displayMode === 'T' ? (
    <Link {...props} target="_blank">
      {children}
    </Link>
  ) : displayMode === 'W' ? (
    <Link
      {...props}
      onClick={(e) => {
        openPopUp(props.to, e);
      }}
    >
      {children}
    </Link>
  ) : (
    <Link {...props}>{children}</Link>
  );
