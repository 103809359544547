import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box, useTheme, TextField, Link, makeStyles } from '@material-ui/core';
import { XButton } from 'components';
import { loginShadow } from 'apis';
import { BaseLogin } from './Base';

export const AltLogin = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loginStatus, loginData] = useSelector((state) => [
    state.auth.loginStatus,
    state.auth.loginData,
  ]);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [loginInfo, setLoginInfo] = React.useState({
    username: '',
    password: '',
    isError: '',
    errorMessage: [],
  });
  const [validation, setValidation] = React.useState({
    nameError: false,
    passwordError: false,
  });

  const isValid = () => {
    const result = loginInfo.username.length > 0;
    setValidation({ nameError: loginInfo.username.length === 0 });
    return result;
  };

  const changeValue = (e) => {
    e.preventDefault();
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (isValid() && !loading) {
      setLoading(true);
      setError(false);
      dispatch(loginShadow(loginInfo.username));
    }
  };

  React.useEffect(() => {
    if (loginStatus === 'failure') {
      setLoading(false);
      setError(true);
      setErrorMessage(loginData.detail);
    }
  }, [loginStatus]);

  return (
    <BaseLogin>
      <Box
        className={classes.textHelp}
        textAlign="right"
        fontSize="body1.fontSize"
        color="#333333"
      >
        Need help?{' '}
        <Link component={'a'} href={window.BRAND.support_url}>
          Click Here for Live Support
        </Link>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Box color="gray" fontSize={'h4.fontSize'}>
          Sign In
        </Box>
      </Box>
      {error && (
        <Box marginBottom={1}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      <Box>
        <form>
          <Box textAlign={'left'}>
            <Box fontSize={'body1.fontSize'} color="gray" mb={1}>
              Username or Email
            </Box>
            <TextField
              error={validation.nameError}
              type="text"
              variant="outlined"
              size="small"
              className={classes.textfield}
              placeholder="Username or Email"
              helperText={
                validation.nameError ? 'This field should be filled out.' : ''
              }
              name={'username'}
              value={loginInfo.username}
              onChange={(e) => changeValue(e)}
            />
          </Box>
          <Box className="form-group" mt={2}>
            <XButton
              label={loading ? 'signing in...' : 'Sign In'}
              bkcolor={theme.palette.primary.main}
              textcolor="white"
              variant="contained"
              onClick={sendForm}
              width={1}
            />
          </Box>
          <Box
            pt={3}
            pb={1}
            display={'flex'}
            justifyContent={'center'}
            fontSize="body1.fontSize"
            color="#333333"
          >
            Don't have an {window.BRAND.site_name} account?&nbsp;
            <Link component={RouterLink} to={'/signup'}>
              Sign Up now!
            </Link>
          </Box>
        </form>
      </Box>
    </BaseLogin>
  );
};

const useStyles = makeStyles((theme) => ({
  textHelp: {
    position: 'absolute',
    right: 32,
    width: 170,
    top: 46,
  },
  textfield: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      fontSize: '14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
        borderColor: theme.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        boxShadow: `0px 0px 5px 1px ${theme.palette.text.secondary}`,
        borderColor: theme.palette.text.secondary,
      },
    },
  },
}));
