import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  CardMedia,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { staticUrl } from 'global';
import { PlayerRedemption } from 'components';

export const SuccessView = ({ email, success }) => {
  const classes = useStyles();
  const [showVideo, setShowVideo] = React.useState(success);

  return (
    <Box className={classes.containter}>
      <CardMedia className={classes.mediaLogo} image={window.BRAND.logo} />
      {showVideo ? (
        <Box className={classes.player}>
          <PlayerRedemption onComplete={() => setShowVideo(false)} />
          <Box pt={1} textAlign="center">
            <Box
              className={classes.textPlayer}
              component="span"
              lineHeight="45px"
              mr={2}
            >
              Please wait while we create your account
            </Box>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h3" align="center">
              Please Confirm Your Email Address
            </Typography>
            <Box mt={1}>
              Click the link in the Confirmation Email sent to the below email
              address to continue with the setup of your
              {window.BRAND.site_name} account.
            </Box>
            <Box mt={1}>
              Please allow up to 5 minutes to receive the email. If you have not
              received the email in your inbox, please{' '}
              <strong>check your spam folder</strong>. If you still do not
              receive an email, please contact
              <Link href={window.BRAND.support_url}> User Support </Link>
              for assistance.
            </Box>
            <Box fontWeight="bold" textAlign="center" my={2}>
              Please confirm your email address with the Confirmation Email sent
              to
            </Box>
            <Box textAlign="center">
              <Typography variant="h6" color="primary">
                {email}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  containter: {
    backgroundImage: `url('${staticUrl('members/login/img/login-bg.jpg')}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100vh',
    paddingTop: theme.spacing(10),
  },
  card: {
    maxWidth: 780,
    margin: `${theme.spacing(10)}px auto`,
    padding: theme.spacing(5),
  },
  mediaLogo: {
    height: theme.spacing(12),
    marginBottom: theme.spacing(4),
    backgroundSize: 'contain',
  },
  player: {
    maxWidth: 780,
    margin: '0 auto',
  },
  textPlayer: {
    textAlign: 'center',
    verticalAlign: 'text-bottom',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
}));
