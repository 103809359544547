import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { JWTPlayer, useInterval } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  videoPlayerContainer: {
    width: '100%',
    height: '100%',
  },
  error: {
    color: '#fff',
    textAlign: 'center',
    margin: '20px 0px',
    fontSize: 24,
  },
}));

export const VodPlayer = ({
  vodName,
  vodType = 'movies',
  autoPlay = true,
  url,
  ...props
}) => {
  const classes = useStyles();
  const [displayMessage, setDisplayMessage] = React.useState(false);

  const resumePoint = React.useMemo(
    () => JSON.parse(sessionStorage.getItem('vod_resume_point')),
    // eslint-disable-next-line
    [sessionStorage.getItem('vod_resume_point')],
  );

  const startPosition = React.useMemo(
    () =>
      resumePoint?.vodName === vodName ? resumePoint?.currentTime || 0 : 0,
    [resumePoint, vodName],
  );

  const onUpdateCurrentTime = React.useCallback(() => {
    const currentTime = window.jwplayer()?.getPosition() || 0;
    const hasStarted =
      parseFloat(currentTime) > parseFloat(resumePoint?.currentTime);

    if (
      currentTime === 0 ||
      (!hasStarted && resumePoint?.vodName === vodName)
    ) {
      return;
    }

    sessionStorage.setItem(
      'vod_resume_point',
      JSON.stringify({
        vodName,
        vodType,
        currentTime,
      }),
    );
  }, [resumePoint, window.jwplayer]);

  useInterval(onUpdateCurrentTime, 5000);
  useInterval(() => !url?.length && setDisplayMessage(true), 20000);

  if (!url?.length && displayMessage) {
    return (
      <h5>
        {`This ${vodType === 'movies' ? 'movie' : 'show'} is not available`}
      </h5>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.videoPlayerContainer}>
        <JWTPlayer
          url={url}
          isPlaying={autoPlay}
          controls
          isLoop={false}
          isVod
          startPosition={startPosition}
        />
      </Box>
    </Box>
  );
};
