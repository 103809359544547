import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'shows',
  initialState: {
    sourceList: [],
    showInfo: [],
    linksPerEpisodes: {},
    seasonEpisodes: [],
    allSources: {},
    showEpisodeUrl: undefined,
  },
  reducers: {
    onSetShows: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onSetLinks: (state, action) => {
      state.linksPerEpisodes = action.payload
        ? {
            ...state.linksPerEpisodes,
            [action.payload.slug]: action.payload.links,
          }
        : {};
    },
  },
});

export default slice.reducer;

const { onSetShows, onSetLinks } = slice.actions;
export const setShows = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetShows({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
export const setLinks = ({ slug, links }) => async (dispatch) => {
  try {
    dispatch(onSetLinks({ slug, links }));
  } catch (e) {
    return console.error(e.message);
  }
};
