import React from 'react';
import { Box } from '@material-ui/core';
import { CustomCarousel } from './CustomCarousel';
import withInfiniteScroll from '../InfiniteScroll/hocs/withInfiniteScroll';

export const CustomCarousels = ({ carousels, mode }) => {
  const Carousels = ({ nextItems = [] }) =>
    React.useMemo(
      () =>
        nextItems
          ? nextItems.map((carousel) => (
            <Box key={carousel.id} paddingTop={2}>
              <CustomCarousel carouselData={carousel} mode={mode} />
            </Box>
            ))
          : null,
      [nextItems],
    );

  const CarouselsWithInfiniteScroll = withInfiniteScroll({
    items: carousels,
  })(Carousels);

  return <CarouselsWithInfiniteScroll />;
};
