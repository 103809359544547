import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { COLOR } from 'global';

const useStyles = makeStyles({
  root: {
    padding: '15px',
    lineHeight: '30px',
    fontSize: '18px',
    fontWeight: '700',
    backgroundColor: COLOR.b_black_mlight,
    color: COLOR.t_white_light,
  },
});

export const XCardHeader = ({ title, className }) => {
  const classes = useStyles();
  return (
    <CardHeader
      title={title}
      className={clsx(classes.root, className)}
      disableTypography
    />
  );
};
