import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { withResizeDetector } from 'react-resize-detector';

import { chunkArray, COLOR, WIDTH_BREAK_POINT } from 'global';
import { CastItem } from './CastItem';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  header: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: 'white',
    paddingBottom: theme.spacing(1),
    width: '100%',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  arrows: {
    display: 'flex',
    position: 'absolute',
    right: 0,
  },
  arrowButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CastContainer = React.memo(
  ({ actorList, ...props }) => {
    const classes = useStyles();

    const [currentPageNumber, setCurrentPage] = React.useState(0);
    const slider = React.useRef();

    const chunkCol2 = chunkArray(actorList, 2);
    const chunkCol3 = chunkArray(actorList, 3);
    const chunkRow2 = chunkArray(chunkCol2, 4);
    const chunkRow3 = chunkArray(chunkCol3, 4);

    const cSettings = {
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
    };

    const onPrevSlider = () => {
      slider.current.slickPrev();
    };

    const onNextSlider = () => {
      slider.current.slickNext();
    };

    const onAfterChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    return (
      <Box className={classes.container} mt={3}>
        <Box className={classes.header}>
          <Box className={classes.name}>CAST</Box>
          {((props.width > WIDTH_BREAK_POINT && chunkRow3.length > 1) ||
            (props.width <= WIDTH_BREAK_POINT && chunkRow2.length > 1)) && (
            <Box className={classes.arrows}>
              <Box
                className={classes.arrowButton}
                onClick={onPrevSlider}
                color={currentPageNumber > 0 ? 'white' : COLOR.b_black_mlight}
              >
                <ChevronLeftIcon fontSize={'large'} />
              </Box>
              <Box
                className={classes.arrowButton}
                onClick={onNextSlider}
                color={
                  currentPageNumber <
                  (props.width > WIDTH_BREAK_POINT
                    ? chunkRow3.length - 1
                    : chunkRow2.length - 1)
                    ? 'white'
                    : COLOR.b_black_mlight
                }
              >
                <ChevronRightIcon fontSize={'large'} />
              </Box>
            </Box>
          )}
        </Box>
        {props.width > WIDTH_BREAK_POINT ? (
          <Slider {...cSettings} ref={slider} afterChange={onAfterChange}>
            {chunkRow3.mapWithKey((item) => (
              <CastItem key={item.key} itemList={item} xsSize={4} />
            ))}
          </Slider>
        ) : (
          <Slider {...cSettings} ref={slider} afterChange={onAfterChange}>
            {chunkRow2.mapWithKey((item) => (
              <CastItem key={item.key} itemList={item} xsSize={6} />
            ))}
          </Slider>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.actorList === nextProps.actorList &&
    prevProps.width === nextProps.width,
);

export const Cast = withResizeDetector(CastContainer, {
  refreshMode: 'throttle',
  refreshRate: 500,
});
