import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    alignItems: 'center',
    borderBottomColor: '#7e7e7e',
  },
  linkItem: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.primary.main,
    opacity: 1,
    textDecoration: 'unset',
    transition: 'opacity 500ms',
    '&:hover': {
      opacity: 0.6,
    },
  },
  linkItemBorderBottom: {
    borderBottom: `1px solid #7e7e7e`,
  },
  linkItemBorderRight: {
    borderRight: `1px solid #7e7e7e`,
  },
}));

export const CastItem = ({ itemList: { key, ...itemList }, xsSize }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {Object.keys(itemList).length > 0 &&
        itemList.mapWithKey((item, index) => {
          const itemKey = `castItem-${index}`;
          return (
            typeof item !== 'function' && (
              <Grid container key={itemKey}>
                {item.map((actor, k) => {
                  const subItemKey = `castSubItem-${k}`;
                  return (
                    <Grid
                      key={subItemKey}
                      item
                      xs={xsSize}
                      className={clsx(classes.rowBox, {
                        [classes.linkItemBorderBottom]:
                          parseInt(index) < Object.keys(itemList).length - 2,
                        [classes.linkItemBorderRight]: k < 12 / xsSize - 1,
                      })}
                    >
                      {actor.actor?.slug ? (
                        <Link
                          component={RouterLink}
                          to={`/guide/actor/info/${actor.actor.slug}/`}
                          underline="none"
                          className={classes.linkItem}
                        >
                          <Box textAlign="center">{actor.actor.name}</Box>
                          {actor.character_name && (
                            <Box textAlign="center">
                              ({actor.character_name})
                            </Box>
                          )}
                        </Link>
                      ) : (
                        <Box className={classes.linkItem}>{actor.actor}</Box>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            )
          );
        })}
    </Box>
  );
};
