import React from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { LoadingSpinner } from 'components';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    paddingTop: 0,
  },
  menuIcon: {
    width: '100%',
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(25),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      borderRadius: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '60%',
      },
    },
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  dialogClose: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  name: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    textAlign: 'center',
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'white',
  },
  price: {
    fontSize: '1.4rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: window.BRAND.primary_color,
  },
  buttonBox: {
    borderRadius: theme.spacing(0.5),
    color: 'white',
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    fontSize: theme.typography.h6.fontSize,
    fontFamily: 'century-gothic, sans-serif',
  },
}));

export const UpgradePlanDialog = ({
  plans,
  unPlans,
  token,
  openModal,
  setOpenModal,
  updateMembership,
  onUpgradePlan,
  onClose,
  setOpenPaymentModal,
  hasCard,
  isSotal,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  // eslint-disable-next-line
  const mainPlans = React.useMemo(
    () => (plans && plans.length ? plans : unPlans),
    // eslint-disable-next-line
    [plans],
  );
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (plans && plans.length > 0) {
      setIsConfirmed(plans[0].subscribed);
    } else if (unPlans && unPlans.length > 0) {
      setIsConfirmed(!unPlans[0].subscribed);
    }
  }, []);

  React.useEffect(() => {
    if (openModal) {
      setIsLoading(false);
      setError(false);
    }
  }, [openModal]);

  const onConfirmClicked = () => {
    if (isConfirmed) {
      onUpgradePlan();
    } else if (!hasCard) {
      setOpenModal(false);
      setOpenPaymentModal(true);
    } else {
      setIsLoading(true);
      setError(false);
      // call api
      updateMembership(
        token,
        isSotal
          ? { sotalcloud_package_slug: plans[0].slug }
          : { package_slug: plans[0].slug },
      )
        .then((response) => {
          setIsLoading(false);
          if (response.status === 'success') {
            setIsConfirmed(true);
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setError(true);
        });
    }
  };

  return (
    <Dialog
      className={classes.modal}
      classes={{ paper: classes.dialog }}
      open={openModal}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>
        <Box>
          <Box className={classes.closeBox}>
            <IconButton
              className={classes.dialogClose}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Box component={ClearIcon} className={classes.menuIcon} />
            </IconButton>
          </Box>
          <Box className={classes.name}>
            {isConfirmed ? 'Success!' : 'Upgrade Plan'}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          <Box className={classes.description}>
            {isConfirmed
              ? `You have successfully upgraded to ${
                  mainPlans[0] && mainPlans[0].name
                }`
              : `Are you sure you want to upgrade to ${
                  mainPlans[0] && mainPlans[0].name
                }?`}
          </Box>
          <Box className={classes.description}>
            {`Your ${
              !mainPlans[0].billing_period ||
              mainPlans[0].billing_period === 'Month'
                ? 'monthly'
                : 'annual'
            } total will ${
              unPlans && unPlans.length > 0 ? 'decrease by ' : 'increase by '
            }`}
            <span className={classes.price}>
              $
              {mainPlans[0]?.display_annual_price
                ? mainPlans[0].annual_price
                : mainPlans[0].price}
            </span>
          </Box>
          {!isConfirmed && (
            <Box my={2}>
              {mainPlans.mapWithKey((plan) => (
                <Box className={classes.price} key={plan.key}>
                  {plan.name}
                </Box>
              ))}
            </Box>
          )}
          {isLoading && (
            <Box
              width={1}
              justifyContent="center"
              alignItems="center"
              height="10vh"
              position="relative"
            >
              <LoadingSpinner />
            </Box>
          )}
          {error && (
            <span style={{ color: 'white' }}>
              {'Upgrade '}
              could not be processed at this time. Please contact' &nbsp;
              <a
                href={window.BRAND.support_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
              &nbsp; for more information.
            </span>
          )}
          {!isLoading && !error && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={1}
            >
              <Box
                className={classes.buttonBox}
                style={{
                  background: isConfirmed
                    ? window.BRAND.primary_color
                    : '#477410',
                }}
                onClick={() => {
                  onConfirmClicked();
                }}
              >
                {isConfirmed ? 'Watch Now' : 'Confirm'}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
