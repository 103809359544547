export const AMAZON_CLOUDFRONT = 'https://d6o7tahhtpeom.cloudfront.net';
export const NONE_VALUE = 'None';
export const PAGE_SIZE = 24;
export const STATIC_URL = 'https://d6o7tahhtpeom.cloudfront.net/static/';
export const WIDTH_BREAK_POINT = 550;
export const BRAND_SLUG = process.env.REACT_APP_BRAND_SLUG;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

window.API_PREMIUM = process.env.REACT_APP_API_PREMIUM;
window.API_SERVICES_DOMAIN = process.env.REACT_APP_API_DOMAIN;
window.LIVEAPI_BASE_URL = process.env.REACT_APP_LIVEAPI_BASE_URL;

if (!!localStorage.getItem('settings')) {
  const settings = JSON.parse(localStorage.getItem('settings'));
  window.PUBLIC_PATH = settings.PUBLIC_PATH;
  window.STATIC_URL = settings.STATIC_URL;
  window.RECAPTCHA_PUBLIC_KEY = settings.RECAPTCHA_PUBLIC_KEY;
  window.BRAND = settings.brand;
}

export const ACCORDIONS_TYPE = {
  FRONT_PAGE: 'front_page',
  SHOW_GENRE: 'shows/by_genre',
  MOVIE_GENRE: 'movies/by_genre',
  NETWORK: 'network',
  SITE_PAGE: 'site_page',
};

export const BREADCRUMBS_TITLE = [
  { name: 'TV Shows', url: '/guide/tv/tv_shows/' },
  { name: 'Kids', url: '/guide/Kids/' },
];

export const BREADCRUMBS = {
  ON_DEMAND: 'on-demand',
  HOME: 'home',
  TV_SHOWS: 'Tv Shows',
  MOVIES: 'Movies',
  TV_SHOWS_NEW: 'tv-shows-new',
};

export const PUBLIC_KEY = {
  RECAPTCHA_PUBLIC_KEY: window.RECAPTCHA_PUBLIC_KEY,
};

export const COLOR = {
  t_white_light: '#c8c8c8',
  border_black_light2: '#cecece',
  t_green_color: '#5fa55a',
  b_black: '#101010',
  b_black_mlight: '#252525',
  b_black_light: '#353535',
  b_white_light: '#cccccc',
  b_white_mligth: '#ddd',
  dot_text_shadow: '#1b1b1b',
  dot_color: 'white',
  border_color: '#333',
  blue_light: '#00c6d0',
  green_light: '#00ef00',
  hover_color: '#454545',
};

export const FAVORITE_TYPE = {
  MOVIES: 'movie',
  MOVIEGENRES: 'moviegenre',
  SHOWS: 'show',
  NETWORKS: 'network',
  CHANNELS: 'channel',
  VIDEOLIBRARIES: 'videolibrarires',
};

export const INFOTYPE = {
  SHOW_INFO: 'show_info',
  MOVIE_INFO: 'movie_info',
};

export const LISTTYPE = {
  SHOWS: 'Shows',
  MOVIES: 'Movies',
};

export const LOGIN_LOADING_STATE = {
  INIT: 'init',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const MODE = {
  ALL: 'all',
  FREE: 'free',
  PREMIUM: 'premium',
};

export const MONTH_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MOVIE_RATINGS = [
  { value: 'N', label: 'Not Rated' },
  { value: 'G', label: 'G' },
  { value: '1', label: 'PG-13' },
  { value: 'P', label: 'PG' },
  { value: 'R', label: 'R' },
  { value: 'V', label: 'TV' },
  { value: 'X', label: 'X' },
  { value: 'C', label: 'NC-17' },
];

export const getSortValues = (type) => [
  { label: 'A -> Z', value: 'name' },
  { label: 'Z -> A', value: '-name' },
  {
    label: 'Release Date (newest to oldest)',
    value: `-${type === 'movie' ? 'release_year' : 'first_aired'}`,
  },
  {
    label: 'Release Date (oldest to newest)',
    value: type === 'movie' ? 'release_year' : 'first_aired',
  },
  { label: 'Popularity (most to least)', value: 'popularity' },
  { label: 'Popularity (least to most)', value: '-popularity' },
];

export const PAGE_FILTER = {
  PAGE_TYPE: {
    TVSHOW: 'shows',
    MOVIE: 'movies',
  },
  FILTER_DATA: {
    dropdown: '',
    selectedOptions: [],
    params: {},
    ordering: '',
    isReloadShow: true,
    isReloadMovie: true,
    pageType: 'shows',
  },
};

export const PROVIDERTYPE = {
  FREE: 'free',
  SUBSCRIPTIONS: 'subscriptions',
  PAID: 'paid',
};

export const PRICEFORMATS = {
  SD: 'SD',
  HD: 'HD',
  PURCHASE: 'purchase',
  RENT: 'rent',
};

export const SLUGS = {
  SEASON_SLUG: 'season_slug',
  SEASON: 'season',
  FRONT_PAGE: 'front_page',
  SLASH: '/',
  HOME: 'home',
  BY_GENRE: 'by_genre',
  BY_RATING: 'by_rating',
};

export const SHOW_RATINGS = [
  { value: 'N', label: 'Not Rated' },
  { value: 'Y', label: 'TV-Y' },
  { value: '7', label: 'TV-Y7' },
  { value: 'F', label: 'TV-Y7FV' },
  { value: 'G', label: 'TV-G' },
  { value: 'P', label: 'TV-PG' },
  { value: '1', label: 'TV-14' },
  { value: 'M', label: 'TV-MA' },
  { value: 'C', label: 'NC-17' },
];

export const STATES_AND_PROVINCES = [
  ['AL', 'US-Alabama'],
  ['AK', 'US-Alaska'],
  ['AS', 'US-American Samoa'],
  ['AR', 'US-Arkansas'],
  ['AZ', 'US-Arizona'],
  ['CA', 'US-California'],
  ['CO', 'US-Colorado'],
  ['CT', 'US-Connecticut'],
  ['DE', 'US-Delaware'],
  ['DC', 'US-District of Columbia'],
  ['FM', 'US-Federated States of Micronesia'],
  ['FL', 'US-Florida'],
  ['GA', 'US-Georgia'],
  ['GU', 'US-Guam'],
  ['HI', 'US-Hawaii'],
  ['ID', 'US-Idaho'],
  ['IL', 'US-Illinois'],
  ['IN', 'US-Indiana'],
  ['IA', 'US-Iowa'],
  ['KS', 'US-Kansas'],
  ['KY', 'US-Kentucky'],
  ['LA', 'US-Louisiana'],
  ['ME', 'US-Maine'],
  ['MD', 'US-Maryland'],
  ['MH', 'US-Marshall Islands'],
  ['MA', 'US-Massachusetts'],
  ['MI', 'US-Michigan'],
  ['MN', 'US-Minnesota'],
  ['MS', 'US-Mississippi'],
  ['MO', 'US-Missouri'],
  ['MT', 'US-Montana'],
  ['NC', 'US-North Carolina'],
  ['ND', 'US-North Dakota'],
  ['NE', 'US-Nebraska'],
  ['NV', 'US-Nevada'],
  ['NH', 'US-New Hampshire'],
  ['NJ', 'US-New Jersey'],
  ['NM', 'US-New Mexico'],
  ['NY', 'US-New York'],
  ['OH', 'US-Ohio'],
  ['OK', 'US-Oklahoma'],
  ['OR', 'US-Oregon'],
  ['PA', 'US-Pennsylvania'],
  ['PR', 'US-Puerto Rico'],
  ['RI', 'US-Rhode Island'],
  ['SC', 'US-South Carolina'],
  ['SD', 'US-South Dakota'],
  ['TN', 'US-Tennessee'],
  ['TX', 'US-Texas'],
  ['UT', 'US-Utah'],
  ['VA', 'US-Virginia'],
  ['VI', 'US-Virgin Islands'],
  ['VT', 'US-Vermont'],
  ['WA', 'US-Washington'],
  ['WV', 'US-West Virginia'],
  ['WI', 'US-Wisconsin'],
  ['WY', 'US-Wyoming'],
  ['AB', 'CA-Alberta'],
  ['BC', 'CA-British Columbia'],
  ['MB', 'CA-Manitoba'],
  ['NB', 'CA-New Brunswick'],
  ['NL', 'CA-Newfoundland and Labrador'],
  ['NT', 'CA-Northwest Territories'],
  ['NS', 'CA-Nova Scotia'],
  ['NU', 'CA-Nunavut'],
  ['ON', 'CA-Ontario'],
  ['PE', 'CA-Prince Edward Island'],
  ['PW', 'CA-PW'],
  ['QC', 'CA-Quebec'],
  ['SK', 'CA-Saskatchewan'],
  ['YT', 'CA-Yukon Territories'],
];

export const FULL_SCREEN_PATH = {
  viewBox: '0 0 240 240',
  path: `<path d="M96.3,186.1c1.9,1.9,1.3,4-1.4,4.4l-50.6,8.4c-1.8,0.5-3.7-0.6-4.2-2.4c-0.2-0.6-0.2-1.2,0-1.7l8.4-50.6c0.4-2.7,2.4-3.4,4.4-1.4l14.5,14.5l28.2-28.2l14.3,14.3l-28.2,28.2L96.3,186.1z M195.8,39.1l-50.6,8.4c-2.7,0.4-3.4,2.4-1.4,4.4l14.5,14.5l-28.2,28.2l14.3,14.3l28.2-28.2l14.5,14.5c1.9,1.9,4,1.3,4.4-1.4l8.4-50.6c0.5-1.8-0.6-3.6-2.4-4.2C197,39,196.4,39,195.8,39.1L195.8,39.1z" class=""></path>`,
};
