import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';
import clsx from 'clsx';
import { Box, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { staticUrl, WIDTH_BREAK_POINT } from 'global';
import { FavoriteButton } from '../FavoriteButton';
import LazyImage from '../LazyImage';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  imgContainer: {
    position: 'relative',
    '&:hover > $favoriteBox': {
      height: theme.spacing(6),
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '& > $favoriteBox': {
      height: 0,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  img: {
    width: '100%',
    height: '100%',
  },
  imgWrapper: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: '#1b1b1b',
    borderRadius: theme.spacing(2),
  },
  favoriteBox: {
    position: 'absolute',
    bottom: 0,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: 0,
    backgroundColor: 'rgba(0, 156, 254, 0.9)',
  },
  linkContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  linkBox: {
    display: 'flex',
    alignItems: 'start',
    marginTop: theme.spacing(1),
  },
  name: {
    display: 'table-cell',
    width: theme.spacing(15),
    minWidth: theme.spacing(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  releaseDate: {
    marginLeft: theme.spacing(1),
  },
  linkItem: {
    color: '#c8c8c8',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    fontWeight: '400',
    textDecorationColor: theme.palette.primary.main,
    transition: '0.3s ease all !important',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const PosterContainer = React.memo(
  ({ width, mediaInfo, isMovie, isFavorite, onFavorite, ...props }) => {
    const imgUrl =
      mediaInfo.image ||
      '//d62yj09qiua3x.cloudfront.net/static/guide/img/placeholders/default-360x202.png';
    const classes = useStyles();
    const type = isMovie ? 'movies' : 'shows';

    return (
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={isMovie ? (width > WIDTH_BREAK_POINT ? 4 : 12) : 12}>
            <Box display="flex" width={1} mb={2}>
              <Box className={classes.imgContainer}>
                <Box
                  className={clsx({ [classes.imgWrapper]: !mediaInfo.image })}
                >
                  <LazyImage
                    className={classes.img}
                    src={imgUrl}
                    alt="poster"
                    height="100%"
                    width="100%"
                  />
                </Box>
                <Box className={classes.favoriteBox}>
                  <FavoriteButton
                    favoriteFlag={isFavorite}
                    onFavorite={() => onFavorite()}
                    model={type}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={isMovie ? (width > WIDTH_BREAK_POINT ? 8 : 12) : 12}>
            <Box className={classes.linkContainer}>
              <Box className={classes.linkBox}>
                <Box className={classes.name}>AGE RATING</Box>
                <Box className={classes.releaseDate}>-</Box>
                <Link
                  className={classes.linkItem}
                  component={RouterLink}
                  to={`/guide/${type}/?rating=${
                    mediaInfo.rating?.code || 'N'
                  }&page=1&page_size=15&ordering=`}
                >
                  <Box className={classes.releaseDate}>
                    {mediaInfo.rating?.name || 'Not Rated'}
                  </Box>
                </Link>
              </Box>
            </Box>
            {mediaInfo.network && (
              <Box className={classes.linkContainer}>
                <Box className={classes.linkBox}>
                  <Box className={classes.name}>NETWORK</Box>
                  <Box className={classes.releaseDate}>-</Box>
                  <Link
                    className={classes.linkItem}
                    component={RouterLink}
                    to={
                      `/guide/network/info/${mediaInfo.network}/` +
                      `?network=${mediaInfo.network}&page=1&page_size=15&ordering=`
                    }
                  >
                    <Box className={classes.releaseDate}>
                      {mediaInfo.network}
                    </Box>
                  </Link>
                </Box>
              </Box>
            )}
            {mediaInfo.genres && (
              <Box className={classes.linkContainer}>
                <Box className={classes.linkBox}>
                  <Box className={classes.name}>GENRE</Box>
                  <Box className={classes.releaseDate}>-</Box>
                  <Box display="flex" flexWrap="wrap">
                    {mediaInfo.genres.map((genre, index) => {
                      const key = `linkItem-${index}`;
                      return (
                        <React.Fragment key={key}>
                          <Link
                            className={classes.linkItem}
                            component={RouterLink}
                            to={`/guide/${type}/?genre=${genre.slug}&page=1&page_size=23&ordering=`}
                          >
                            <Box className={classes.releaseDate}>
                              {genre.name}
                            </Box>
                          </Link>
                          {index !== mediaInfo.genres.length - 1 && (
                            <Box>,</Box>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )}
            {isMovie && mediaInfo.runtime && (
              <Box className={classes.linkContainer}>
                <Box className={classes.linkBox}>
                  <Box className={classes.name}>RUNTIME</Box>
                  <Box className={classes.releaseDate}>-</Box>
                  <Box className={classes.releaseDate}>
                    {mediaInfo.runtime} minutes
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={classes.linkContainer}>
              <Box className={classes.linkBox}>
                <Box className={classes.name}>
                  <img src={staticUrl('images/imdb-logo.png')} alt="" />
                </Box>
                <Box className={classes.releaseDate}>-</Box>
                <Box className={classes.releaseDate}>
                  {mediaInfo.imdb_rating || 0} / 10
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  },
);

export const Poster = withResizeDetector(PosterContainer, {
  refreshMode: 'throttle',
  refreshRate: 500,
});
