/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { COLOR } from '../../../global';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
    textAlign: 'center',
  },
  name: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'white',
    textAlign: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      borderRadius: theme.spacing(2),
    },
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(15),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: `${COLOR.hover_color}`,
    },
  },
  imgBox: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  closeBox: {
    height: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  dialogClose: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  buttonBox: {
    borderRadius: theme.spacing(0.5),
    color: 'white',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    background: `${COLOR.t_white_light}`,
    fontSize: theme.typography.h6.fontSize,
  },
  menuIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    background: `rgba(150, 150, 150, 0.8)`,
    color: 'white',
    cursor: 'pointer',
  },
  closeIcon: {
    width: '100%',
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const PremiumServicesDialog = ({
  openModal,
  setOpenModal,
  premiumServices,
  onClose,
  onSelectPremium,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Box className={classes.closeBox}>
            <IconButton
              className={classes.dialogClose}
              onClick={() => onClose()}
            >
              <Box component={ClearIcon} className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Box className={classes.title}>
            Access other Premium Channel Services
          </Box>
          <Box className={classes.name}>
            Select a Premium Channel Provider below to Login or Sign Up
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {premiumServices &&
            premiumServices.map((item, index) => (
              <Grid
                item
                key={item.slug}
                xs={6}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  className={classes.itemContainer}
                  onClick={() => {
                    onSelectPremium(item);
                  }}
                  color="white"
                >
                  {item.logo ? (
                    <img
                      src={item.logo}
                      className={classes.imgBox}
                      alt="Premium item logo"
                    />
                  ) : (
                    item.name
                  )}
                </Box>
              </Grid>
            ))}
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box className={classes.buttonBox} onClick={() => onClose()}>
            Cancel
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
