import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoVideoPlayer } from '../../../redux/reducers/players';

/**
 * @component useMuteChannels
 * This hook controls the mute state from JWPlayer when its playing on background when the user opens the information icon's video dialog.
 *
 * @returns {Object} | { hasMutedChannelsBehind }
 */
const useMuteChannels = () => {
  const dispatch = useDispatch();
  const infoVideoPlayer = useSelector(({ players }) => players.infoVideo);
  const [hasMutedChannelsBehind, setHasMutedChannelsBehind] = React.useState(
    undefined,
  );

  React.useEffect(() => {
    const wasPlayingInfoVideoWithMutedChannels =
      infoVideoPlayer.hasMutedChannelsBehind &&
      window.jwplayer('jwPlayer')?.getMute();

    const hasJustOpenedInfoVideoWithNonMutedChannels =
      !window.jwplayer('jwPlayer')?.getMute() && !infoVideoPlayer.playing;

    const hasJustOpenedInfoVideoWithMutedChannels =
      window.jwplayer('jwPlayer')?.getMute() && infoVideoPlayer.playing;

    if (hasJustOpenedInfoVideoWithMutedChannels) {
      setHasMutedChannelsBehind(true);
      dispatch(
        setInfoVideoPlayer({
          field: 'hasMutedChannelsBehind',
          value: true,
        }),
      );
      return;
    }

    /**
     * The mute doesn't need to be set because:
     * 1) The user just opened the info video's dialog with muted channels,
     * so it should be kept like that (wasPlayingInfoVideoWithMutedChannels)
     *
     * 2) The user was playing the info video and has just closed its dialog
     * at the meantime the channels is playing on background (hasJustOpenedInfoVideoWithNonMutedChannels)
     */
    if (
      wasPlayingInfoVideoWithMutedChannels ||
      hasJustOpenedInfoVideoWithNonMutedChannels
    ) {
      return;
    }

    /**
     * User had a muted channel when he opened the information icon's video dialog
     */
    if (infoVideoPlayer.hasMutedChannelsBehind) {
      setHasMutedChannelsBehind(false);
      dispatch(
        setInfoVideoPlayer({
          field: 'hasMutedChannelsBehind',
          value: false,
        }),
      );
      return;
    }

    window.jwplayer('jwPlayer').setMute();
  }, [window.jwplayer, infoVideoPlayer]); //eslint-disable-line

  return { hasMutedChannelsBehind };
};

export default useMuteChannels;
