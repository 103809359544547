import React from 'react';
import _keys from 'lodash/keys';
import is from 'is_js';
import { useHistory } from 'react-router-dom';

const useSwiper = ({ carouselData, slideBy = 3 }) => {
  const history = useHistory();
  const [swiper, setSwiper] = React.useState(undefined);

  const slidesPerView = React.useMemo(
    () => (carouselData.kind === 'S' ? 7 : 10),
    [carouselData],
  );

  const sizes = React.useMemo(() => {
    const defaultSizes = { width: '144px', height: '144px' };
    const sizesPerKind = {
      S: { width: '210px', height: is.safari() ? '56.25%' : '114px' },
      M: { width: '148px', height: is.safari() ? '142.5%' : '211px' },
    };

    return sizesPerKind[carouselData.kind] || defaultSizes;
  }, [carouselData]);

  const placeholderClassName = React.useMemo(() => {
    const classesPerKind = {
      S: 'showsItem',
      M: 'movieItem',
      L: 'otherItem',
    };

    return classesPerKind[carouselData.kind];
  }, [carouselData]);

  const breakpoints = React.useMemo(() => {
    const { kind } = carouselData;

    // S = Show, M = Movie, L = List?
    const slidesPerWidthAndKind = {
      500: { S: 2, M: 3, L: 3 },
      850: { S: 3, M: 5, L: 5 },
      1200: { S: 4, M: 6, L: 6 },
      1350: { S: 5, M: 7, L: 7 },
      1600: { S: 6, M: 8, L: 8 },
      1700: { S: 7, M: 9, L: 9 },
    };

    let result = { 1800: { slidesPerView } };
    _keys(slidesPerWidthAndKind).forEach((key) => {
      result = {
        ...result,
        [key]: { slidesPerView: slidesPerWidthAndKind[key][kind] },
      };
    });

    return result;
  }, [carouselData]);

  const onClickSlide = (url) => {
    history.push(url || '#');
  };

  const onSwipe = (slideBy) => {
    if (!swiper) return;
    swiper.slideTo(swiper.activeIndex + slideBy);
  };

  const onPrevious = () => {
    onSwipe(slideBy * -1);
  };

  const onNext = () => {
    onSwipe(slideBy);
  };

  return {
    slidesPerView,
    sizes,
    placeholderClassName,
    breakpoints,
    swiper,
    setSwiper,
    onClickSlide,
    onPrevious,
    onNext,
  };
};

export default useSwiper;
