import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    marginBottom: props.marginbottom || '16px',
  }),
}));

export const FormGroup = ({ children, className, style, marginbottom }) => {
  const classes = useStyles({ marginbottom });
  return (
    <Box className={clsx(classes.root, className)} style={style}>
      {children || 'Form Group'}
    </Box>
  );
};
