import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(20)}px`,
    },
  },
  dialog: {
    position: 'absolute',
    top: theme.spacing(25),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      [theme.breakpoints.up('md')]: {
        maxWidth: '60%',
      },
    },
  },
  name: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    color: `${COLOR.green_light}`,
    fontSize: theme.typography.subtitle1.fontSize,
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(8),
  },
  buttonBox: {
    borderRadius: theme.spacing(0.5),
    color: 'white',
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    background: `${window.BRAND.primary_color}`,
  },
}));

export const ActivatedPremiumDialog = ({
  logo,
  openModal,
  onStartWatching,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.modal}
      classes={{ paper: classes.dialog }}
      open={openModal}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>
        <Box className={classes.name}>You've successfully connected!</Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          <Box className={classes.logoBox}>
            <img
              src={logo || ''}
              alt=""
              height="100%"
              style={{ objectFit: 'contain', filter: 'grayscale(100%)' }}
            />
          </Box>
          <Box className={classes.description}>
            <Box>connected</Box>
            <Box ml={1}>
              <DoneRoundedIcon />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              className={classes.buttonBox}
              onClick={() => {
                onStartWatching();
              }}
            >
              Start Watching
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
