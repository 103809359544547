import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { COLOR } from 'global';

const useStyles = makeStyles((theme) => ({
  textArea: {
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    color: COLOR.t_white_light,
    '& div': { outline: 'none', padding: 10 },
    '& textarea': {
      textFillColor: `${COLOR.t_white_light} !important`,
    },
    '& fieldset': {
      border: `1px solid ${COLOR.border_black_light2} !important`,
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
}));

export const TextArea = ({ rowsMax = 5, rows = 5, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      className={classes.textArea}
      multiline
      fullWidth
      rowsMax={rowsMax}
      rows={rows}
      {...props}
    />
  );
};
