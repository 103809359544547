import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'channels',
  initialState: {
    // for channels
    lastUpdatedDate: {},
    channelCategories: {},
    channelsData: {},
    channelStream: {},
    channelEPG: {},
    freePackageList: [],
    freePackageCategories: {},
    catchUpData: {},
    dvrStatus: {},
    dvrList: [],
    dvrStream: {},
    // for vidgo
    areaId: undefined,
    kisweToken: undefined,
    packageList: [],
    packageCategories: {},
    packageInfo: {},
    packageChannels: {},
    isUserPremium: false,
    subscriptionStatus: '',
    userPackage: undefined,
    vidgoModalData: {},
    channelAds: undefined,
  },
  reducers: {
    onSetChannelCategories: (state, action) => {
      const { field, value } = action.payload;
      state.channelCategories[field] = value;
    },
    onSetChannelsData: (state, action) => {
      const { slug, listData } = action.payload;
      state.channelsData[slug] = listData;
      state.lastUpdatedDate[slug] = Date();
      state.channelEPG = {};
    },
    onSetChannelStream: (state, action) => {
      state.channelStream = action.payload.streamData;
    },
    onSetChannelEpg: (state, action) => {
      if (action.payload.reset) {
        state.channelEPG = {};
      } else {
        const { slug, epgData } = action.payload;
        state.channelEPG[slug] = epgData;
      }
    },
    onSetFreePackageList: (state, action) => {
      state.freePackageList = action.payload.freePackageList;
    },
    onSetFreePackageCategories: (state, action) => {
      const { slug, freePackageCategories } = action.payload;
      state.freePackageCategories[slug] = freePackageCategories;
    },
    onSetCatchUpList: (state, action) => {
      const { slug, catchUpData } = action.payload;
      state.catchUpData[slug] = catchUpData;
    },
    onSetDVRStatus: (state, action) => {
      state.dvrStatus = action.payload.dvrStatus;
    },
    onSetDVRList: (state, action) => {
      state.dvrList = action.payload.dvrList;
    },
    onSetDVRStream: (state, action) => {
      state.dvrStream = action.payload.dvrStream;
    },
    onSetFreePackages: (state, action) => {
      state.packageList = action.payload.packageList;
    },
    onSetPackageCategories: (state, action) => {
      const { slug, packageCategories } = action.payload;
      state.packageCategories[slug] = packageCategories;
    },
    onSetPackageInfo: (state, action) => {
      state.packageInfo = action.payload.packageInfo;
    },
    onSetPackageChannels: (state, action) => {
      const { slug, listData } = action.payload;
      state.packageChannels[slug] = listData;
    },
    onSetUserPackage: (state, action) => {
      state.userPackage = action.payload.data;
    },
    onSetModalVidgoData: (state, action) => {
      state.vidgoModalData = action.payload.data;
    },
    onSetKisweToken: (state, action) => {
      state.areaId = action.payload.areaId;
      state.kisweToken = action.payload.kisweToken;
    },
  },
});

export default slice.reducer;

const {
  onSetChannelCategories,
  onSetChannelsData,
  onSetChannelStream,
  onSetChannelEpg,
  onSetFreePackageList,
  onSetFreePackageCategories,
  onSetCatchUpList,
  onSetDVRStatus,
  onSetDVRList,
  onSetDVRStream,
  onSetFreePackages,
  onSetPackageCategories,
  onSetPackageInfo,
  onSetPackageChannels,
  onSetUserPackage,
  onSetModalVidgoData,
  onSetKisweToken,
} = slice.actions;

export const setChannelCategories = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetChannelCategories({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setChannelsData = ({ slug, listData }) => async (dispatch) => {
  try {
    dispatch(onSetChannelsData({ slug, listData }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setChannelStream = ({ streamData }) => async (dispatch) => {
  try {
    dispatch(onSetChannelStream({ streamData }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setChannelEpg = ({ slug, epgData, reset }) => async (dispatch) => {
  try {
    dispatch(onSetChannelEpg({ slug, epgData, reset }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setFreePackageList = ({ freePackageList }) => async (dispatch) => {
  try {
    dispatch(onSetFreePackageList({ freePackageList }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setFreePackageCategories = ({
  slug,
  freePackageCategories,
}) => async (dispatch) => {
  try {
    dispatch(onSetFreePackageCategories({ slug, freePackageCategories }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setCatchUpList = ({ slug, catchUpData }) => async (dispatch) => {
  try {
    dispatch(onSetCatchUpList({ slug, catchUpData }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setDvrStatus = ({ dvrStatus }) => async (dispatch) => {
  try {
    dispatch(onSetDVRStatus({ dvrStatus }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setDvrList = ({ dvrList }) => async (dispatch) => {
  try {
    dispatch(onSetDVRList({ dvrList }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setDvrStream = ({ dvrStream }) => async (dispatch) => {
  try {
    dispatch(onSetDVRStream({ dvrStream }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setFreePackages = ({ packageList }) => async (dispatch) => {
  try {
    dispatch(onSetFreePackages({ packageList }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setPackageCategories = ({ slug, packageCategories }) => async (
  dispatch,
) => {
  try {
    dispatch(onSetPackageCategories({ slug, packageCategories }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setPackageInfo = ({ packageInfo }) => async (dispatch) => {
  try {
    dispatch(onSetPackageInfo({ packageInfo }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setPackageChannels = ({ slug, listData }) => async (dispatch) => {
  try {
    dispatch(onSetPackageChannels({ slug, listData }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setUserPackage = ({ data }) => async (dispatch) => {
  try {
    dispatch(onSetUserPackage({ data }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setModalVidgoData = ({ data }) => async (dispatch) => {
  try {
    dispatch(onSetModalVidgoData({ data }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setKisweToken = ({ kisweToken }) => async (dispatch) => {
  try {
    dispatch(onSetKisweToken({ kisweToken }));
  } catch (e) {
    return console.error(e.message);
  }
};
