import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import { ListHeader } from './ListHeader';
import { SliderList } from './SliderList';

const extraParams = 'page=1&page_size=15&ordering=';

export const GenreList = ({ genres, type, title }) => {
  const classes = useStyles();
  const memorizedGenreList = React.useMemo(
    () =>
      genres.map((genre) => (
        <Link
          to={`/guide/${type}/?genre=${genre.slug}&${extraParams}`}
          key={genre.slug}
          className={clsx(classes.item, classes[type])}
        >
          {genre.name}
        </Link>
      )),
    [genres], // eslint-disable-line
  );

  return (
    <>
      <ListHeader mb={1} title={title} />
      <Box className={classes.sliderContainer}>
        <SliderList>
          {memorizedGenreList}

          <Link
            to={`/guide/${type}?${extraParams}`}
            key={`see-all-${type}`}
            className={clsx(classes.item, classes[type])}
          >
            SEE ALL
          </Link>
        </SliderList>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 5,
    textTransform: 'uppercase',
    fontSize: '12px',
    cursor: 'pointer',
    color: '#fff!important',

    maxWidth: '100px!important',
    width: '100px!important',
    height: '100px!important',

    marginRight: 20,
    textDecoration: 'none',
    outline: 'nonw',
    margin: '0 auto',

    '&:hover': {
      transform: 'scale(0.98)',
      opacity: 0.8,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90px!important',
      width: '90px!important',
      height: '90px!important',
      fontSize: '10px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '125px!important',
      width: '125px!important',
      height: '125px!important',
      fontSize: '12px',
      marginRight: 5,
    },
  },
  shows: {
    background: theme.palette.primary.main,
  },
  movies: {
    background: '#4a4a4a',
  },
  sliderContainer: {
    width: 'calc(100% - 40px)',
    marginLeft: 20,
    marginBottom: 10,
  },
}));
