import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import channels from './channels';
import global from './global';
import players from './players';
import pageData from './pageData';
import search from './search';
import loading from './loading';
import genres from './genres';
import shows from './shows';
import movies from './movies';
import music from './music';
import networks from './networks';
import favoriteList from './favorites';
import subscriptions from './subscription';
import profile from './profile';
import auth from './auth';
import membership from './membership';

const persistConfig = {
  key: 'auth',
  version: 1,
  storage,
  blacklist: [
    'resetPasswordRequest',
    'resetPasswordValidate',
    'resetPasswordConfirm',
  ],
};

const persistedLogin = persistReducer(persistConfig, auth);

const reducer = combineReducers({
  auth: persistedLogin,
  global,
  players,
  channels,
  pageData,
  search,
  loading,
  genres,
  shows,
  movies,
  music,
  networks,
  favoriteList,
  subscriptions,
  profile,
  membership,
});

export default reducer;
