import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  svgIcon: (props) => ({
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fill: props.fill,

      '& path': {
        fill: props.fill,
        stroke: props.fill,
      },
    },
  }),
}));

export const CustomSVGIcon = ({ fill, src }) => {
  const [svg, setSvg] = useState(null);
  const classes = useStyles({ fill });

  useEffect(() => {
    let cancel;

    if (src) {
      axios
        .get(src, {
          cancelToken: new axios.CancelToken((c) => {
            cancel = c;
          }),
        })
        .then((res) => res.text())
        .then(setSvg);
    }

    return () => {
      if (cancel) {
        cancel();
      }
    };
  }, [src]);

  return (
    <div
      className={classes.svgIcon}
      dangerouslySetInnerHTML={{ __html: svg }} //eslint-disable-line
    />
  );
};
