import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'global',
  initialState: {
    globalState: {
      isDisplayModeState: false,
    },
    welcomePage: {},
    ipAddress: undefined,
    countryList: [],
  },
  reducers: {
    onSetGlobal: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onSetGlobalState: (state, action) => {
      state.globalState = { ...state.globalState, ...action.payload };
    },
  },
});

export default slice.reducer;

const { onSetGlobal, onSetGlobalState } = slice.actions;
export const setGlobal = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetGlobal({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
export const setGlobalState = (obj) => async (dispatch) => {
  dispatch(onSetGlobalState(obj));
};
