import React from 'react';
import _keys from 'lodash/keys';
import is from 'is_js';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Switch,
  Select,
  TextField,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import {
  validationRegisterForm,
  validationField,
  checkStateAndSetState,
} from 'global';
import { fetchStart, registerWithCode } from 'apis';
import { SuccessView, LoadingSpinner } from 'components';
import { fields } from './RegisterField';

const AGE_RANGE_VALUES = [
  { value: 0, label: 'Age Range' },
  { value: 1, label: '18 - 24' },
  { value: 2, label: '35 - 34' },
  { value: 3, label: '35 - 44' },
  { value: 4, label: '45 - 54' },
  { value: 5, label: '55+' },
];

export const RegisterPage = (props) => {
  const { code } = useParams();
  const classes = useStyles();
  const [formData, setFormData] = React.useState(fields);
  const [success, setSuccess] = React.useState(false);
  const [codeUsed, setCodeUsed] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tosError, setTosError] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const textButton = React.useMemo(
    () => (loading ? 'Registering...' : 'Register'),
    [loading],
  );

  const classesSwitch = {
    root: classes.checkBox,
    switchBase: classes.switchBase,
    thumb: classes.thumb,
    track: classes.track,
    checked: classes.checked,
  };

  React.useEffect(() => {
    fetchStart(code).then(
      () => setMounted(true),
      () => {
        setMounted(true);
        setCodeUsed(true);
      },
    );
  }, []);

  const updateFormData = (name, value) => {
    const updatedFormElement = {
      ...formData[name],
      value,
    };

    setFormData({
      ...formData,
      [name]: validationField(updatedFormElement),
    });
  };

  const handleChange = (event) => {
    updateFormData(event.target.name, event.target.value);
  };

  const onSubmit = () => {
    checkStateAndSetState(tosError, setTosError, '');
    setErrors([]);
    if (!validationRegisterForm(formData, setFormData, setErrors)) {
      if (!formData.tos.value) {
        setTosError('You must accept the Terms Of Service.');
        return;
      }
      const data = {};
      _keys(formData).forEach((formElementId) => {
        data[formElementId] = formData[formElementId].value;
      });
      setLoading(true);
      registerWithCode(
        data,
        code,
        setSuccess,
        setCodeUsed,
        setErrors,
        setLoading,
      );
    }
  };

  const renderField = (name, placeholder, type = 'text') => (
    <Grid item xs={12} md={6}>
      <TextField
        className={classes.inputContainer}
        error={formData[name].invalid}
        helperText={formData[name].messages && formData[name].messages[0]}
        fullWidth
        InputProps={{
          className: classes.input,
          disableUnderline: true,
        }}
        placeholder={placeholder}
        name={name}
        id={name}
        type={type}
        value={formData[name].value}
        onChange={handleChange}
      />
    </Grid>
  );

  const renderSelect = () => (
    <Grid item xs={12} md={6}>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={formData.age_range.value}
        onChange={(e) => updateFormData('age_range', e.target.value)}
        input={<Input disableUnderline fullWidth className={classes.input} />}
      >
        {AGE_RANGE_VALUES.map((d) => (
          <MenuItem
            selected={formData.age_range.value === d.value}
            value={d.value}
          >
            {d.label}
          </MenuItem>
        ))}
      </Select>
      {formData.age_range.invalid && (
        <FormHelperText error>Select Age range!</FormHelperText>
      )}
    </Grid>
  );

  const renderCheckTOS = () => (
    <Box textAlign="center">
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="end"
            className={classes.checkLabel}
            control={(
              <Checkbox
                color="primary"
                onChange={(_, val) => updateFormData('tos', val)}
              />
            )}
            label={(
              <Box component="span" color={!!tosError ? 'red' : ''}>
                I have read and agree to the
              </Box>
            )}
            labelPlacement="end"
          />
          <Link className={clsx(classes.checkLink)}>Terms Of Service</Link>
        </FormGroup>
      </FormControl>
    </Box>
  );

  const renderSwitch = () => (
    <Typography component="div">
      <Grid container spacing={1} component="label">
        <Grid item className={classes.labelSwitch}>
          Male
        </Grid>
        <Grid item>
          <Switch
            classes={classesSwitch}
            onChange={(_, val) => updateFormData('gender', val ? 'F' : 'M')}
          />
        </Grid>
        <Grid item className={classes.labelSwitch}>
          Female
        </Grid>
      </Grid>
    </Typography>
  );

  const renderContainer = () => (
    <>
      <Box className={classes.header}>
        <img
          src={window.BRAND.logo_image_dark}
          alt={window.BRAND.site_name}
          width={185}
        />
      </Box>
      <Box>
        <Box className={classes.mainContainer}>
          <Box mt={2} mb={1} className={classes.titles}>
            <Typography variant="h4">Product Registration</Typography>
            <Box my={0.5}>
              Success! Your new account will be active until May 5, 2022
            </Box>
            <Box mb={2} color="#959595">
              Register your product on the form below:
            </Box>
          </Box>
          <Card>
            <CardContent>
              {is.not.empty(formData) && (
                <Grid container spacing={2}>
                  {renderField('first_name', 'First Name')}
                  {renderField('last_name', 'Last Name')}
                  {renderField('email_1', `Email (You'll use this to log in)`)}

                  {renderSelect()}

                  {renderField('pwd_1', 'Choose Password', 'password')}
                  {renderField('pwd_2', 'Confirm Password', 'password')}

                  {renderField('phone_number', 'Phone Number')}
                  {renderField('postal_code', 'Zip/Postal Code')}
                </Grid>
              )}
              {renderSwitch()}

              <Divider />

              {renderCheckTOS()}

              {is.not.empty(tosError) && (
                <Box my={2} textAlign="center" color="red">
                  {tosError}
                </Box>
              )}

              {errors?.map((e) => (
                <Box my={0.5} textAlign="center" color="red">
                  * {e}
                </Box>
              ))}

              <Box textAlign="center">
                <Button
                  className={classes.buttonRegister}
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                >
                  {textButton}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );

  return (
    <Box className={clsx(classes.container)}>
      {mounted ? (
        codeUsed ? (
          <SuccessView email={formData.email_1.value} success={success} />
        ) : (
          renderContainer()
        )
      ) : (
        <LoadingSpinner />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonRegister: {
    color: 'white',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(6)}px`,
  },
  checkLabel: { marginRight: theme.spacing(1) },
  checkLink: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: '45px',
    cursor: 'pointer',
  },
  container: {
    backgroundColor: '#ededed',
    minHeight: '100%',
    position: 'relative',
    // paddingBottom: theme.spacing(20),
  },
  header: {
    padding: `${theme.spacing(1)}px 2%`,
    backgroundColor: 'white',
  },
  input: {
    fontSize: '16px',
    backgroundColor: '#ededed',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    border: '1px solid #ccc',
    boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%)`,
    color: '#555',
  },
  mainContainer: {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      maxWidth: '100%',
    },
  },
  labelSwitch: {
    lineHeight: `${theme.spacing(5)}px`,
    color: 'grey',
  },
  titles: {
    [theme.breakpoints.down('sm')]: { padding: `0 ${theme.spacing(3)}px` },
  },

  // check button

  checkBox: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    backgroundColor: theme.palette.primary.main,
    '& + $track': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
      border: 'none',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));
