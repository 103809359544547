import axios from 'axios';
import * as global from 'global/index';
import { setPageData, setPage } from 'redux/reducers/pageData';
import { setFavoriteList, onRemoveFavorite } from 'redux/reducers/favorites';
import { setLoading } from 'redux/reducers/loading';
import { setResultByType, setSearch } from 'redux/reducers/search';
import {
  getFilterGenre,
  getFilterRating,
  MOVIE_RATINGS,
  SHOW_RATINGS,
} from 'global';
import { setGenres } from '../redux/reducers/genres';
import { setLinks, setShows } from '../redux/reducers/shows';
import { setMovies } from '../redux/reducers/movies';
import { setNetworks } from '../redux/reducers/networks';
import { setGlobal, setGlobalState } from '../redux/reducers/global';

const qs = require('qs');

export const getBrandSettings = () => {
  axios.get(global.BRAND.API).then((response) => {
    if (response.status === 200 && typeof response.data === 'object') {
      localStorage.setItem('settings', JSON.stringify(response.data));
      window.location.reload();
    } else {
      console.log('Exists a ERROR');
    }
  });
};

export const setIsRequestLoading = (isLoading) => (dispatch) =>
  dispatch(setLoading({ isLoading }));

export const getSuggestions = (searchText) =>
  axios
    .get(`${global.SEARCH_PAGE_SUGGESTIONS.API}?q=${searchText}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return 'error';
    })
    .catch(() => 'error');

export const getSearchResults = (params) => async (dispatch) => {
  dispatch(setSearch({ field: 'result', value: [] }));
  dispatch(setIsRequestLoading(true));

  await axios
    .get(global.SEARCH.METADATA, {
      params,
    })
    .then((response) => {
      const contentTypesToBeShown = [
        'shows',
        'movies',
        'actors',
        'channels',
        'music',
      ];

      const filteredSearchResult = response.data.filter(({ type }) =>
        contentTypesToBeShown.includes(type),
      );

      const resultWithoutItems = Object.keys(
        filteredSearchResult,
      ).map((key) => ({ ...filteredSearchResult[key], items: [] }));

      dispatch(setSearch({ field: 'result', value: resultWithoutItems }));
      dispatch(setIsRequestLoading(false));
    })
    .catch(() => {
      dispatch(setSearch({ field: 'result', value: [] }));
      dispatch(setIsRequestLoading(false));
    });
};

export const fetchRelated = (type, search) => (dispatch) => {
  dispatch(
    setSearch({
      field: type === 'shows' ? 'relatedShows' : 'relatedMovies',
      value: [],
    }),
  );
  axios.get(`${global.SEARCH.RELATED}${type}/?q=${search}`).then((response) => {
    dispatch(
      setSearch({
        field: type === 'shows' ? 'relatedShows' : 'relatedMovies',
        value: response.data.related,
      }),
    );
  });
};

const fetchPage = (dispatch, fetchParams, state, url) => {
  dispatch(setPageData({ field: 'data', value: {} }));
  const token = state.auth.jwt_token;
  const headers = { Authorization: `Bearer ${token}` };
  return new Promise((resolve) => {
    axios
      .get(url, {
        headers,
        params: fetchParams,
        paramsSerializer: (newParams) =>
          qs.stringify(newParams, { arrayFormat: 'repeat' }),
      })
      .then((response) => {
        const data = { data: response.data };
        if (state.pageData.enableFilter !== response.data.enable_filters) {
          data.enableFilter = response.data.enable_filters;
        }

        return dispatch(setPage(data));
      });
    resolve();
  });
};

export const fetchFrontPage = (slug, fetchParams) => async (
  dispatch,
  getState,
) => {
  const state = getState();

  fetchPage(dispatch, fetchParams, state, `${global.FRONT_PAGE.API + slug}/`);
};

export const fetchSitePage = (slug, fetchParams) => (dispatch, getState) => {
  const state = getState();
  fetchPage(dispatch, fetchParams, state, `${global.SITE_PAGE.API + slug}/`);
};

const fetchList = (url, newParam, count, fetchField, dispatch) =>
  axios
    .get(url, {
      params: newParam,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then((response) =>
      count
        ? dispatch(
            setPageData({
              field: fetchField,
              value: response.data.count || 0,
            }),
          )
        : dispatch(setPageData({ field: 'listData', value: response.data })),
    )
    .catch(() =>
      dispatch(
        setPageData({
          field: 'listData',
          value: {
            count: 0,
            results: [],
          },
        }),
      ),
    );

export const fetchMovieList = (params, count = false) => (
  dispatch,
  getState,
) => {
  dispatch(setPageData({ field: 'listData', value: [] }));
  const { genres } = getState();
  const newParam = {
    ...params,
    rating: getFilterRating(params.rating, MOVIE_RATINGS),
    genre: getFilterGenre(params.genre, genres.movie),
    page: count ? 1 : params.page,
    pageType: 'movies',
  };

  return fetchList(
    global.LIST_PAGE.MOVIE_API,
    newParam,
    count,
    'moviesCount',
    dispatch,
  );
};

export const fetchShowList = (params, count = false) => (
  dispatch,
  getState,
) => {
  dispatch(setPageData({ field: 'listData', value: [] }));

  const { genres } = getState();
  const newParam = {
    ...params,
    rating: getFilterRating(params.rating, SHOW_RATINGS),
    genre: getFilterGenre(params.genre, genres.show),
    page: count ? 1 : params.page,
    pageType: 'shows',
  };

  return fetchList(
    global.LIST_PAGE.SHOW_API,
    newParam,
    count,
    'showsCount',
    dispatch,
  );
};

export const fetchMovieGenreList = () => (dispatch) =>
  axios
    .get(global.MOVIE_GENRES.API)
    .then((response) =>
      dispatch(setGenres({ field: 'movie', value: response.data })),
    );

export const getSearchResultsByType = ({ type, params }) => {
  const { search = '', page = 1 } = params;

  return (dispatch, getState) => {
    if (!getState().search.result.length) {
      return;
    }

    dispatch(setIsRequestLoading(true));

    axios
      .get(`${global.SEARCH.BY_TYPE}${type}/${search}&page=${page}`)
      .then((response) => {
        dispatch(setResultByType({ result: response.data.results, type }));
        dispatch(setIsRequestLoading(false));
      })
      .catch(() => {
        dispatch(setIsRequestLoading(false));
      });
  };
};

export const fetchShowGenreList = () => (dispatch) =>
  axios
    .get(global.SHOW_GENRES.API)
    .then((response) =>
      dispatch(setGenres({ field: 'show', value: response.data })),
    );

export const fetchSourceList = () => (dispatch) =>
  axios
    .get(global.LIST_PAGE.SOURCE_API)
    .then((response) =>
      dispatch(setShows({ field: 'sourceList', value: response.data })),
    );

export const fetchNetwork = (networkSlug) => (dispatch) =>
  axios
    .get(`${global.NETWORK_INFO.API + networkSlug}/`)
    .then((response) =>
      dispatch(setNetworks({ field: 'networkInfo', value: response.data })),
    )
    .catch(() => dispatch(setNetworks({ field: 'networkInfo', value: '404' })));

export const fetchNetworkList = () => (dispatch) => {
  axios
    .get(`${global.NETWORK_INFO.API}?page_size=1000`)
    .then((response) =>
      dispatch(
        setNetworks({ field: 'networksList', value: response.data.results }),
      ),
    );
};

export const fetchChannelsCategories = () => (dispatch) => {
  axios
    .get(global.CHANNELS_INFO.CATEGORY_API)
    .then((response) =>
      dispatch(setPageData({ field: 'listData', value: response.data })),
    );
};

export const fetchChannelsByCategory = (slug) => (dispatch) => {
  axios
    .get(`${global.CHANNELS_INFO.CATEGORY_API + slug}/channels/`)
    .then((response) =>
      dispatch(setPageData({ field: 'listData', value: response.data })),
    );
};

export const fetchAllChannels = () => (dispatch) => {
  axios
    .get(`${global.CHANNELS_INFO.ALL_CHANNELS}`)
    .then((response) =>
      dispatch(setPageData({ field: 'listData', value: response.data })),
    );
};

export const fetchFavoriteList = (token, model) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let url;
  switch (model) {
    case global.FAVORITE_TYPE.MOVIES:
      url = global.FAVORITE_LIST.MOVIE_API;
      break;
    case global.FAVORITE_TYPE.MOVIEGENRES:
      url = global.FAVORITE_LIST.MOVIE_GENRE_API;
      break;
    case global.FAVORITE_TYPE.SHOWS:
      url = global.FAVORITE_LIST.SHOW_API;
      break;
    case global.FAVORITE_TYPE.NETWORKS:
      url = global.FAVORITE_LIST.NETWORK_API;
      break;
    case global.FAVORITE_TYPE.CHANNELS:
      url = global.FAVORITE_LIST.CHANNEL_API;
      break;
    default:
      url = global.FAVORITE_LIST.VIDEO_LIBRARY_API;
      break;
  }
  return (dispatch) =>
    axios
      .get(url, {
        headers,
      })
      .then((response) =>
        dispatch(
          setFavoriteList({ field: model, value: response.data.results }),
        ),
      )
      .catch(() => dispatch(setPageData({ field: 'listData', value: [] })));
};

export const addFavorite = (model, item, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (dispatch) => {
    axios
      .post(
        global.FAVORITE_LIST.ADD_API,
        {
          model,
          slug: item.slug,
        },
        { headers },
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(fetchFavoriteList(token, model));
        }
      });
  };
};

export const removeFavorite = (model, item, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (dispatch) =>
    axios
      .post(
        global.FAVORITE_LIST.REMOVE_API,
        {
          model,
          slug: item.slug,
        },
        { headers },
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          return dispatch(onRemoveFavorite({ item, type: model }));
        }
      });
};

export const fetchShowInfo = (slug, token, sotalToken) => {
  const headers = sotalToken
    ? {
        Authorization: `Bearer ${token}`,
        'sotalcloud-token': sotalToken,
      }
    : { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(
        `${
          sotalToken ? global.VOD_SHOW_INFO.BASE_API : global.SHOW_INFO.BASE_API
        }${slug}/`,
        { headers },
      )
      .then((response) =>
        dispatch(setShows({ field: 'showInfo', value: response.data })),
      )
      .catch((err) => dispatch(setShows({ field: 'showInfo', value: '404' })));
};

export const fetchSeasonEpisodes = (slug, seasonNumber, token, sotalToken) => {
  const headers = sotalToken
    ? {
        Authorization: `Bearer ${token}`,
        'sotalcloud-token': sotalToken,
      }
    : { Authorization: `Bearer ${token}` };
  return (dispatch) => {
    dispatch(setShows({ field: 'linksPerEpisodes', value: undefined }));
    dispatch(setShows({ field: 'seasonEpisodes', value: [] }));

    return axios
      .get(
        `${
          sotalToken ? global.VOD_SHOW_INFO.BASE_API : global.SHOW_INFO.BASE_API
        }${slug}/seasons/${seasonNumber}/episodes/`,
        { headers },
      )
      .then((response) =>
        dispatch(setShows({ field: 'seasonEpisodes', value: response.data })),
      );
  };
};

export const fetchLinksForEpisode = (
  seasonSlug,
  seasonNumber,
  episodeNumber,
  token,
) => {
  const headers = { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(
        global.SHOW_INFO.EPISODES_LINKS.replace('{0}', seasonSlug)
          .replace('{1}', seasonNumber)
          .replace('{2}', episodeNumber),
        { headers },
      )
      .then((response) =>
        dispatch(setLinks({ slug: episodeNumber, links: response.data })),
      );
};

export const fetchMovieInfo = (slug, token, sotalToken) => {
  const headers = sotalToken
    ? {
        Authorization: `Bearer ${token}`,
        'sotalcloud-token': sotalToken,
      }
    : { Authorization: `Bearer ${token}` };
  return (dispatch) =>
    axios
      .get(
        `${
          sotalToken ? global.VOD_MOVIE_INFO.API : global.MOVIE_INFO.API
        }${slug}/`,
        { headers },
      )
      .then((response) => {
        dispatch(setMovies({ field: 'movieInfo', value: response.data }));
      })
      .catch((error) => {
        dispatch(setMovies({ field: 'movieInfo', value: '404' }));
      });
};

export const fetchMovieLinks = (slug, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    dispatch(setMovies({ field: 'movieLinks', value: undefined }));
    return axios
      .get(global.MOVIE_INFO.MOVIE_LINKS.replace('{0}', slug), {
        headers,
      })
      .then((response) => {
        dispatch(setMovies({ field: 'movieLinks', value: response.data }));
      })
      .catch((error) => {
        dispatch(setMovies({ field: 'movieInfo', value: '404' }));
      });
  };
};

export const fetchActorInfo = (slug, token, sotalToken, params) => {
  const headers = sotalToken
    ? {
        Authorization: `Bearer ${token}`,
        'sotalcloud-token': sotalToken,
      }
    : { Authorization: `Bearer ${token}` };
  return (dispatch) => {
    dispatch(setMovies({ field: 'actor', value: undefined }));
    return axios
      .get(`${global.ACTOR_INFO.API}${slug}/`, {
        params,
        headers,
      })
      .then((response) => {
        dispatch(setMovies({ field: 'actor', value: response.data }));
      })
      .catch((err) => {
        dispatch(setMovies({ field: 'actor', value: '404' }));
      });
  };
};

export const saveGlobalState = (obj) => (dispatch) => {
  dispatch(setGlobalState(obj));
};

export const setMode = (mode) => (dispatch) => {
  dispatch(setPageData({ field: 'mode', value: mode }));
};

export const fetchWelcomePage = () => (dispatch) =>
  axios
    .get(global.FETCH_WELCOME_PAGE)
    .then((response) =>
      dispatch(setGlobal({ field: 'welcomePage', value: response.data })),
    );

export const fetchSeasonEpisodesByMode = (showSlug, token) => (dispatch) => {
  dispatch(setShows({ field: 'allSources', value: [] }));

  const headers = { Authorization: `Bearer ${token}` };
  return axios
    .get(global.SHOW_INFO.EPISODES_BY_MODE_NATIVE.replace('{0}', showSlug), {
      headers,
    })
    .then((response) => {
      dispatch(setShows({ field: 'allSources', value: response.data }));
    })
    .catch(() => dispatch(setShows({ field: 'allSources', value: [] })));
};

export const fetchSeasonEpisodesbySeasonId = (
  slug,
  seasonId,
  token,
  sotalToken,
) => (dispatch) => {
  dispatch(setShows({ field: 'seasonEpisodes', value: undefined }));

  const headers = sotalToken
    ? {
        Authorization: `Bearer ${token}`,
        'sotalcloud-token': sotalToken,
      }
    : { Authorization: `Bearer ${token}` };

  return axios
    .get(
      global.VOD_SHOW_INFO.EPISODES_BY_SEASON_ID.replace('{0}', slug).replace(
        '{1}',
        seasonId,
      ),
      {
        headers,
      },
    )
    .then((response) => {
      dispatch(setShows({ field: 'seasonEpisodes', value: response.data }));
    })
    .catch((err) => dispatch(setShows({ field: 'seasonEpisodes', value: [] })));
};

export const fetchRelatedItems = (slug, model) => {
  let url = global.SHOW_RELATED.API;
  if (model === 'related_movies') url = global.MOVIE_RELATED.API;
  return (dispatch) =>
    axios.get(url.replace('{0}', slug)).then((response) =>
      dispatch(
        setPageData({
          field: 'relatedItems',
          value: response.data[model],
        }),
      ),
    );
};

export const fetchMovieUrl = (slug, streamId, sotalToken) => (dispatch) => {
  if (!sotalToken || !streamId || !slug) {
    return;
  }

  const headers = { 'sotalcloud-token': sotalToken };

  axios
    .get(
      global.VOD_MOVIE_INFO.STREAMS.replace('{0}', slug).replace(
        '{1}',
        streamId,
      ),
      { headers },
    )
    .then((response) =>
      dispatch(setMovies({ field: 'movieUrl', value: response.data })),
    )
    .catch((error) => dispatch(setMovies({ field: 'movieUrl', value: '404' })));
};

export const fetchMovieTrailerUrl = (slug, trailerId, sotalToken) => (
  dispatch,
) => {
  if (!sotalToken || !slug) {
    return;
  }

  const headers = { 'sotalcloud-token': sotalToken };

  if (!trailerId) {
    return dispatch(setMovies({ field: 'movieTrailerUrl', value: '404' }));
  }

  axios
    .get(
      global.VOD_MOVIE_INFO.STREAMS.replace('{0}', slug).replace(
        '{1}',
        trailerId,
      ),
      { headers },
    )
    .then((response) =>
      dispatch(setMovies({ field: 'movieTrailerUrl', value: response.data })),
    )
    .catch((error) =>
      dispatch(setMovies({ field: 'movieTrailerUrl', value: '404' })),
    );
};

export const fetchShowEpisodeUrl = ({
  showSlug,
  episodeId,
  streamId,
  sotalToken,
}) => (dispatch) => {
  if (!sotalToken) {
    return;
  }

  const headers = { 'sotalcloud-token': sotalToken };

  axios
    .get(
      global.VOD_SHOW_INFO.EPISODE_URLS.replace('{0}', showSlug)
        .replace('{1}', episodeId)
        .replace('{2}', streamId),
      {
        headers,
      },
    )
    .then((response) => {
      dispatch(setShows({ field: 'showEpisodeUrl', value: response.data }));
    })
    .catch((err) => {
      dispatch(setShows({ field: 'showEpisodeUrl', value: '404' }));
    });
};

export * from './radio';
export * from './subscription';
export * from './channel';
export * from './international';
export * from './profile';
export * from './auth';
export * from './filter';
export * from './membership';
export * from './redemption';
