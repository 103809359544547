import _keys from 'lodash/keys';
import { createSlice } from '@reduxjs/toolkit';
import { LOGIN_LOADING_STATE } from 'global';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loginData: {},
    loginStatus: LOGIN_LOADING_STATE.INIT,
    jwt_token: '',
    refresh_token: '',
    resetPasswordRequest: {},
    resetPasswordValidate: {},
    resetPasswordConfirm: {},
  },
  reducers: {
    onSetAuth: (state, action) => {
      const { data } = action.payload;
      if (!!_keys(data).length) {
        _keys(data).forEach((key) => (state[key] = data[key]));
      } else {
        state.loginData = {};
        state.loginStatus = LOGIN_LOADING_STATE.INIT;
        state.jwt_token = '';
        state.refresh_token = '';
      }
    },
  },
});

export default authSlice.reducer;

const { onSetAuth } = authSlice.actions;

export const setAuth = (data) => async (dispatch) =>
  dispatch(onSetAuth({ data }));
