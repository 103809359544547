import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'players',
  initialState: {
    infoVideo: { playing: false, hasMutedChannelsBehind: false },
  },
  reducers: {
    onSetPlayers: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onSetInfoVideoPlayer: (state, action) => {
      const { field, value } = action.payload;
      state.infoVideo[field] = value;
    },
  },
});

export default slice.reducer;

const { onSetPlayers, onSetInfoVideoPlayer } = slice.actions;

export const setPlayers = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetPlayers({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const setInfoVideoPlayer = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetInfoVideoPlayer({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
