import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Popover } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import FastRewindRoundedIcon from '@material-ui/icons/FastRewindRounded';
import FastForwardRoundedIcon from '@material-ui/icons/FastForwardRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AlbumRoundedIcon from '@material-ui/icons/AlbumRounded';

import moment from 'moment';
import { COLOR, getDuration, getEmpyFilledElement, getTimestamp } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(9),
    borderBottom: `2px solid ${COLOR.border_color}`,
  },
  channelLogoImage: {
    width: theme.spacing(8),
    height: '100%',
  },
  channelLogoGreyImage: {
    width: theme.spacing(8),
    height: '100%',
    filter: `grayscale(100%)`,
  },
  channelItemBox: {
    width: theme.spacing(40),
    height: '100%',
    background: 'rgb(17, 17, 17)',
    display: 'flex',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  channelItemLinkBox: {
    cursor: 'pointer',
    height: '100%',
    width: `calc(100% - ${theme.spacing(5)}px)`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    paddingLeft: 0,
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    textDecoration: 'none !important',
  },
  programBoxContainer: {
    position: 'relative',
    display: 'flex',
    width: `calc(100% - ${theme.spacing(40)}px)`,
    height: '100%',
  },
  guideLineBox: (props) => ({
    position: 'absolute',
    left: `calc(${50 - (props.timeOffsetValue * 33) / 2}% - ${theme.spacing(
      0.5,
    )}px)`,
    top: 0,
    zIndex: `${50 - (props.timeOffsetValue * 33) / 2 > 0 ? 999 : -1}`,
    width: theme.spacing(1),
    height: '100%',
    background: 'white',
    opacity: 0.1,
  }),
  fullProgramBox: {
    position: 'relative',
    width: `calc(100% - ${theme.spacing(40)}px)`,
    height: '100%',
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  linkItem: {
    color: 'white',
    textDecoration: 'none !important',
  },
  programBox: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    borderRight: `2px solid ${COLOR.border_color}`,
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
    '&:hover > div': {
      opacity: 1,
    },
  },
  programBoxNoBorder: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
    '&:hover > div': {
      opacity: 1,
    },
  },
  programTitleBox: {
    marginRight: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  programTimeBox: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: `0 ${theme.spacing(1)}px`,
    marginRight: theme.spacing(3),
  },
  favoriteBox: {
    padding: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
  },
  optionsBtn: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    cursor: 'pointer',
    opacity: 0,
  },
  optionsPane: {
    '& .MuiPopover-paper': {
      background: 'grey',
    },
  },
  optionsBox: {
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  optionsItemBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsItemBoxBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `0 ${theme.spacing(1)}px`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    border: `2px solid white`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsNameBox: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  watchLiveIcon: {
    transform: 'rotate(180deg)',
  },
  recordMarkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.5),
    background: 'red',
  },
  smallRecIcon: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  smallRecBox: {
    color: 'white',
    fontSize: '0.75rem',
  },
}));

export const ChannelLinear = React.memo(
  ({
    channelNumber,
    channel,
    epgData,
    isFavorite,
    onFavorite,
    isCurrent = false,
    packageSlug,
    category,
    isVidgo,
    timeOffsetValue,
    updateDvrTask,
    controlCatchUp,
    catchUpForwardBackward,
    catchUpRestart,
    catchUpEpgSlug,
    isPlaying,
    setVideoFullScreen,
    dvrList,
    availableRecording,
    forceRender,
  }) => {
    const classes = useStyles({
      timeOffsetValue,
    });

    const theme = useTheme();
    const location = useLocation();

    const [programs, setPrograms] = React.useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorProgram, setAnchorProgram] = React.useState(null);

    React.useEffect(() => {
      const start = moment
        .utc()
        .add(timeOffsetValue * 0.5, 'hours')
        .utcOffset(0)
        .add(-1.5, 'hours');
      const end = moment
        .utc()
        .add(timeOffsetValue * 0.5, 'hours')
        .utcOffset(0)
        .add(1.5, 'hours');
      let tempPrograms = [];
      if (
        channel.type &&
        channel.type.slice(0, 4) === 'live' &&
        channel.epg &&
        epgData &&
        epgData.length > 0
      ) {
        tempPrograms = epgData;
      } else if (epgData && epgData.programs && epgData.programs.length > 0) {
        epgData.programs.forEach((item) => {
          if (item.playlist) {
            item.playlist.forEach((it) => tempPrograms.push(it));
          }
        });
      }
      if (tempPrograms.length > 0) {
        const filteredPrograms = tempPrograms.filter(
          (item) =>
            (item.start_at && moment(item.start_at).isBetween(start, end)) ||
            (item.end_at && moment(item.end_at).isBetween(start, end)) ||
            (moment(item.start_at).isBefore(start) &&
              moment(item.end_at).isAfter(end)),
        );

        if (filteredPrograms.length > 0) {
          if (moment(filteredPrograms[0].start_at).isAfter(start)) {
            filteredPrograms.unshift(
              getEmpyFilledElement(
                getTimestamp(start),
                filteredPrograms[0].start_at,
                getDuration(getTimestamp(start), filteredPrograms[0].start_at),
                filteredPrograms.length,
              ),
            );
          }
          if (
            moment(
              filteredPrograms[filteredPrograms.length - 1].end_at,
            ).isBefore(end)
          ) {
            filteredPrograms.push(
              getEmpyFilledElement(
                filteredPrograms[filteredPrograms.length - 1].end_at,
                getTimestamp(end),
                getDuration(
                  filteredPrograms[filteredPrograms.length - 1].end_at,
                  getTimestamp(end),
                ),
                tempPrograms.length,
              ),
            );
          }
        }

        setPrograms(filteredPrograms);
      } else {
        setPrograms([]);
      }
    }, [channel, epgData, forceRender, timeOffsetValue]);

    const getBasePath = () =>
      location.pathname.split('/')[1] === 'embeddable-channels'
        ? '/embeddable-channels'
        : '/channels';

    const handleOptionClick = (event, program) => {
      setAnchorProgram(program);
      setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const getGeneralOptionsBar = () => {
      const isAvailableRecord =
        anchorProgram.dvr &&
        !dvrList.find((item) => item.epg.id === anchorProgram.id) &&
        moment.utc().utcOffset(0).isBefore(anchorProgram.start_at);

      const isAvailableRewind =
        (anchorProgram.catchup ||
          (dvrList.find((item) => item.epg.id === anchorProgram.id) &&
            dvrList.find((item) => item.epg.id === anchorProgram.id).status ===
              'recorded')) &&
        moment.utc().utcOffset(0).isAfter(anchorProgram.end_at);

      return (
        <Box className={classes.optionsBox}>
          {isAvailableRecord && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => {
                updateDvrTask(
                  anchorProgram.slug,
                  moment.duration(anchorProgram.duration).asMinutes(),
                  true,
                );
                handleClose();
              }}
            >
              <Box mt={0.5} mr={0.5} color="red">
                <FiberManualRecordIcon />
              </Box>
              <Box className={classes.optionsNameBox}>Record</Box>
            </Box>
          )}
          {isAvailableRewind && (
            <DvrRewindBox
              anchorProgram={anchorProgram}
              channel={channel}
              classes={classes}
              type={'start'}
              text={'Start Over'}
              controlCatchUp={controlCatchUp}
            />
          )}
          {isAvailableRewind && (
            <DvrRewindBox
              anchorProgram={anchorProgram}
              channel={channel}
              classes={classes}
              type={'rewind'}
              text={'Rewind'}
              controlCatchUp={controlCatchUp}
            />
          )}
          {!isVidgo && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => onFavorite(channel)}
            >
              <Box mt={0.5} mr={0.5} color="white">
                {isFavorite(channel) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </Box>
              <Box className={classes.optionsNameBox}>Favorite</Box>
            </Box>
          )}
        </Box>
      );
    };

    const getControlsOptionsBar = () => (
      <Box className={classes.optionsBox}>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            setAnchorEl(null);
            setAnchorProgram(null);
            controlCatchUp(channel, null, true);
          }}
        >
          <Box mb={1} mr={0.5} color="white" className={classes.watchLiveIcon}>
            <DoubleArrowIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Watch Live</Box>
        </Box>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(false);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastRewindRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Rewind</Box>
        </Box>
        {anchorProgram.slug === catchUpEpgSlug && !isPlaying ? (
          <Box
            className={classes.optionsItemBoxBorder}
            onClick={() =>
              controlCatchUp(
                channel,
                anchorProgram.slug,
                true,
                !anchorProgram.catchup,
              )
            }
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PlayArrowRoundedIcon />
            </Box>
            <Box className={classes.optionsNameBox}>Play</Box>
          </Box>
        ) : (
          <Box
            className={classes.optionsItemBoxBorder}
            onClick={() =>
              controlCatchUp(
                channel,
                anchorProgram.slug,
                false,
                !anchorProgram.catchup,
              )
            }
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PauseRoundedIcon />
            </Box>
            <Box className={classes.optionsNameBox}>Pause</Box>
          </Box>
        )}
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(true);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastForwardRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Forward</Box>
        </Box>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpRestart();
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.blue_light}>
            <ReplayRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Start Over</Box>
        </Box>
      </Box>
    );

    const getOptionsBox = () => (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.optionsPane}
      >
        {anchorProgram.slug === catchUpEpgSlug
          ? getControlsOptionsBar()
          : getGeneralOptionsBar()}
      </Popover>
    );

    const getEpgBox = () => {
      const start = moment
        .utc()
        .add(timeOffsetValue * 0.5, 'hours')
        .add(-1.5, 'hours');
      const end = moment
        .utc()
        .add(timeOffsetValue * 0.5, 'hours')
        .add(1.5, 'hours');

      let sumPercent = 0;

      return (
        <Box className={classes.programBoxContainer}>
          {programs.mapWithKey((program, index) => {
            const isAvailableRecord =
              program.dvr &&
              !dvrList.find((item) => item.epg.id === program.id) &&
              moment.utc().utcOffset(0).isBefore(program.start_at);

            const isAvailableRewind =
              (program.catchup ||
                (dvrList.find((item) => item.epg.id === program.id) &&
                  dvrList.find((item) => item.epg.id === program.id).status ===
                    'recorded')) &&
              moment.utc().utcOffset(0).isAfter(program.end_at);

            const programDrawStart = moment(program.start_at).isBefore(start)
              ? start
              : moment(program.start_at);
            const programDrawEnd = moment(program.end_at).isAfter(end)
              ? end
              : moment(program.end_at);

            const widthPercent =
              (moment
                .duration(getDuration(programDrawStart, programDrawEnd))
                .asMilliseconds() /
                moment.duration(getDuration(start, end)).asMilliseconds()) *
              100;

            if (index !== programs.length - 1) {
              sumPercent += widthPercent;
            }

            return (
              program &&
              widthPercent > 0 && (
                <Box
                  width={`${
                    index !== programs.length - 1
                      ? widthPercent
                      : 100 - sumPercent
                  }%`}
                  className={
                    index === programs.length - 1
                      ? classes.programBoxNoBorder
                      : classes.programBox
                  }
                  key={program.key || index}
                  onDoubleClick={() => {
                    if (isCurrent) {
                      setVideoFullScreen();
                    }
                  }}
                  style={{
                    border:
                      anchorEl &&
                      anchorProgram === program &&
                      `2px solid white`,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={
                      channel.type === 'linear/stream' &&
                      !moment
                        .utc()
                        .utcOffset(0)
                        .isBetween(program.start_at, program.end_at)
                        ? `${getBasePath()}/${packageSlug}/${category}/${
                            channel.slug
                          }/ondemand/${program.uuid}/`
                        : `${getBasePath()}/${packageSlug}/${category}/${
                            channel.slug
                          }/${
                            channel.type === 'linear/stream'
                              ? ''
                              : `${epgData.indexOf(program.uuid)}/`
                          }`
                    }
                    className={classes.linkItem}
                  >
                    <Box p={(1, 1, 1, 1)} className={classes.programTitleBox}>
                      {program.name}
                    </Box>
                    <Box p={(1, 1, 1, 1)} className={classes.programTimeBox}>
                      {program.start_at &&
                        moment(program.start_at)
                          .add(0, 'milliseconds')
                          .format('hh:mm a')}
                      {availableRecording &&
                        dvrList.find((item) => item.epg.id === program.id) &&
                        dvrList.find((item) => item.epg.id === program.id)
                          .status !== 'error' && (
                          <Box className={classes.recordMarkBox}>
                            <Box mr={0.5} color="white">
                              <FiberManualRecordIcon
                                className={classes.smallRecIcon}
                              />
                            </Box>
                            <Box className={classes.smallRecBox}>REC</Box>
                          </Box>
                        )}
                      {availableRecording &&
                        (program.catchup ||
                          (dvrList.find((item) => item.epg.id === program.id) &&
                            dvrList.find((item) => item.epg.id === program.id)
                              .status !== 'error')) &&
                        program.slug === catchUpEpgSlug && (
                          <Box className={classes.recordMarkBox}>
                            <Box mr={0.5} color="white">
                              <AlbumRoundedIcon
                                className={classes.smallRecIcon}
                              />
                            </Box>
                            <Box className={classes.smallRecBox}>
                              WATCHING NOW
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Link>
                  {program.catchup && (
                    <Box
                      position="absolute"
                      color="white"
                      right={theme.spacing(2)}
                      bottom={theme.spacing(2)}
                    >
                      <ReplayRoundedIcon />
                    </Box>
                  )}
                  {availableRecording &&
                    (isAvailableRewind || isAvailableRecord || !isVidgo) &&
                    channel.type &&
                    channel.type.slice(0, 4) === 'live' && (
                      <Box
                        className={classes.optionsBtn}
                        onClick={(event) => handleOptionClick(event, program)}
                      >
                        <MoreVertIcon />
                      </Box>
                    )}
                </Box>
              )
            );
          })}
          {anchorProgram && getOptionsBox()}
          <Box className={classes.guideLineBox}></Box>
        </Box>
      );
    };

    return (
      <Box className={classes.container}>
        <Box className={classes.channelItemBox}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              background: isCurrent ? window.BRAND.primary_color : 'inherit',
              minWidth: `${theme.spacing(5)}px`,
            }}
          >
            <Box textAlign="center">
              {channelNumber && <Box>{channelNumber}</Box>}
              {!isVidgo && (
                <Box
                  className={classes.favoriteBox}
                  onClick={() => onFavorite(channel)}
                >
                  {isFavorite(channel) ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Link
            component={RouterLink}
            to={`/channels/${packageSlug}/${category}/${channel.slug}/`}
            className={classes.channelItemLinkBox}
          >
            <img
              src={channel.logo || channel.logo_url}
              className={
                isCurrent
                  ? classes.channelLogoImage
                  : classes.channelLogoGreyImage
              }
              alt="channel_logo"
            />
            <Box p={(1, 1, 1, 1)} className={classes.programTitleBox}>
              {channel.name}
            </Box>
          </Link>
        </Box>
        {programs && programs.length > 0 && epgData ? (
          getEpgBox()
        ) : (
          <Box className={classes.fullProgramBox} width={1}>
            <Link
              component={RouterLink}
              to={`/channels/${packageSlug}/${category}/${channel.slug}/`}
              className={classes.linkItem}
            >
              <Box p={(1, 1, 1, 1)} className={classes.programTitleBox}>
                {channel.description || channel.name}
              </Box>
              <Box px={1} className={classes.programTitleBox}>
                Live
              </Box>
              <Box className={classes.guideLineBox}></Box>
            </Link>
          </Box>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.channelNumber === nextProps.channelNumber &&
    prevProps.channel === nextProps.channel &&
    prevProps.epgData === nextProps.epgData &&
    prevProps.isFavorite === nextProps.isFavorite &&
    prevProps.isCurrent === nextProps.isCurrent &&
    prevProps.isVidgo === nextProps.isVidgo &&
    prevProps.timeOffsetValue === nextProps.timeOffsetValue &&
    prevProps.isPlaying === nextProps.isPlaying &&
    prevProps.dvrList === nextProps.dvrList &&
    prevProps.availableRecording === nextProps.availableRecording,
);

const DvrRewindBox = ({
  type,
  text,
  classes,
  controlCatchUp,
  channel,
  anchorProgram,
}) => {
  if (!type) {
    return;
  }

  return (
    <Box
      className={classes.optionsItemBox}
      onClick={() => {
        controlCatchUp(
          channel,
          anchorProgram.slug,
          true,
          !anchorProgram.catchup,
        );
      }}
    >
      <Box
        mt={0.5}
        mr={0.5}
        color={type === 'start' ? COLOR.blue_light : COLOR.green_light}
      >
        {type === 'start' ? <ReplayRoundedIcon /> : <FastRewindRoundedIcon />}
      </Box>
      <Box className={classes.optionsNameBox}>{text}</Box>
    </Box>
  );
};
