import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    onSetLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
  },
});

export default slice.reducer;

const { onSetLoading } = slice.actions;
export const setLoading = ({ isLoading }) => async (dispatch) => {
  try {
    dispatch(onSetLoading({ isLoading }));
  } catch (e) {
    return console.error(e.message);
  }
};
