import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { COLOR } from 'global';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '15px',
    color: COLOR.t_white_light,
    backgroundColor: COLOR.b_black_light,
  },
}));

export const XCard = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)}>{children || 'Card'}</Card>
  );
};
