import React from 'react';
import Slider from 'react-slick';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  slider: {
    '& .slick-arrow': {
      color: 'rgba(255,255,255,0.7) !important',
      '&:hover': { color: 'rgba(255,255,255,1) !important' },
    },
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 9,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  swipeToSlide: false,
  prevArrow: <ChevronLeftIcon />,
  nextArrow: <ChevronRightIcon />,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SliderList = ({ children }) => {
  const classes = useStyles();

  return (
    <Slider {...settings} adaptiveHeight className={classes.slider}>
      {children}
    </Slider>
  );
};
