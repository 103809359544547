import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { truncateByLength, WEBLINK, openPopUp } from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  description: {
    fontSize: theme.typography.subtitle2.fontSize,
    textAlign: 'start',
  },
  name: {
    textTransform: 'uppercase',
    fontSize: theme.typography.subtitle2.fontSize,
  },
  price: {
    color: 'gray',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.subtitle2.fontSize,
  },
  linkItem: {
    textDecoration: 'none !important',
    marginBottom: theme.spacing(2),
  },
  sourceContainer: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  linkBoxItem: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  sourceImage: {
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 3,
    boxShadow: '0px 0px 2px rgba(255,255,255,0.5)',
    overflow: 'hidden',
    '& img': {
      margin: 0,
      width: 52,
      height: 52,
      marginTop: '-1px',
      marginLeft: '-1px',
    },
  },
}));

const MAX_LENGTH = 125;

export const LinksBox = React.memo(({ itemInfo }) => {
  const classes = useStyles();
  const [isExtended, setIsExtended] = React.useState(false);
  const [sources, setSources] = React.useState({ free: [], premium: [] });

  React.useEffect(() => {
    const src = itemInfo.sources;
    const newSources = { ...sources };
    newSources.free = src.free;

    if (src.subscription.length > 0)
      newSources.premium = newSources.premium.concat(src.subscription);
    if (src.authenticated.length > 0)
      newSources.premium = newSources.premium.concat(src.authenticated);
    if (src.paid.length > 0)
      newSources.premium = newSources.premium.concat(src.paid);

    setSources(newSources);
  }, [itemInfo]);

  return (
    <Box className={classes.container}>
      {itemInfo.overview && (
        <Box className={classes.description}>
          <>
            {itemInfo.overview.length < MAX_LENGTH || isExtended
              ? itemInfo.overview
              : truncateByLength(itemInfo.overview, MAX_LENGTH, '...')}
            {itemInfo.overview.length > MAX_LENGTH && (
              <Link
                className={classes.linkItem}
                component={RouterLink}
                to={'#'}
                onClick={() => setIsExtended((isExtended) => !isExtended)}
              >
                {isExtended ? ' Read Less' : ' Read More'}
              </Link>
            )}
          </>
        </Box>
      )}
      {Object.entries(sources).map(
        ([keyName, value], index) =>
          value &&
          value.length > 0 && (
            // eslint-disable-next-line
            <Box key={index} paddingTop={1}>
              <Box className={classes.name}>
                {keyName === 'paid' ? 'BUY' : keyName}
              </Box>
              <Box className={classes.sourceContainer}>
                {value.mapWithKey((source) => (
                  <Box
                    key={source.key}
                    className={classes.linkBoxItem}
                    onClick={(e) => openPopUp(WEBLINK.PATH + source.id, e)}
                  >
                    <Box className={classes.sourceImage}>
                      <img
                        src={source.source.image}
                        alt={source.source.name}
                        width="100%"
                      />
                    </Box>
                    {source.formats && source.formats.length > 0 && (
                      <Box className={classes.price}>
                        ${source.formats[0].price}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ),
      )}
    </Box>
  );
});
