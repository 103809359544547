import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Input,
  Button,
  Link,
  Hidden,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { staticUrl } from 'global';
import { fetchStart } from 'apis';

export const StartPage = (props) => {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();

  const onActivate = () => {
    setLoading(true);
    if (!loading) {
      fetchStart(code).then(
        (response) => {
          if (response.data.next_url) {
            history.push(`/register/${code}/`);
          }
          setLoading(false);
        },
        (error) => {
          setError(true);
          setErrorMessage(error.response.data.message[0]);
          setLoading(false);
        },
      );
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <img
          src={window.BRAND.logo_image_dark}
          alt={window.BRAND.site_name}
          width={185}
        />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={6}>
              <Box textAlign="center">
                <img
                  src={staticUrl('members/img/packingslip-mock-5.png')}
                  alt="Registration Card"
                  className={classes.img}
                />
              </Box>
            </Grid>
          </Hidden>
          <Grid item sm={12} md={6} lg={5}>
            <Box className={classes.cardBox} py={3} px={7}>
              <Box>
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.title}
                >
                  ACTIVATE
                </Typography>
              </Box>
              <Box fontSize="1.4rem" color="white" textAlign="center">
                Scratch off your {window.BRAND.site_name} scratch card and enter
                your card code below to activate your account.
              </Box>
              {error && (
                <Box mt={3}>
                  <Alert severity="error" onClose={() => setError(false)}>
                    * {errorMessage}
                  </Alert>
                </Box>
              )}
              <Box mt={6}>
                <Input
                  className={classes.inputCode}
                  disableUnderline
                  disabled={loading}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code Here"
                />
              </Box>
              <Box textAlign="center" py={2} px={1}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={onActivate}
                  className={classes.buttonActivate}
                >
                  Activate
                </Button>
              </Box>
              <Box mb={2} textAlign="center" color="white" fontSize="1.2rem">
                Need help?{' '}
                <Link
                  className={classes.linkWhite}
                  target="_blank"
                  href={window.BRAND.support_url}
                >
                  Click here
                </Link>
              </Box>
            </Box>

            <Hidden mdUp>
              <Box textAlign="center">
                <img
                  src={staticUrl(
                    'members/img/mobile-packingslip-activate-1.png',
                  )}
                  alt="Registration Card"
                  className={classes.img}
                />
              </Box>
            </Hidden>

            <Box mt={3} textAlign="center" fontSize="1.3rem" mb={3}>
              <Box fontWeight="bold" fontSize="1.6rem" mb={1}>
                You're Just A Few Steps Away From ...
              </Box>
              700,000 TV episode 100,000+ movies, 1,500+ free channels + Pay Per
              View and a FREE trail of our STV14 SelectTV Plus premium channels
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.footer} py={3}>
        <Box className={classes.footerLinksRow} mb={3}>
          <Link className={classes.footerLinksRowItem} href="/login/">
            Login{' '}
          </Link>
          <Link
            className={classes.footerLinksRowItem}
            target="_blank"
            href={window.BRAND.support_url}
          >
            Support
          </Link>
          <Link
            className={classes.footerLinksRowItem}
            target="_blank"
            href="https://freecast.com"
          >
            Company{' '}
          </Link>
          <Link
            className={classes.footerLinksRowItem}
            target="_blank"
            href="https://freecast.com/#contact-freecast"
          >
            Contact Us
          </Link>
          <Link className={classes.footerLinksRowItem} href="/members/tos/">
            Terms/Policies{' '}
          </Link>
        </Box>
        <Box m={3}>Copyright © {new Date().getFullYear()} FreeCast</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonActivate: {
    backgroundColor: '#252525',
    border: '2px solid white',
    borderRadius: theme.spacing(4),
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'white',
      color: '#252525',
    },
  },
  cardBox: {
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    margin: `0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      borderRadius: 0,
    },
  },
  container: {
    backgroundColor: 'white',
    minHeight: '100%',
    position: 'relative',
    paddingBottom: theme.spacing(20),
  },
  footer: {
    position: 'absolute',
    backgroundColor: '#222',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    bottom: 0,
  },
  footerLinksRow: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  footerLinksRowItem: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1.1rem',
  },
  inputCode: {
    backgroundColor: 'white',
    width: '100%',
    color: '#252525',
    border: 'none',
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: theme.typography.h6.fontSize,
  },
  img: {
    maxWidth: '100%',
  },
  header: {
    padding: `${theme.spacing(1)}px 2%`,
    borderBottom: `1px solid gray`,
  },
  linkWhite: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  mainContainer: {
    padding: `0 2%`,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: { color: 'white', fontWeight: 'bold' },
}));
