import React from 'react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { COLOR } from 'global';
import { LoadingSpinner } from '../LoadingSpinner';
import LazyImage from '../LazyImage';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  menuIcon: {
    width: '100%',
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperScrollPaper': {
      backgroundColor: `${COLOR.b_black_mlight}`,
      maxWidth: `80%`,
    },
  },
  dialogImage: {
    width: '100%',
    height: '100%',
    borderRadius: 20,
    cursor: 'pointer',
  },
  dialogImageNormal: {
    boxShadow: 'none',
  },
  dialogImageFavorite: {
    boxShadow: 'rgb(0, 156, 254) 0px 0px 8px 5px',
  },
  closeBox: {
    height: theme.spacing(5),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  dialogClose: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  name: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },
  categoryBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  categoryItemBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
  },
  categoryItemBoxNormal: {
    color: 'white',
  },
  categoryItemBoxActive: {
    color: theme.palette.primary.main,
  },
}));

export const AddDialog = ({
  listData,
  onImageClick,
  isFavorite,
  dialogTitle,
  openModal,
  setOpenModal,
  categoryList,
  categorySlug,
  onFilter,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Box fontSize="h4.fontSize" color="white" justifyContent="center">
          <Box className={classes.closeBox}>
            <IconButton
              className={classes.dialogClose}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Box component={ClearIcon} className={classes.menuIcon} />
            </IconButton>
          </Box>
          <Box textAlign="center">{dialogTitle}</Box>
          {categoryList && (
            <Box className={classes.categoryBox}>
              {categoryList.mapWithKey((item) => (
                <Box
                  key={item.key}
                  className={clsx(classes.categoryItemBox, {
                    [classes.categoryItemBoxNormal]: item.slug !== categorySlug,
                    [classes.categoryItemBoxActive]: item.slug === categorySlug,
                  })}
                  onClick={() => onFilter(item.slug)}
                >
                  {item.name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          {listData && listData.length > 0 ? (
            <Grid container spacing={4}>
              {listData.map((item) => (
                <Grid item key={nanoid()} lg={2} md={3} sm={4} xs={6}>
                  <Box
                    className={clsx(classes.dialogImage, {
                      [classes.dialogImageFavorite]: isFavorite(item),
                      [classes.dialogImageNormal]: !isFavorite(item),
                    })}
                    onClick={() => {
                      onImageClick(item);
                    }}
                  >
                    <LazyImage
                      width="100%"
                      height="100%"
                      src={item.image || item.logo}
                      alt={item.name}
                    />
                    <Box className={classes.name}>{item.name}</Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              width={1}
              justifyContent="center"
              alignItems="center"
              height="40vh"
              position="relative"
            >
              <LoadingSpinner />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
