import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { logout } from 'apis';
import { CustomDialog } from './CustomDialog';

export const LoginRequiredPopup = () => {
  const [timeLeft, setTimeLeft] = React.useState(5);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (timeLeft === 0) {
      console.log('TIME LEFT IS 0');
      dispatch(logout());
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, dispatch]);

  return (
    <CustomDialog
      open
      title={`After updating your password, you will be required to log into ${window.BRAND.site_name} with your new password.`}
      maxWidth={'sm'}
      handleClose={false}
      button={''}
    >
      You will be automatically logout in{' '}
      <Typography
        color="primary"
        variant="caption"
        style={{ fontSize: '18px' }}
      >
        {timeLeft}
      </Typography>{' '}
      seconds.
    </CustomDialog>
  );
};
