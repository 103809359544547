import React from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { openPopUp } from 'global';

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
  },
  subscriptionBox: {
    // width: `calc(100% - ${theme.spacing(15)}px)`,
    maxHeight: theme.spacing(7),
    width: 616,
    '@media screen and (max-width: 1919px)': { width: 560 },
    '@media screen and (max-width: 1800px)': { width: 504 },
    '@media screen and (max-width: 1660px)': { width: 448 },
    '@media screen and (max-width: 1520px)': { width: 392 },
    '@media screen and (max-width: 1390px)': { width: 336 },
    '@media screen and (max-width: 1110px)': { width: 'calc(100% - 70px)' },
  },
  maskBox: {
    borderRadius: theme.spacing(1),
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  subscriptionImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    boxShadow: '2px 2px 3px 1px grey',
    borderRadius: theme.spacing(1),
  },
  addMoreContainer: {
    boxShadow: '0px 2px 8px 2px #7e7e7e',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  addMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    boxShadow: '2px 2px 3px 1px grey',
    borderRadius: theme.spacing(1),
    fontSize: `${theme.spacing(1)}px`,
    color: 'white',
    '&:hover': {
      textDecoration: 'none !important',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  subscriptionItem: {
    '& .subscription-hover': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
      '&::before': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: 'rgba(0,0,0,0.7)',
        top: 0,
        left: 0,
        borderRadius: 'inherit',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '120%',
        background: 'rgba(0,0,0,0.7)',
        top: '5px',
        left: '47px',
        borderTop: '2px solid #fff',
        transform: 'rotate(135deg)',
        transformOrigin: '0% 0%',
        zIndex: 11000,
      },
    },
    '&:hover': {
      '& .subscription-hover': {
        opacity: 1,
        zIndex: 5000,
      },
    },
  },
  try: {
    display: 'block',
    position: 'absolute',
    top: '11px',
    left: '2px',
    fontSize: '10px',
    color: '#fff',
    fontWeight: 700,
  },
  buy: {
    display: 'block',
    position: 'absolute',
    bottom: '7px',
    right: '2px',
    fontSize: '10px',
    color: '#fff',
    fontWeight: 700,
  },
}));

export const SubscriptionList = React.memo(
  ({ subscriptionList }) => {
    const classes = useStyles();
    const slider = React.useRef();
    const [
      filteredSubscriptionList,
      setFilteredSubscriptionList,
    ] = React.useState([]);

    React.useEffect(() => {
      let filteredSubscriptionList = [];
      filteredSubscriptionList = subscriptionList.filter(
        (item) =>
          item.active &&
          item.subscribed &&
          !item.display_on_subscription_manager_only,
      );
      setFilteredSubscriptionList(filteredSubscriptionList);
    }, [subscriptionList]);

    const prevousSubscription = () => slider.current.slickPrev();
    const nextSubscription = () => slider.current.slickNext();

    const AddMoreButton = (_, index) => (
      <Box p={0.5} key={`subs_${index}`}>
        <Link
          component={RouterLink}
          className={classes.addMoreButton}
          to={'/myapps/'}
        >
          <Box textAlign="center">
            <AddIcon />
            <Box>Add More</Box>
          </Box>
        </Link>
      </Box>
    );

    const renderSlider = () => {
      const maxShowingCount = 11;

      const settings = {
        className: 'center',
        arrows: false,
        infinite: true,
        variableWidth: true,
        swipeToSlide: true,
        speed: 500,
        adaptiveHeight: true,
      };

      const renderAddMore = (max) =>
        // eslint-disable-next-line
        Array.apply(null, Array(max - filteredSubscriptionList.length)).map(
          AddMoreButton,
        );

      return (
        <Slider ref={slider} {...settings}>
          {filteredSubscriptionList.mapWithKey((sub) => (
            <Box key={sub.key}>
              <Link
                component={RouterLink}
                to={
                  sub.display_mode === 'normal_in_app'
                    ? sub.subscribed
                      ? `/guide/network/info/${sub.slug}/`
                      : `/subscriptions/${sub.slug}/`
                    : '#'
                }
                onClick={(event) => {
                  if (sub.display_mode !== 'normal_in_app') {
                    openPopUp(sub.platform_path, event);
                  }
                }}
              >
                <Box padding={0.5}>
                  <Box position="relative" className={classes.subscriptionItem}>
                    <img
                      alt={sub.slug}
                      src={sub.image}
                      className={classes.subscriptionImage}
                    />
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
          {maxShowingCount > filteredSubscriptionList.length &&
            renderAddMore(maxShowingCount)}
          {maxShowingCount <= filteredSubscriptionList.length &&
            AddMoreButton(null, 0)}
        </Slider>
      );
    };

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box className={classes.arrowIcon} onClick={prevousSubscription}>
          <ChevronLeftIcon fontSize="large" />
        </Box>
        <Box className={classes.subscriptionBox}>{renderSlider()}</Box>
        <Box className={classes.arrowIcon} onClick={nextSubscription}>
          <ChevronRightIcon fontSize="large" />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.subscriptionList === nextProps.subscriptionList,
);
