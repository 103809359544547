import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const Loading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress />
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Loading;
