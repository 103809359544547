import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDialog, UpgradeButton } from 'components';
import { fetchShowInfo } from 'apis';
import { EpisodeLinkContainer } from './EpisodeLinkContainer';
import { EpisodePlayer } from './EpisodePlayer';

const useStyles = makeStyles((theme) => ({
  episodeElement: {
    borderBottom: '1px solid #7e7e7e !important',
    padding: `${theme.spacing(2)}px 0px`,
  },
  episodeContainer: {
    height: theme.spacing(3),
    fontSize: theme.typography.body1.fontSize,
    fontFamily: 'century-gothic, sans-serif',
    fontWeight: 'bold',
  },
  episodeName: {
    height: theme.spacing(3),
    fontWeight: '200',
    color: '#cecece',
    overflow: 'hidden',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  episodeButton: {
    color: 'white',
    backgroundColor: '#1C6FEE',
    mariginTop: theme.spacing(2),
    width: theme.spacing(27),
    height: theme.spacing(4),
    '&:hover': {
      opacity: '0.8',
      backgroundColor: '#1C6FAE',
    },
  },
  episodeText: {},
  button: {
    display: 'inline-block',
    minWidth: 'max-content',
    verticalAlign: 'top',
  },
  playButton: {
    fontWeight: 'bold',
    minWidth: '140px !important',
    fontSize: '1rem',
    padding: `${theme.spacing(0.25)}px`,
  },
  lastNoBorder: {
    '&:last-child': { borderBottom: 'none !important' },
  },
  buttonContainer: {
    height: theme.spacing(4),
    display: 'flex',
    marginRight: '-4px',
  },
  buttonYellow: {
    backgroundColor: '#dfae2e',
    fontColor: 'black',
    width: 'max-content',
    display: 'inline-block',
    '&:hover': {
      opacity: '0.8',
      background: '#dfae2e',
    },
  },
  buttonGreen: {
    background: '#477410',
    color: '#fff',
    minWidth: '70px',
    '&:hover': {
      background: '#477410',
      opacity: '0.8',
    },
  },
  buttonOutlined: {
    border: '1px solid white',
    color: 'white',
    marginRight: theme.spacing(1),
  },
  modalPaper: {
    borderRadius: theme.spacing(2),
  },
  modalContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px !important`,
  },
}));

export const EpisodesContainer = ({
  episodes,
  type,
  show,
  seasonNumber,
  token,
  sotalToken,
}) => {
  const [isMoreLoading, setIsMoreLoading] = React.useState(false);
  const [end, setEnd] = React.useState(6);
  const [modalOpenDescription, setModalOpenDescription] = React.useState(false);
  const [modalOpenWatchNow, setModalOpenWatchNow] = React.useState(false);
  const [openPlayerDialog, setOpenPlayerDialog] = React.useState(false);
  const [currentEpisode, setCurrentEpisode] = React.useState(false);
  const [serviceData, setServiceData] = React.useState(false);
  const classes = useStyles();
  const playText = !sotalToken ? 'Watch Now' : 'Play';

  const dispatch = useDispatch();

  const getEpisodes = () =>
    [...episodes].sort((a, b) => b.number - a.number).slice(0, end);

  const showModal = (data, modal = 'detail') => {
    setCurrentEpisode(data);
    if (modal === 'detail') {
      setModalOpenDescription(true);
    } else if (data?.media?.streams?.length > 0) {
      setOpenPlayerDialog(true);
    } else {
      setModalOpenWatchNow(true);
    }
  };

  const toggleLoad = (isMore) => {
    setIsMoreLoading(true);
    setTimeout(() => {
      setIsMoreLoading(false);
      setEnd(isMore ? end + 6 : 6);
    }, 200);
  };

  const onCloseClick = () => {
    setModalOpenDescription(false);
    setModalOpenWatchNow(false);
    setServiceData(false);
  };

  const renderModalDescription = () => {
    const title = (
      <Box component="span" style={{ color: 'white' }} mt={2} display="block">
        {`${show.name} - Season ${seasonNumber}, Episode ${currentEpisode.number}`}
      </Box>
    );
    const buttons = (
      <Box p={1} pb={3}>
        {show.flags?.status === 'upgrade_required' ? (
          <UpgradeButton
            onUpgraded={() => {
              dispatch(fetchShowInfo(show.slug, token, sotalToken));
              setOpenPlayerDialog(true);
            }}
          />
        ) : (
          <Button
            variant="contained"
            className={clsx(classes.button, classes.playButton, {
              [classes.buttonYellow]: !sotalToken,
              [classes.buttonGreen]: !!sotalToken,
            })}
            onClick={() => {
              setModalOpenDescription(false);
              if (currentEpisode?.media?.streams?.length > 0) {
                setOpenPlayerDialog(true);
              } else {
                setModalOpenWatchNow(true);
              }
            }}
          >
            {playText}
          </Button>
        )}
      </Box>
    );
    return (
      <CustomDialog
        title={title}
        handleClose={onCloseClick}
        open={modalOpenDescription}
        button={buttons}
        classes={{ paper: classes.modalPaper, content: classes.modalContainer }}
      >
        <Box style={{ fontSize: '1.05rem' }} textAlign="center">
          {currentEpisode.overview || currentEpisode.description}
        </Box>
      </CustomDialog>
    );
  };

  const renderModalWatchNow = () => {
    const title = (
      <Box component="span" style={{ color: 'white' }} mt={2} display="block">
        {`${show.name} - Season ${seasonNumber}, Episode ${currentEpisode.number}`}
      </Box>
    );

    return (
      <CustomDialog
        title={title}
        handleClose={onCloseClick}
        open={modalOpenWatchNow}
        classes={{ paper: classes.modalPaper }}
      >
        <EpisodeLinkContainer
          showSlug={show.slug}
          type={type}
          seasonNumber={seasonNumber}
          episode={currentEpisode}
          serviceData={serviceData}
          onClose={onCloseClick}
        />
      </CustomDialog>
    );
  };

  const renderModalPlayer = () =>
    openPlayerDialog && (
      <EpisodePlayer
        name={currentEpisode.name}
        seasonNumber={seasonNumber}
        episodeNumber={currentEpisode.number}
        openPlayerDialog={openPlayerDialog}
        setOpenPlayerDialog={setOpenPlayerDialog}
      />
    );

  const renderEpisode = (data, key) => (
    <Box
      pb={1}
      pt={1}
      key={`episode_${key}`}
      className={`${classes.episodeElement} ${
        episodes.length <= 6 ? classes.lastNoBorder : ''
      }`}
    >
      <Box className="episode" display="flex">
        <Box width="100%" className={classes.episodeContainer}>
          {`S${seasonNumber} E${data.number} -${' '}`}
          <span className={classes.episodeName}>{data.name}</span>
        </Box>
        <Box flexShrink={1} mr={1} className={classes.buttonContainer}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => showModal(data, 'detail')}
            className={clsx(classes.button, classes.buttonOutlined)}
          >
            Episode Details
          </Button>
          {show.flags?.status === 'upgrade_required' ? (
            <UpgradeButton
              onUpgraded={() => {
                setCurrentEpisode(data);
                dispatch(fetchShowInfo(show.slug, token, sotalToken));
                setOpenPlayerDialog(true);
              }}
            />
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={() => showModal(data, 'watch now')}
              className={clsx(classes.button, {
                [classes.buttonYellow]: !sotalToken,
                [classes.buttonGreen]: !!sotalToken,
              })}
            >
              {playText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );

  return episodes ? (
    <Box>
      {renderModalDescription()}
      {renderModalWatchNow()}
      {renderModalPlayer()}
      {getEpisodes().map((ep, key) => renderEpisode(ep, key))}
      {episodes.length > 6 && (
        <Box
          display="flex"
          justifyContent="flex-end"
          pt={1}
          style={{ marginTop: '20px' }}
        >
          <Button
            className={classes.episodeButton}
            variant="contained"
            disabled={episodes.length <= 6}
            onClick={() => toggleLoad(episodes.length > end)}
          >
            {isMoreLoading
              ? 'Loading...'
              : episodes.length > end
              ? 'See More Episodes'
              : 'See Less Episodes'}
          </Button>
        </Box>
      )}
    </Box>
  ) : (
    ''
  );
};
