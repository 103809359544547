import React from 'react';
import _head from 'lodash/head';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, makeStyles, Hidden } from '@material-ui/core';
import { staticUrl } from 'global';
import { fetchMemberProfile } from 'apis';
import is from 'is_js';

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'white',
    '&:hover div': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& a': { margin: `0 auto` },
    '@media screen and (max-width: 959px)': {
      paddingTop: theme.spacing(1),
    },
  },
  profileLogo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  textName: {
    whiteSpace: 'nowrap',
    textDecoration: 'none !important',
    display: 'inline-flex',
    overflow: 'hidden',
    textOverflow: 'clip',
    paddingLeft: theme.spacing(1),
    height: '100%',
    alignItems: 'center',
  },
  menuTextNormal: {
    color: 'white',
    textDecoration: 'none !important',
    display: 'inline-flex',
    '@media (max-width: 959px)': {
      position: 'absolute',
      right: '20px',
    },
  },
}));

export const UserProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [token, profile] = useSelector(({ auth, profile }) => [
    auth.jwt_token,
    profile.profile,
  ]);

  React.useEffect(() => {
    if (is.empty(profile) && token) {
      dispatch(fetchMemberProfile(token));
    }
  }, [dispatch, token]);

  const renderProfileName = () => {
    const { user = { first_name: '', last_name: '' } } = profile;
    const [firstName, lastName] = [
      _head(user.first_name.split(' ')),
      user.last_name.charAt(0),
    ];
    return (
      <Box>
        <Box className={classes.textName}>{firstName}</Box> {lastName}.
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="right"
      justifyContent="space-between"
      className={classes.container}
      width={1}
    >
      <Link
        component={RouterLink}
        to={'/members/profile/'}
        className={classes.menuTextNormal}
      >
        <img
          className={classes.profileLogo}
          alt="profile"
          src={staticUrl('images/myaccount-icon-header.png')}
        />
        <Hidden mdDown>{renderProfileName()}</Hidden>
      </Link>
    </Box>
  );
};
