import axios from 'axios';
import { SUBSCRIPTION } from 'global';
import {
  setSubscription,
  setToggleSubscription,
} from 'redux/reducers/subscription';

export const fetchSubscriptionList = () => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };

  return axios
    .get(SUBSCRIPTION.SUBSCRIPTION_LIST, { headers })
    .then((response) =>
      dispatch(
        setSubscription({
          field: 'subscriptionList',
          value: response.data.results,
        }),
      ),
    );
};

export const fetchSubscriptionHeader = () => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };

  return axios
    .get(SUBSCRIPTION.SUBSCRIPTION_HEADER, { headers })
    .then((response) =>
      dispatch(
        setSubscription({
          field: 'subscriptionHeader',
          value: response.data,
        }),
      ),
    );
};

export const fetchSubscriptionManagerPage = () => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };
  return axios
    .get(SUBSCRIPTION.SUBSCRIPTION_MANAGER_PAGE, { headers })
    .then((response) =>
      dispatch(
        setSubscription({
          field: 'subscriptionManagerPage',
          value: response.data,
        }),
      ),
    );
};

export const fetchServiceDetail = (serviceSlug) => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };
  const url = SUBSCRIPTION.SUBSCRIPTION_DETAIL.replace('{0}', serviceSlug);

  return axios
    .get(url, { headers })
    .then((response) =>
      dispatch(setSubscription({ field: 'service', value: response.data })),
    )
    .catch(() => dispatch(setSubscription({ field: 'service', value: '404' })));
};

export const toggleSubscription = (item, isDetail) => {
  const url = item.subscribed
    ? SUBSCRIPTION.REMOVE_SUBSCRIPTION.replace('{0}', item.slug)
    : SUBSCRIPTION.ADD_SUBSCRIPTION.replace('{0}', item.slug);

  return (dispatch, getState) => {
    const { auth } = getState();
    const headers = { Authorization: `Bearer ${auth.jwt_token}` };

    return axios.get(url, { headers }).then((response) => {
      if (response.data.status === 'ok') {
        const data = {
          slug: item.slug,
          subscribed: !item.subscribed,
          isDetail: isDetail || false,
        };
        return dispatch(setToggleSubscription(data));
      }
    });
  };
};

export const getUserSyncedTitles = () => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };
  return axios
    .get(SUBSCRIPTION.USER_SYNCED_TITLES, { headers })
    .then((response) =>
      dispatch(
        setSubscription({ field: 'userSyncedTitles', value: response.data }),
      ),
    );
};

export const addAllSubscription = (condition) => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };
  const url = `${SUBSCRIPTION.ADD_ALL_SUBSCRIPTION}?${condition}`;
  return axios.get(url, { headers }).then((response) => {
    if (response.data.status === 'ok') {
      return dispatch(fetchSubscriptionList());
    }
  });
};

export const removeAllSubscription = (condition) => (dispatch, getState) => {
  const { auth } = getState();
  const headers = { Authorization: `Bearer ${auth.jwt_token}` };
  const url = `${SUBSCRIPTION.REMOVE_ALL_SUBSCRIPTION}?${condition}`;
  return axios.get(url, { headers }).then((response) => {
    if (response.data.status === 'ok') {
      return dispatch(fetchSubscriptionList());
    }
  });
};
