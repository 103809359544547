import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'favoriteList',
  initialState: {
    movie: undefined,
    moviegenre: undefined,
    show: undefined,
    network: undefined,
    videolibraries: undefined,
    channel: undefined,
  },
  reducers: {
    onSetFavoriteList: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    onRemoveFavorite: (state, action) => {
      const {
        type,
        item: { slug },
      } = action.payload;

      const favoriteList = state[type].filter((item) => item.slug !== slug);
      state[type] = favoriteList;
    },
  },
});

export default slice.reducer;

const { onSetFavoriteList } = slice.actions;
export const setFavoriteList = ({ field, value }) => async (dispatch) => {
  try {
    return dispatch(onSetFavoriteList({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};

export const onRemoveFavorite = ({ item, type }) => async (dispatch) => {
  try {
    dispatch(slice.actions.onRemoveFavorite({ item, type }));
  } catch (e) {
    return console.error(e.message);
  }
};
