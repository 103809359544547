import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Popover } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import FastRewindRoundedIcon from '@material-ui/icons/FastRewindRounded';
import FastForwardRoundedIcon from '@material-ui/icons/FastForwardRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AlbumRoundedIcon from '@material-ui/icons/AlbumRounded';

import moment from 'moment';
import {
  COLOR,
  addDuration,
  subtractDuration,
  getDuration,
  getEmpyFilledElement,
} from 'global';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: theme.spacing(40),
    height: '100%',
    borderBottom: `2px solid ${COLOR.border_color}`,
  },
  channelLogoImage: {
    width: theme.spacing(8),
    height: '100%',
  },
  channelLogoGreyImage: {
    width: theme.spacing(8),
    height: '100%',
    filter: `grayscale(100%)`,
  },
  channelItemBox: {
    cursor: 'pointer',
    width: '100%',
    height: theme.spacing(9),
    background: 'rgb(17, 17, 17)',
    display: 'flex',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  channelItemLinkBox: {
    cursor: 'pointer',
    width: `calc(100% - ${theme.spacing(5)}px)`,
    height: '100%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecoration: 'none !important',
  },
  programBoxContainer: {
    width: `100%`,
    height: `calc(100% - ${theme.spacing(9)}px)`,
    borderRight: `2px solid ${COLOR.border_color}`,
  },
  guideLineBox: {
    position: 'absolute',
    left: 0,
    bottom: '2px',
    zIndex: 999,
    height: theme.spacing(0.5),
    background: 'white',
  },
  fullProgramBox: {
    width: `100%`,
    height: `calc(100% - ${theme.spacing(9)}px)`,
    borderRight: `2px solid ${COLOR.border_color}`,
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  linkItem: {
    color: 'white',
    textDecoration: 'none !important',
  },
  programBox: {
    position: 'relative',
    overflow: 'hidden',
    borderBottom: `2px solid ${COLOR.border_color}`,
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
    '&:hover > div': {
      opacity: 1,
    },
  },
  programBoxNoBorder: {
    position: 'relative',
    overflow: 'hidden',
    background: `${COLOR.b_black_mlight}`,
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
    '&:hover > div': {
      opacity: 1,
    },
  },
  programTitleBox: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(4),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  programTimeBox: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: `0 ${theme.spacing(1)}px`,
    marginRight: theme.spacing(3),
  },
  favoriteBox: {
    padding: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
  },
  description: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    textAlign: 'start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block -webkit-box',
    maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
    margin: '0 auto',
    '-webkit-line-clamp': 14,
    '-webkit-box-orient': 'vertical',
  },
  optionsBtn: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    cursor: 'pointer',
    opacity: 0,
  },
  optionsPane: {
    '& .MuiPopover-paper': {
      background: 'grey',
    },
  },
  optionsBox: {
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  optionsItemBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsItemBoxBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `0 ${theme.spacing(1)}px`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    border: `2px solid white`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLOR.hover_color}`,
    },
  },
  optionsNameBox: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  watchLiveIcon: {
    transform: 'rotate(180deg)',
  },
  recordMardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.5),
    background: 'red',
  },
  smallRecIcon: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  smallRecBox: {
    color: 'white',
    fontSize: '0.75rem',
  },
}));

export const ChannelVerticalLinear = React.memo(
  ({
    channelNumber,
    channel,
    epgData,
    isFavorite,
    onFavorite,
    isCurrent = false,
    packageSlug,
    category,
    isVidgo,
    timeOffsetValue,
    updateDvrTask,
    controlCatchUp,
    catchUpForwardBackward,
    catchUpRestart,
    catchUpEpgSlug,
    isPlaying,
    setVideoFullScreen,
    dvrList,
    availableRecording,
  }) => {
    const classes = useStyles();

    const theme = useTheme();
    const location = useLocation();
    const ticker = React.useRef();

    const [programs, setPrograms] = React.useState([]);
    const [random, setRandom] = React.useState();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorProgram, setAnchorProgram] = React.useState(null);

    React.useEffect(() => {
      const delta = 0;
      let tempPrograms = [];
      if (
        channel.type &&
        channel.type.slice(0, 4) === 'live' &&
        channel.epg &&
        epgData &&
        epgData.length > 0
      ) {
        tempPrograms = epgData;
      } else if (epgData && epgData.programs && epgData.programs.length > 0) {
        epgData.programs.forEach((item) => {
          if (!item.playlist) {
            return;
          }
          item.playlist.forEach((item) => {
            tempPrograms.push(item);
          });
        });
      }
      if (tempPrograms.length > 0) {
        let ppp;
        let pp;
        let np;
        let nnp;
        const cp =
          tempPrograms &&
          tempPrograms.find((item) =>
            moment
              .utc()
              .add(timeOffsetValue * 0.5, 'hours')
              .subtract(delta, 'milliseconds')
              .utcOffset(0)
              .isBetween(item.start_at, item.end_at),
          );

        if (cp) {
          ppp =
            tempPrograms.indexOf(cp) < 2
              ? tempPrograms.indexOf(cp) === 0
                ? getEmpyFilledElement(
                    subtractDuration(cp.start_at, '12:00:00'),
                    subtractDuration(cp.start_at, '6:00:00'),
                    getDuration(
                      subtractDuration(cp.start_at, '12:00:00'),
                      subtractDuration(cp.start_at, '6:00:00'),
                    ),
                    tempPrograms.length,
                  )
                : getEmpyFilledElement(
                    subtractDuration(tempPrograms[0].start_at, '6:00:00'),
                    tempPrograms[0].start_at,
                    getDuration(
                      subtractDuration(tempPrograms[0].start_at, '6:00:00'),
                      cp.start_at,
                    ),
                    tempPrograms.length,
                  )
              : tempPrograms[tempPrograms.indexOf(cp) - 2];
          pp =
            tempPrograms.indexOf(cp) === 0
              ? getEmpyFilledElement(
                  subtractDuration(cp.start_at, '6:00:00'),
                  cp.start_at,
                  getDuration(
                    subtractDuration(cp.start_at, '6:00:00'),
                    cp.start_at,
                  ),
                  tempPrograms.length,
                )
              : tempPrograms[tempPrograms.indexOf(cp) - 1];
          np =
            tempPrograms.indexOf(cp) === tempPrograms.length - 1
              ? getEmpyFilledElement(
                  cp.end_at,
                  addDuration(cp.end_at, '6:00:00'),
                  getDuration(cp.end_at, addDuration(cp.end_at, '6:00:00')),
                  tempPrograms.length,
                )
              : tempPrograms[tempPrograms.indexOf(cp) + 1];
          nnp =
            tempPrograms.indexOf(cp) > tempPrograms.length - 3
              ? tempPrograms.indexOf(cp) === tempPrograms.length - 2
                ? getEmpyFilledElement(
                    tempPrograms[tempPrograms.length - 1].end_at,
                    addDuration(
                      tempPrograms[tempPrograms.length - 1].end_at,
                      '6:00:00',
                    ),
                    getDuration(
                      tempPrograms[tempPrograms.length - 1].end_at,
                      addDuration(
                        tempPrograms[tempPrograms.length - 1].end_at,
                        '6:00:00',
                      ),
                    ),
                    tempPrograms.length,
                  )
                : getEmpyFilledElement(
                    addDuration(cp.end_at, '6:00:00'),
                    addDuration(cp.end_at, '12:00:00'),
                    getDuration(
                      addDuration(cp.end_at, '6:00:00'),
                      addDuration(cp.end_at, '12:00:00'),
                    ),
                    tempPrograms.length,
                  )
              : tempPrograms[tempPrograms.indexOf(cp) + 2];

          setPrograms([ppp, pp, cp, np, nnp]);
        } else {
          setPrograms([]);
        }

        if (cp) {
          if (cp.duration) {
            let timeout;
            const dur = moment.duration(cp.duration).asMilliseconds();
            const oneTick = dur / 100;
            timeout = oneTick;
            if (oneTick < 2000) {
              timeout = oneTick * 2;
            }
            if (oneTick * 2 < 2000) {
              timeout = oneTick * 5;
            }
            if (oneTick * 5 < 2000) {
              timeout = oneTick * 10;
            }
            if (oneTick * 10 < 2000) {
              timeout = oneTick * 25;
            }
            if (oneTick * 25 < 2000) {
              timeout = oneTick * 100;
            }

            clearInterval(ticker.current);
            ticker.current = setInterval(() => {
              setRandom(!random);
            }, timeout);
          }
        }
      } else {
        setPrograms([]);
      }

      return () => {
        if (ticker && ticker.current) clearInterval(ticker.current);
      };
    }, [channel, epgData, random, timeOffsetValue]);

    const getBasePath = () =>
      location.pathname.split('/')[1] === 'embeddable-channels'
        ? '/embeddable-channels'
        : '/channels';

    const handleOptionClick = (event, program) => {
      setAnchorProgram(program);
      setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const getGeneralOptionsBar = () => {
      const isAvailableRecord =
        anchorProgram.dvr &&
        !dvrList.find((item) => item.epg.id === anchorProgram.id) &&
        moment.utc().utcOffset(0).isBefore(anchorProgram.start_at);

      const isAvailableRewind =
        (anchorProgram.catchup ||
          (dvrList.find((item) => item.epg.id === anchorProgram.id) &&
            dvrList.find((item) => item.epg.id === anchorProgram.id).status ===
              'recorded')) &&
        moment.utc().utcOffset(0).isAfter(anchorProgram.end_at);

      return (
        <Box className={classes.optionsBox}>
          {isAvailableRecord && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => {
                updateDvrTask(
                  anchorProgram.slug,
                  moment.duration(anchorProgram.duration).asMinutes(),
                  true,
                );
                handleClose();
              }}
            >
              <Box mt={0.5} mr={0.5} color="red">
                <FiberManualRecordIcon />
              </Box>
              <Box className={classes.optionsNameBox}>Record</Box>
            </Box>
          )}
          {isAvailableRewind && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => {
                controlCatchUp(
                  channel,
                  anchorProgram.slug,
                  true,
                  !anchorProgram.catchup,
                );
              }}
            >
              <Box mt={0.5} mr={0.5} color={COLOR.blue_light}>
                <ReplayRoundedIcon />
              </Box>
              <Box className={classes.optionsNameBox}>Start Over</Box>
            </Box>
          )}
          {isAvailableRewind && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => {
                controlCatchUp(
                  channel,
                  anchorProgram.slug,
                  true,
                  !anchorProgram.catchup,
                );
              }}
            >
              <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
                <FastRewindRoundedIcon />
              </Box>
              <Box className={classes.optionsNameBox}>Rewind</Box>
            </Box>
          )}
          {!isVidgo && (
            <Box
              className={classes.optionsItemBox}
              onClick={() => onFavorite(channel)}
            >
              <Box mt={0.5} mr={0.5} color="white">
                {isFavorite(channel) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </Box>
              <Box className={classes.optionsNameBox}>Favorite</Box>
            </Box>
          )}
        </Box>
      );
    };

    const getControlsOptionsBar = () => (
      <Box className={classes.optionsBox}>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            setAnchorEl(null);
            setAnchorProgram(null);
            controlCatchUp(channel, null, true);
          }}
        >
          <Box mb={1} mr={0.5} color="white" className={classes.watchLiveIcon}>
            <DoubleArrowIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Watch Live</Box>
        </Box>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(false);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastRewindRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Rewind</Box>
        </Box>
        {anchorProgram.slug === catchUpEpgSlug && !isPlaying ? (
          <Box
            onClick={() =>
              controlCatchUp(
                channel,
                anchorProgram.slug,
                true,
                !anchorProgram.catchup,
              )
            }
            className={classes.optionsItemBoxBorder}
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PlayArrowRoundedIcon />
            </Box>
            <Box className={classes.optionsNameBox}>Play</Box>
          </Box>
        ) : (
          <Box
            onClick={() =>
              controlCatchUp(
                channel,
                anchorProgram.slug,
                false,
                !anchorProgram.catchup,
              )
            }
            className={classes.optionsItemBoxBorder}
          >
            <Box mt={0.5} mr={0.5} color="white">
              <PauseRoundedIcon />
            </Box>
            <Box className={classes.optionsNameBox}>Pause</Box>
          </Box>
        )}
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpForwardBackward(true);
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.green_light}>
            <FastForwardRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Forward</Box>
        </Box>
        <Box
          className={classes.optionsItemBox}
          onClick={() => {
            catchUpRestart();
          }}
        >
          <Box mt={0.5} mr={0.5} color={COLOR.blue_light}>
            <ReplayRoundedIcon />
          </Box>
          <Box className={classes.optionsNameBox}>Start Over</Box>
        </Box>
      </Box>
    );

    const getOptionsBox = () => (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.optionsPane}
      >
        {anchorProgram.slug === catchUpEpgSlug
          ? getControlsOptionsBar()
          : getGeneralOptionsBar()}
      </Popover>
    );

    const getEpgBox = () => {
      const CW = 25;
      let pos = 0;
      const delta = 0;

      let tempPrograms = [];
      if (
        channel.type &&
        channel.type.slice(0, 4) === 'live' &&
        channel.epg &&
        epgData &&
        epgData.length > 0
      ) {
        tempPrograms = epgData;
      } else if (epgData && epgData.programs && epgData.programs.length > 0) {
        epgData.programs.forEach((item) => {
          if (!item.playlist) {
            return;
          }
          item.playlist.forEach((item) => tempPrograms.push(item));
        });
      }

      if (tempPrograms) {
        const currentProgram = tempPrograms.find((item) =>
          moment
            .utc()
            .subtract(delta, 'milliseconds')
            .utcOffset(0)
            .isBetween(item.start_at, item.end_at),
        );

        if (!currentProgram) {
          return;
        }

        const now = moment()
          .subtract(delta, 'milliseconds')
          .utcOffset(0)
          .valueOf();

        const startAt = moment(currentProgram.start_at).utcOffset(0).valueOf();
        const endAt = moment(currentProgram.end_at).utcOffset(0).valueOf();
        const l = now - startAt;
        const r = endAt - now;
        pos = l / (l + r);
      }

      const heightList = [CW / 2, CW, CW, CW, CW / 2];

      return (
        <Box className={classes.programBoxContainer}>
          {programs.map((program, index) => {
            const key = `programBox-${index}`;
            const isAvailableRecord =
              program.dvr &&
              !dvrList.find((item) => item.epg.id === program.id) &&
              moment.utc().utcOffset(0).isBefore(program.start_at);

            const isAvailableRewind =
              (program.catchup ||
                (dvrList.find((item) => item.epg.id === program.id) &&
                  dvrList.find((item) => item.epg.id === program.id).status ===
                    'recorded')) &&
              moment.utc().utcOffset(0).isAfter(program.end_at);

            const shouldDisplayRecButton =
              availableRecording &&
              (program.catchup ||
                (dvrList.find((item) => item.epg.id === program.id) &&
                  dvrList.find((item) => item.epg.id === program.id).status !==
                    'error'));

            const isCatchupEpgSlug =
              availableRecording &&
              dvrList.find((item) => item.epg.id === program.id) &&
              dvrList.find((item) => item.epg.id === program.id).status !==
                'error' &&
              program.slug === catchUpEpgSlug;

            return (
              program &&
              heightList[index] > 0 && (
                <Box
                  key={key}
                  height={`${heightList[index]}%`}
                  className={
                    index === 4
                      ? classes.programBoxNoBorder
                      : classes.programBox
                  }
                  onDoubleClick={() => {
                    if (isCurrent) {
                      setVideoFullScreen();
                    }
                  }}
                  style={{
                    border:
                      anchorEl &&
                      anchorProgram === program &&
                      `2px solid white`,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={
                      channel.type === 'linear/stream' &&
                      !moment
                        .utc()
                        .subtract(delta, 'milliseconds')
                        .utcOffset(0)
                        .isBetween(program.start_at, program.end_at)
                        ? `${getBasePath()}/${packageSlug}/${category}/${
                            channel.slug
                          }/ondemand/${program.uuid}/`
                        : `${getBasePath()}/${packageSlug}/${category}/${
                            channel.slug
                          }/${epgData.indexOf(program.uuid)}/`
                    }
                    className={classes.linkItem}
                  >
                    {index > 0 && (
                      <Box p={(1, 1, 1, 1)} className={classes.programTitleBox}>
                        {program.name}
                      </Box>
                    )}
                    <Box p={(1, 1, 1, 1)} className={classes.programTimeBox}>
                      {program.start_at &&
                        moment(program.start_at)
                          .add(0, 'milliseconds')
                          .format('hh:mm a')}
                      {shouldDisplayRecButton && (
                        <Box className={classes.recordMardBox}>
                          <Box mr={0.5} color="white">
                            <FiberManualRecordIcon
                              className={classes.smallRecIcon}
                            />
                          </Box>
                          <Box className={classes.smallRecBox}>REC</Box>
                        </Box>
                      )}
                      {isCatchupEpgSlug && (
                        <Box className={classes.recordMardBox}>
                          <Box mr={0.5} color="white">
                            <AlbumRoundedIcon
                              className={classes.smallRecIcon}
                            />
                          </Box>
                          <Box className={classes.smallRecBox}>
                            WATCHING NOW
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {moment
                      .utc()
                      .subtract(delta, 'milliseconds')
                      .utcOffset(0)
                      .isBetween(program.start_at, program.end_at) && (
                      <Box
                        className={classes.guideLineBox}
                        width={`${pos * 100}%`}
                      />
                    )}
                  </Link>
                  {program.catchup && (
                    <Box
                      position="absolute"
                      color="white"
                      right={theme.spacing(2)}
                      bottom={theme.spacing(2)}
                    >
                      <ReplayRoundedIcon />
                    </Box>
                  )}
                  {availableRecording &&
                    (isAvailableRecord || isAvailableRewind || !isVidgo) &&
                    channel.type &&
                    channel.type.slice(0, 4) === 'live' && (
                      <Box
                        className={classes.optionsBtn}
                        onClick={(event) => handleOptionClick(event, program)}
                      >
                        <MoreVertIcon />
                      </Box>
                    )}
                </Box>
              )
            );
          })}
          {anchorProgram && getOptionsBox()}
        </Box>
      );
    };

    return (
      <Box className={classes.container}>
        <Box className={classes.channelItemBox}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              background: isCurrent ? window.BRAND.primary_color : 'inherit',
              minWidth: `${theme.spacing(5)}px`,
            }}
          >
            <Box textAlign="center">
              {channelNumber && <Box>{channelNumber}</Box>}
              {!isVidgo && (
                <Box
                  className={classes.favoriteBox}
                  onClick={() => onFavorite(channel)}
                >
                  {isFavorite(channel) ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Link
            component={RouterLink}
            to={`/channels/${packageSlug}/${category}/${channel.slug}/`}
            className={classes.channelItemLinkBox}
          >
            <img
              src={channel.logo || channel.logo_url}
              alt=""
              className={
                isCurrent
                  ? classes.channelLogoImage
                  : classes.channelLogoGreyImage
              }
            />
            <Box p={(1, 1, 1, 1)} className={classes.programTitleBox}>
              {channel.name}
            </Box>
          </Link>
        </Box>
        {programs && programs.length > 0 && epgData ? (
          getEpgBox()
        ) : (
          <Box className={classes.fullProgramBox} width={1}>
            <Link
              component={RouterLink}
              to={`/channels/${packageSlug}/${category}/${channel.slug}/`}
              className={classes.linkItem}
            >
              <Box className={classes.description}>
                {channel.description || channel.name}
              </Box>
            </Link>
          </Box>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.channelNumber === nextProps.channelNumber &&
    prevProps.channel === nextProps.channel &&
    prevProps.epgData === nextProps.epgData &&
    prevProps.isFavorite === nextProps.isFavorite &&
    prevProps.isCurrent === nextProps.isCurrent &&
    prevProps.isVidgo === nextProps.isVidgo &&
    prevProps.timeOffsetValue === nextProps.timeOffsetValue &&
    prevProps.isPlaying === nextProps.isPlaying &&
    prevProps.dvrList === nextProps.dvrList &&
    prevProps.availableRecording === nextProps.availableRecording,
);
