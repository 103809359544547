import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { LoadingSpinner } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  imgContainer: {
    width: '100%',
    paddingTop: '100%' /* 1:1 Aspect Ratio */,
    position: 'relative',
    flexBasis: '100%',
    '&:hover > div': {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  imgBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  shadeBox: {
    background: '#000000cc',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
  },
  imgItem: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  subscribedText: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: '100%',
    background: '#dc6403',
    padding: `0 ${theme.spacing(1)}px`,
    color: 'white',
  },
  removeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'red',
    opacity: 0,
    cursor: 'pointer',
  },
  browseButton: {
    position: 'absolute',
    left: `50%`,
    top: `50%`,
    transform: `translateX(-50%) translateY(-50%)`,
    color: 'white',
    opacity: 0,
    textAlign: 'center',
    fontSize: theme.typography.subtitle1.fontSize,
    cursor: 'pointer',
  },
  browseLink: {
    color: 'white',
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    },
  },
  addBox: {
    position: 'absolute',
    left: `50%`,
    top: `50%`,
    display: 'flex',
    maxWidth: '95%',
    flexDirection: 'column',
    justifyContent: 'start',
    textAlign: 'start',
    transform: `translateX(-50%) translateY(-50%)`,
    opacity: 0,
  },
  addButton: {
    margin: `${theme.spacing(0.5)}px 0`,
    color: 'white',
    fontSize: theme.typography.subtitle1.fontSize,
    cursor: 'pointer',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    color: 'white',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle1,
  },
}));

export const SubscriptionItem = ({ item, onToggleSub, onCreateAccount }) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    setIsProcessing(false);
  }, [item]);

  return (
    <Box
      className={classes.container}
      style={{ cursor: !item.subscribed && 'pointer' }}
    >
      <Box className={classes.imgContainer}>
        <Box className={classes.imgBox}>
          <img
            src={item.image || item.tile_logo}
            className={classes.imgItem}
            alt=""
          />
        </Box>
        {item.subscribed && (
          <Box className={classes.subscribedText}>SUBSCRIBED</Box>
        )}
        <Box className={classes.shadeBox}></Box>
        {!isProcessing && item.subscribed && (
          <Box
            className={classes.removeButton}
            onClick={() => {
              setIsProcessing(true);
              onToggleSub(item);
            }}
          >
            <CloseRoundedIcon />
          </Box>
        )}
        {!isProcessing && item.subscribed && (
          <Box className={classes.browseButton}>
            <Link
              component={RouterLink}
              to={`${item.platform_path}?network=${item.slug}&page=1&page_size=15&ordering=`}
              className={classes.browseLink}
            >
              Browse
            </Link>
          </Box>
        )}
        {!isProcessing && !item.subscribed && (
          <Box className={classes.addBox}>
            <Box
              className={classes.addButton}
              onClick={() => {
                setIsProcessing(true);
                onToggleSub(item);
              }}
            >
              Add to Subscriptions
            </Box>
            <Box
              className={classes.addButton}
              onClick={(event) => onCreateAccount(event, item)}
            >
              Create Account
            </Box>
            <Box className={classes.addButton}>
              <Link
                component={RouterLink}
                to={`${item.platform_path}?network=${item.slug}&page=1&page_size=15&ordering=`}
                className={classes.browseLink}
              >
                Browse
              </Link>
            </Box>
          </Box>
        )}
        {isProcessing && (
          <Box className={classes.shadeBox} style={{ opacity: 1 }}>
            <Box
              width={1}
              justifyContent="center"
              alignItems="center"
              height={1}
              position="relative"
            >
              <LoadingSpinner />
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.title}>{item.name}</Box>
    </Box>
  );
};
