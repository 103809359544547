import { BRAND_SLUG, API_DOMAIN } from 'global';

let LIVEAPI_BASE_URL = '';

if (!!localStorage.getItem('settings')) {
  const settings = JSON.parse(localStorage.getItem('settings'));
  LIVEAPI_BASE_URL = settings.LIVEAPI_BASE_URL;
}
const DOMAIN = `https://${window.location.host}`;

export const ACTOR_INFO = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/actors/`,
};

export const BRAND = {
  API: `${API_DOMAIN}/branding/api/${BRAND_SLUG}/settings/`,
};

export const CHANNELS_INFO = {
  ALL_CHANNELS: `${LIVEAPI_BASE_URL}channels/`,
  CATEGORY_API: `${LIVEAPI_BASE_URL}categories/`,
};

export const FAVORITE_LIST = {
  SHOW_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/shows/`,
  MOVIE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/movies/`,
  NETWORK_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/networks/`,
  MOVIE_GENRE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/moviegenres/`,
  VIDEO_LIBRARY_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/videolibraries/`,
  CHANNEL_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/favorite/channels/`,
  ADD_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/add_favorite/`,
  REMOVE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/remove_favorite/`,
};

export const FETCH_WELCOME_PAGE = `${API_DOMAIN}/branding/api/${BRAND_SLUG}/welcome_page/`;

export const FRONT_PAGE = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/front_pages/`,
  PATH: `/guide/api/v0/${BRAND_SLUG}/web/front_pages/`,
};

export const INTERNATIONAL = {
  COUNTRY_LIST: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/world_tv_stations/continents/`,
  COUNTRY_STATION_LIST: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/world_tv_stations/countries/{0}/stations/`,
  USA_STATION_LIST: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/world_tv_stations/countries/{0}/stations_by_state/`,
};

export const LIST_PAGE = {
  SHOW_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/`,
  MOVIE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/movies/`,
  SOURCE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/sources/`,
};

export const MOVIE_GENRES = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/movie_genres/`,
};

export const MOVIE_INFO = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/movies/`,
  MOVIE_LINKS: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/movies/{0}/links/`,
};

export const VOD_MOVIE_INFO = {
  API: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/movies/`,
  STREAMS: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/movies/{0}/streams/{1}/`,
};

export const MOVIE_RELATED = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/movies/{0}/related_movies/`,
};

export const NETWORK_INFO = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/networks/`,
};

export const PROFILE_PAGE = {
  PROFILE_API: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/profile/`,
  UPGRADE_API: `${API_DOMAIN}/access/api/v0/${BRAND_SLUG}/upgrade-account/`,
  STOP_RENEW_API: `${API_DOMAIN}/access/api/v0/${BRAND_SLUG}/subscription/cancel/`,
  CANCEL_API: `${API_DOMAIN}/access/api/v0/cancel-account/`,
  MEMBER_BILLING_API: `${API_DOMAIN}/members/api/v0/profile_edit_card/`,
};

export const RESET_PASSWORD = {
  REQUEST_RESET: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/password_reset/`,
  REQUEST_PASSWORD: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/password_reset/confirm/{0}/{1}/ `,
};

export const RADIO = {
  RADIO_STATION_INFO: `${API_DOMAIN}/radio/api/v0/${BRAND_SLUG}/web/stations/{0}/`,
  RADIO_GENRES: `${API_DOMAIN}/radio/api/v0/${BRAND_SLUG}/web/genres/`,
  RADIO_STATIONS: `${API_DOMAIN}/radio/api/v0/${BRAND_SLUG}/web/genres/{0}/stations/`,
};

export const REDEMPTION = {
  start: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/start/`,
  register: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/register/`,
};

export const SEARCH_PAGE_SUGGESTIONS = {
  API: `${API_DOMAIN}/search/api/suggestion/`,
};

export const SEARCH = {
  METADATA: `${API_DOMAIN}/search/api/search_metadata/`,
  BY_TYPE: `${API_DOMAIN}/search/api/search_by_type/`,
  RELATED: `${API_DOMAIN}/search/api/related_search/`,
};

export const LOGIN_JWT = {
  API: `${API_DOMAIN}/auth/api/v1/${BRAND_SLUG}/web/jwt/`,
  VERIFY: `${API_DOMAIN}/auth/api/v1/${BRAND_SLUG}/web/jwt/verify/`,
};

export const LOGIN_SHADOW_JWT = {
  API: `${API_DOMAIN}/auth/api/v1/${BRAND_SLUG}/web/shadow/jwt/`,
};

export const SHOW_GENRES = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/show_genres/`,
};

export const SHOW_INFO = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/{0}/?format=json`,
  BASE_API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/`,
  EPISODES_BY_MODE_NATIVE: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/{0}/seasonepisodes_by_modes/`,
  EPISODES_LINKS: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/{0}/seasons/{1}/episodes/{2}/`,
  HREF: '/guide/show/info/{0}/',
};

export const VOD_SHOW_INFO = {
  API: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/shows/{0}/?format=json`,
  BASE_API: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/shows/`,
  EPISODES_BY_SEASON_ID: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/shows/{0}/episodes/?season_id={1}`,
  EPISODE_URLS: `${API_DOMAIN}/guide/api/v1/${BRAND_SLUG}/web/vod/shows/{0}/episodes/{1}/streams/{2}/`,
  HREF: '/guide/show/info/{0}/',
};

export const SHOW_RELATED = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/shows/{0}/related_shows/`,
};

export const SIDEBAR = {
  LEFT: '/api/left_menu/web/',
  API: `${API_DOMAIN}/left_menu/api/v1/${BRAND_SLUG}/web/left_menu/`,
  RIGHT: '/api/branding/sidebarbuttons/web/',
};

export const SITE_PAGE = {
  API: `${API_DOMAIN}/guide/api/v0/${BRAND_SLUG}/web/site_pages/`,
  PATH: `/guide/api/v0/${BRAND_SLUG}/web/site_pages/`,
};

export const SOTALCLOUD = {
  API: `${API_DOMAIN}/sotalcloud/api/packages/`,
};

export const SUBSCRIPTION = {
  SUBSCRIPTION_LIST: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/`,
  SUBSCRIPTION_HEADER: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/service_type_headers/`,
  SUBSCRIPTION_DETAIL: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/{0}/`,
  ADD_SUBSCRIPTION: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/add/{0}/`,
  REMOVE_SUBSCRIPTION: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/remove/{0}/`,
  REMOVE_ALL_SUBSCRIPTION: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/remove_all/`,
  ADD_ALL_SUBSCRIPTION: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/add_all/`,
  USER_SYNCED_TITLES: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_services/available_content/`,
  SUBSCRIPTION_MANAGER_PAGE: `${API_DOMAIN}/members/api/v0/${BRAND_SLUG}/web/subscription_manager_page/`,
};

export const SUBSCRIPTION_LIST = {
  API: `/members/api/v0/${BRAND_SLUG}/web/subscription_services/`,
};

export const WEBLINK = {
  PATH: `${DOMAIN}/guide/weblink/`,
};
