import React, { useState } from 'react';
import Box from '@material-ui/core/Box/Box';
import { isWidthUp, withWidth } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { COLOR } from 'global';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import { ChannelLinear } from './ChannelLinear';
import { ChannelVerticalLinear } from './ChannelVerticalLinear';
import 'react-virtualized/styles.css';

const useStyles = makeStyles((theme) => ({
  channelBox: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    [theme.breakpoints.up('md')]: {
      display: 'inheirt',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
    // '-ms-overflow-style': 'none' /* IE and Edge */,
    // scrollbarWidth: 'none',
  },
  selectedCategoryBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    borderBottom: `2px solid ${COLOR.border_color}`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(4.5),
    },
  },
  upgradeBox: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  upgradeButton: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(5)}px`,
    background: window.BRAND.primary_color,
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

const EpgContainer = React.memo(
  ({
    identifier,
    channelsData,
    epgData,
    isFavorite,
    onFavorite,
    packageSlug,
    updateCurrentShowCategory,
    currentChannel,
    channelCategories,
    premiumLogo,
    getCategoryImage,
    timeOffsetValue,
    updateDvrTask,
    controlCatchUp,
    catchUpForwardBackward,
    catchUpRestart,
    catchUpEpgSlug,
    isPlaying,
    setVideoFullScreen,
    dvrList,
    availableRecording,
    categoryIndex,
    width,
    forceRender,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const containerRef = React.useRef();
    const [mediaWidth, setMediaWidth] = useState('lg');
    const listCache = React.useRef(
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
      }),
    );

    React.useEffect(() => {
      if (containerRef && containerRef.current) {
        containerRef.current.scrollTo(0, 0);
        setMediaWidth(width);
      }
    }, [channelsData, width]);

    const handleScroll = (e) => {
      e.preventDefault();
      const element = e.target;
      if (isWidthUp('md', mediaWidth)) {
        updateCurrentShowCategory(
          element.scrollTop === 0
            ? 0
            : element.scrollTop + element.clientHeight,
          false,
        );
      } else {
        updateCurrentShowCategory(
          element.scrollLeft === 0
            ? 0
            : element.scrollLeft + element.clientWidth,
          true,
        );
      }
    };

    const getChannelCategory = (channelIndex) => {
      let result = '';
      for (let index = 0; index <= channelIndex; index += 1) {
        if (typeof channelsData[index] === 'string') {
          result = channelsData[index];
        }
      }

      return result;
    };

    if (isWidthUp('md', mediaWidth)) {
      return (
        <Box
          id={identifier}
          className={classes.channelBox}
          ref={containerRef}
          onScroll={handleScroll}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={listCache.current.rowHeight}
                deferredMeasurementCache={listCache.current}
                rowCount={channelsData.length}
                scrollToIndex={categoryIndex || 0}
                scrollToAlignment={'start'}
                rowRenderer={({ key, index, style, parent }) => {
                  const channel = channelsData[index];

                  if (typeof channel === 'string') {
                    return (
                      <CellMeasurer
                        key={key}
                        cache={listCache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <CategoryTitle
                          classes={classes}
                          channel={channel}
                          index={index}
                          style={style}
                          mediaWidth={mediaWidth}
                          packageSlug={packageSlug}
                          premiumLogo={premiumLogo}
                          theme={theme}
                          getCategoryImage={getCategoryImage}
                          channelCategories={channelCategories}
                        />
                      </CellMeasurer>
                    );
                  }
                  return (
                    <CellMeasurer
                      key={key}
                      cache={listCache.current}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      <div style={style}>
                        <ChannelLinear
                          key={channel.key || index}
                          channelNumber={channel.lcn || ''}
                          channel={channel}
                          epgData={epgData[channel.slug] || []}
                          isFavorite={isFavorite}
                          onFavorite={onFavorite}
                          isCurrent={
                            currentChannel &&
                            channel.slug === currentChannel.slug
                          }
                          packageSlug={packageSlug}
                          category={getChannelCategory(index)}
                          isVidgo={packageSlug !== 'free'}
                          timeOffsetValue={timeOffsetValue}
                          updateDvrTask={updateDvrTask}
                          controlCatchUp={controlCatchUp}
                          catchUpForwardBackward={catchUpForwardBackward}
                          catchUpRestart={catchUpRestart}
                          catchUpEpgSlug={catchUpEpgSlug}
                          isPlaying={isPlaying}
                          setVideoFullScreen={setVideoFullScreen}
                          dvrList={dvrList}
                          availableRecording={availableRecording}
                          forceRender={forceRender}
                        />
                      </div>
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        </Box>
      );
    }

    return (
      <Box
        id={identifier}
        className={classes.channelBox}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {channelsData.mapWithKey((channel, index) =>
          typeof channel === 'string' ? (
            <CategoryTitle
              classes={classes}
              channel={channel}
              index={index}
              mediaWidth={mediaWidth}
              packageSlug={packageSlug}
              premiumLogo={premiumLogo}
              theme={theme}
              getCategoryImage={getCategoryImage}
              channelCategories={channelCategories}
            />
          ) : (
            <ChannelVerticalLinear
              key={channel.key || index}
              channelNumber={channel.lcn || ''}
              channel={channel}
              epgData={epgData[channel.slug] || []}
              isFavorite={isFavorite}
              onFavorite={onFavorite}
              isCurrent={currentChannel && channel.slug === currentChannel.slug}
              packageSlug={packageSlug}
              category={getChannelCategory(index)}
              isVidgo={packageSlug !== 'free'}
              timeOffsetValue={timeOffsetValue}
              updateDvrTask={updateDvrTask}
              controlCatchUp={controlCatchUp}
              catchUpForwardBackward={catchUpForwardBackward}
              catchUpRestart={catchUpRestart}
              catchUpEpgSlug={catchUpEpgSlug}
              isPlaying={isPlaying}
              setVideoFullScreen={setVideoFullScreen}
              dvrList={dvrList}
              availableRecording={availableRecording}
            />
          ),
        )}
      </Box>
    );
  },
);

export const Epg = withWidth()(EpgContainer);

const CategoryTitle = ({
  classes,
  channel,
  index,
  style,
  mediaWidth,
  packageSlug,
  premiumLogo,
  theme,
  getCategoryImage,
  channelCategories,
}) => (
  <Box
    className={classes.selectedCategoryBox}
    key={channel.key || index}
    style={style}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        transform: `rotate(${isWidthUp('md', mediaWidth) ? 0 : -90}deg)`,
        width: '100%',
        height: '100%',
      }}
    >
      {channel === packageSlug ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          {premiumLogo ? (
            <img src={premiumLogo} height={`${theme.spacing(6)}px`} alt="" />
          ) : (
            getCategoryImage(channel, true)
          )}
        </Box>
      ) : (
        <Box>{getCategoryImage(channel, true)}</Box>
      )}
      {channel !== packageSlug && (
        <Box
          fontSize="h6.fontSize"
          py={0.5}
          pl={1}
          style={{ whiteSpace: 'nowrap' }}
        >
          {channelCategories.filter((d) => !d.parent && d.slug === channel)
            .length > 0 &&
            channelCategories.filter((d) => !d.parent && d.slug === channel)[0]
              .name}
        </Box>
      )}
    </Box>
  </Box>
);
