import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, ListItem, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LeftMenuLink } from './LeftMenuLink';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
    },
    '&:hover span': {
      color: `${theme.palette.primary.main} !important`,
    },
    '&:hover a': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  subMenuItem: {
    paddingLeft: theme.spacing(4),
  },
  listItemNormal: {
    backgroundColor: 'rgba(1,1,1,0)',
  },
  listItemSelected: {
    color: theme.palette.primary.main,
  },
  menuBox: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(1),
  },
  menuIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  menuTextNormal: {
    color: 'white',
    textDecoration: 'none !important',
    transition: '0.35s',
  },
  menuText: {},
  menuTextHide: {
    display: 'none',
  },
  menuTextSelected: {
    color: theme.palette.primary.main,
  },
  whiteText: {
    color: 'white',
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
}));

export const LeftMenuSidebar = ({ accordions, onToggle, expanded }) => {
  const classes = useStyles();
  const [levels, setLevels] = React.useState([]);
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [activeList, setActiveList] = React.useState([]);
  const [currentActiveLevels, setCurrentActiveLevels] = React.useState([]);

  const handleSelect = (selectedItem, k) => {
    if (!expanded) {
      onToggle(false);
    }
    if (selectedItem.childs && selectedItem.childs.length > 0) {
      setLevels([...levels, k]);
      setBreadcrumbs([...breadcrumbs, selectedItem.name]);
      setActiveList(selectedItem.childs);
    }
  };

  const handleNavbackClick = () => {
    const updatedLevels = levels.filter((_, i) => i < levels.length - 1);
    let childList = accordions;
    updatedLevels.forEach((level) => {
      childList = childList[level].childs;
    });
    setLevels(updatedLevels);
    setActiveList(childList);
    setBreadcrumbs(breadcrumbs.filter((_, i) => i < levels.length - 1));
  };

  const handleToggle = () => {
    onToggle(true);
    if (expanded) {
      setActiveList(accordions);
      setLevels([]);
      setBreadcrumbs([]);
    }
  };

  const renderToggleMenu = () => (
    <Box>
      <IconButton className={classes.menuBox} onClick={handleToggle}>
        <Box component={expanded ? CloseIcon : MenuIcon} color="white" />
      </IconButton>
    </Box>
  );

  const renderBreadcrumb = () =>
    breadcrumbs.length > 0 && (
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton className={classes.menuBox} onClick={handleNavbackClick}>
            <Box component={ArrowBackIcon} color="white" />
          </IconButton>
        </Box>
        <span>
          {breadcrumbs.map((breadcrumb, i) => {
            const key = `breadcrumb-item-${i}`;
            return (
              <span key={key}>
                {i > 0 && breadcrumbs.length - 1 === i && (
                  <span className={classes.whiteText}>&nbsp;/&nbsp;</span>
                )}
                <span
                  className={clsx({
                    [classes.primaryText]: i === breadcrumbs.length - 1,
                    [classes.whiteText]: i !== breadcrumbs.length - 1,
                    [classes.hide]: i < breadcrumbs.length - 2,
                  })}
                >
                  {breadcrumb}
                </span>
              </span>
            );
          })}
        </span>
      </Box>
    );

  React.useEffect(() => {
    setActiveList(accordions);
  }, [accordions]);

  return activeList.length === 0 ? (
    <Box
      width={1}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      {renderToggleMenu()}
      <Box>
        {levels.length > 0 && <Box onClick={handleNavbackClick}> </Box>}
        {renderBreadcrumb()}
      </Box>
      {activeList.map((item, i) => {
        let isSelected = currentActiveLevels.length > 0;
        const checkURL = item.url.includes('?')
          ? window.location.href.includes(item.url)
          : window.location.href.includes('?')
          ? item.url === `${window.location.pathname}${window.location.search}`
          : window.location.pathname === item.url;

        if (isSelected) {
          const subActiveLevels = currentActiveLevels.filter(
            (_, k) => k < levels.length,
          );
          isSelected = levels.every((level, k) => level === subActiveLevels[k]);
        }
        if (isSelected) {
          isSelected = i === currentActiveLevels[levels.length];
        }
        const key = `listItem-${i}`;
        return item.childs && item.childs.length > 0 ? (
          <ListItem
            button
            key={key}
            className={clsx(classes.listItem, {
              [classes.listItemNormal]: !isSelected,
              [classes.listItemSelected]: isSelected,
              [classes.subMenuItem]: levels.length > 0,
            })}
            onClick={() => handleSelect(item, i)}
          >
            <Box className={classes.menuBox}>
              {item.icon && (
                <img
                  className={classes.menuIcon}
                  alt={item.name}
                  src={item.icon}
                />
              )}
            </Box>
            <Box
              component="span"
              className={clsx(classes.menuTextNormal, {
                [classes.menuText]: expanded,
                [classes.menuTextHide]: !expanded,
                [classes.menuTextSelected]: isSelected || checkURL,
              })}
            >
              {item.name}
            </Box>
          </ListItem>
        ) : (
          <ListItem
            className={clsx(classes.listItem, {
              [classes.listItemNormal]: !isSelected,
              [classes.listItemSelected]: isSelected,
              [classes.subMenuItem]: levels.length > 0,
            })}
            key={key}
          >
            <LeftMenuLink
              className={classes.menuTextNormal}
              style={{ width: '100%' }}
              component={RouterLink}
              to={item.url}
              onClick={() => {
                setCurrentActiveLevels([...levels, i]);
              }}
              display_mode={item.display_mode}
            >
              <Box display="flex" alignItems="center" justifyContent="left">
                <Box className={classes.menuBox}>
                  {item.icon && (
                    <img
                      className={classes.menuIcon}
                      alt={item.name}
                      src={item.icon}
                    />
                  )}
                </Box>
                <Box
                  component="span"
                  className={clsx({
                    [classes.menuText]: expanded,
                    [classes.menuTextHide]: !expanded,
                    [classes.menuTextSelected]: isSelected || checkURL,
                  })}
                >
                  {item.name}
                </Box>
              </Box>
            </LeftMenuLink>
          </ListItem>
        );
      })}
    </Box>
  );
};
