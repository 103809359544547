import React from 'react';
import is from 'is_js';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../components/Loading';
import './index.css';

function withInfiniteScroll({ items, firstLoadPageSize = 3, pageSize = 2 }) {
  return (WrappedComponent) => () => {
    const [nextItems, setNextItems] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);

    const next = () => {
      if (is.equal(nextItems.length, items.length)) {
        setHasMore(false);
        return;
      }

      const nextIndex = is.equal(currentPage, 1)
        ? firstLoadPageSize
        : nextItems.length;
      const slicedNextItems = items.slice(nextIndex, nextIndex + pageSize);

      setNextItems(nextItems.concat(slicedNextItems));
      setCurrentPage(currentPage + 1);
    };

    React.useEffect(() => {
      const rightNextItems = items.slice(0, firstLoadPageSize);
      const newNextItems =
        nextItems && !!nextItems.length
          ? nextItems.concat(rightNextItems)
          : rightNextItems;

      setNextItems(newNextItems);
      setCurrentPage(currentPage + 1);
    }, []);

    return (
      <InfiniteScroll
        dataLength={nextItems.length}
        next={() => next()}
        hasMore={hasMore}
        loader={() => <Loading />}
        refreshFunction={() => {}}
        endMessage={null}
        pullDownToRefresh
        pullDownToRefreshThreshold={20}
      >
        <WrappedComponent nextItems={nextItems} />
      </InfiniteScroll>
    );
  };
}

export default withInfiniteScroll;
