import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, CircularProgress, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { staticUrl } from 'global';

const contentTypesGridSize = {
  shows: { xs: 12, sm: 6, md: 4, lg: 3 },
  movies: { xs: 12, sm: 6, md: 4, lg: 2 },
  actors: { xs: 12, sm: 6, md: 4, lg: 2 },
  channels: { xs: 12, sm: 6, md: 4, lg: 2 },
  music: { xs: 12, sm: 6, md: 4, lg: 2 },
  radio: { xs: 12, sm: 6, md: 4, lg: 2 },
  radiostations: { xs: 12, sm: 6, md: 4, lg: 2 },
  tvstation: { xs: 12, sm: 6, md: 4, lg: 2 },
  subscriptions_service: { xs: 12, sm: 6, md: 4, lg: 2 },
};

export const GridListSearch = React.memo(
  ({
    items,
    onPage,
    isAddMoreItem = false,
    viewMoreLink,
    type,
    title,
    isMusicTheCurrentTab = false,
  }) => {
    const classes = useStyles();
    const shouldDisplayYearAlongWithTitle = ['shows', 'movies'].includes(type);
    const { xs, sm, md, lg } = contentTypesGridSize[type];

    const getReleaseYear = ({
      first_aired: firstAired,
      release_year: releaseYear,
    }) => {
      const year = new Date(firstAired).getFullYear() || releaseYear;
      return `(${year})`;
    };

    const RedirectTo = ({ item, type, children, ...props }) =>
      ['music', 'channels'].includes(type) ? (
        <a href={item.url} {...props}>
          {children}
        </a>
      ) : (
        <Link component={RouterLink} to={item.url || '#'} {...props}>
          {children}
        </Link>
      );

    return (
      <>
        {isMusicTheCurrentTab && (
          <Box mb={2} className={classes.titleBox}>
            {title === 'Music' ? 'Music Channels' : title}
          </Box>
        )}

        {!!items.length ? (
          <Grid container spacing={2}>
            {items?.mapWithKey((item) => (
              <Grid item key={item.key} xs={xs} sm={sm} md={md} lg={lg}>
                <Box className={classes.itemContainer}>
                  <Box className={classes.imageContainer}>
                    <RedirectTo item={item} type={type}>
                      <img src={item.image} width="100%" height="100%" alt="" />
                      {item.is_free && (
                        <Box className={classes.isFreeTag}>
                          <Box ml="7px" mr="7px">
                            FREE
                          </Box>
                          {item?.modes?.modes.includes('free_signup') && (
                            <img
                              alt="lock-open-alt"
                              height="20"
                              src={staticUrl(
                                'images/icons/Unlock_icon_white.png',
                              )}
                            />
                          )}
                        </Box>
                      )}
                    </RedirectTo>
                  </Box>

                  <Box className={classes.title}>
                    {item.name}{' '}
                    {shouldDisplayYearAlongWithTitle && (
                      <>{getReleaseYear(item)}</>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}

            {isAddMoreItem && (
              <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                <Box
                  className={classes.viewMoreContainer}
                  onClick={() => {
                    onPage();
                  }}
                >
                  <img src={viewMoreLink} width="100%" height="100%" alt="" />
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            flexGrow="1"
            flexDirection="column"
          >
            <CircularProgress />
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              ml={1}
              fontSize="18px"
              color="#dadada"
            >
              Loading...
            </Box>
          </Box>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.items === nextProps.items &&
    prevProps.onPage === nextProps.onPage &&
    prevProps.isAddMoreItem === nextProps.isAddMoreItem &&
    prevProps.viewMoreLink === nextProps.viewMoreLink &&
    prevProps.type === nextProps.type &&
    prevProps.title === nextProps.title &&
    prevProps.isMusicTheCurrentTab === nextProps.isMusicTheCurrentTab,
);

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
    fontSize: theme.typography.h5.fontSize,
    paddingTop: 10,
    borderTop: '1px solid #424242',
  },
  imageContainer: {
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  watchBox: {
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    width: '100%',
    height: 0,
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
  },
  description: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    textAlign: 'start',
    fontSize: theme.typography.subtitle2.fontSize,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block' /* Fallback for non-webkit */,
    display: '-webkit-box', //eslint-disable-line
    maxHeight: theme.spacing(8),
    margin: '0 auto',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  watchButton: {
    position: 'absolute',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`,
    fontSize: theme.typography.h6.fontSize,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  viewMoreContainer: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  isFreeTag: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: '#477410',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.h6.fontSize,
  },
  unlockIcon: {
    width: 20,
    height: 20,
    margin: '0px 4px 0px -4px',
  },
}));
