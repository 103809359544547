import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import { Alert, AlertTitle } from '@material-ui/lab';
import { openPopUp } from 'global';

const useStyles = makeStyles(() => ({
  jpContainer: {
    background: '#222',
    borderRadius: 8,
  },
  jpTypeSingle: {
    display: 'inline-block',
    padding: 5,
    borderRadius: 5,
    width: '100%',
  },
  radioReactPlayer: {
    outline: 'none',
    '& audio, & video': { outline: 'none' },
  },
  h: {
    color: '#eee',
    fontWeight: 'bold',
  },
  errorBox: {
    '& .MuiAlert-message': {
      color: 'rgb(250, 179, 174)',
    },
  },
}));

const buttonStyles = {
  color: 'white',
  fontWeight: 'bold',
  marginRight: 16,
  marginBottom: 9,
  padding: '6px 16px',
  minWidth: '80px',
};

export const PlayerRadio = ({ station }) => {
  const currentStream = station.streams[0];
  const [state, setState] = useState({
    url: currentStream.stream_url,
    playlist_url: currentStream.playlist_url,
    bitrate: currentStream.bitrate,
    streema_id: currentStream.streema_id,
    is_external: currentStream.is_external,
    playing: true,
    controls: true,
    height: '55px',
    isError: false,
  });

  const classes = useStyles();

  const onListenNowClick = (e) => {
    openPopUp(state.url, e);
  };

  const onStreamChange = (stream) => {
    setState({
      url: stream.stream_url,
      playlist_url: stream.playlist_url,
      bitrate: stream.bitrate,
      streema_id: stream.streema_id,
      is_external: stream.is_external,
      playing: true,
      controls: true,
      height: '55px',
      isError: false,
    });
  };

  const handleError = () => {
    setState({
      ...state,
      height: '0px',
      isError: true,
      playing: false,
      controls: false,
    });
  };

  const render = () => {
    let streamPart;
    if (state.is_external) {
      streamPart = (
        <Box pt={1} pl={1}>
          <Button
            style={buttonStyles}
            size="large"
            variant="contained"
            color="primary"
            onClick={onListenNowClick}
            startIcon={<LaunchIcon />}
          >
            Listen Now
          </Button>
        </Box>
      );
    } else {
      streamPart = (
        <Box className={classes.jpTypeSingle}>
          <Box pt={1} pb={2}>
            <Typography variant="body1" className={classes.h}>
              {station.name}
            </Typography>
          </Box>
          <ReactPlayer
            className={classes.radioReactPlayer}
            width="100%"
            height={state.height}
            url={state.url}
            playing={state.playing}
            controls={state.controls}
            onError={handleError}
          />
        </Box>
      );
    }

    return (
      <>
        {(station.frequency || state.bitrate) && (
          <Box>
            <span>{station.frequency} </span> &nbsp;
            <span>{state.bitrate}Kbps</span>
          </Box>
        )}
        <br />
        <Box id="jp_container_1" className={classes.jpContainer} p={2}>
          {!state.isError && streamPart}
          {state.isError && (
            <Box className={classes.errorBox} pl={1} pr={1}>
              <Box pt={1} pb={1}>
                <Typography variant="body1" className={classes.h}>
                  {station.name}
                </Typography>
              </Box>
              <Alert variant="outlined" severity="error">
                <AlertTitle>Error</AlertTitle>
                Some errors has been occurred. Please try another link.
              </Alert>
            </Box>
          )}
          <Box p={1} mt={1}>
            {station?.streams && // eslint-disable-line
              station.streams.mapWithKey((item, index) => {
                if (state.streema_id === item.streema_id) {
                  return (
                    <Button
                      key={item.key}
                      size="small"
                      variant="contained"
                      style={{ background: 'rgba(0,0,0,0)', ...buttonStyles }}
                      disabled
                    >
                      Link {index + 1}
                    </Button>
                  );
                }
                return (
                  <Button
                    key={item.key}
                    style={buttonStyles}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      onStreamChange(item);
                    }}
                  >
                    Link {index + 1}
                  </Button>
                );
              })}
          </Box>
        </Box>
      </>
    );
  };

  return render();
};
