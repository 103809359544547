import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box/Box';
import Link from '@material-ui/core/Link/Link';
import { makeStyles } from '@material-ui/core/styles';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { LoadingSpinner } from '../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  itemBox: {
    maxWidth: theme.spacing(30),
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  imageBox: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px dashed  ${theme.palette.primary.main}`,
    },
  },
  titleBox: {
    width: '100%',
    textAlign: 'center',
  },
  linkItem: {
    textDecoration: 'none !important',
    color: 'white',
  },
}));

export const PlayList = ({
  epgData,
  packageSlug,
  categorySlug,
  channelSlug,
}) => {
  const [videoItems, setVideoItems] = React.useState([]);
  const classes = useStyles();

  const setVideos = useCallback(() => {
    const programs = [];
    if (epgData && epgData.programs && epgData.programs.length > 0) {
      epgData.programs.forEach((item) => {
        if (item.playlist) {
          item.playlist.forEach((it) => programs.push(it));
        }
      });
    }

    setVideoItems(programs);
  }, [epgData]);

  React.useEffect(() => {
    setVideos();
  }, [setVideos]);

  const cSettings = {
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <Box width="100%" height="400px">
      {epgData ? (
        <Slider {...cSettings}>
          {videoItems.mapWithKey((item) => (
            <Box key={item.key} className={classes.itemBox}>
              <Link
                component={RouterLink}
                to={`/channels/${packageSlug}/${categorySlug}/${channelSlug}/ondemand/${item.uuid}/`}
                className={classes.linkItem}
              >
                <img src={item.thumbnail} className={classes.imageBox} alt="" />
                <Box className={classes.titleBox}>{item.name}</Box>
              </Link>
            </Box>
          ))}
        </Slider>
      ) : (
        <Box
          width={1}
          justifyContent="center"
          alignItems="center"
          height="40vh"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      )}
    </Box>
  );
};
