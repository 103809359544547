import React from 'react';
import { Button, Typography, Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';

import { HREF, truncateByLength, openPopUp } from 'global';

const useStyles = makeStyles(() => ({
  radioThumbnail: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    paddingTop: 0,
  },
  listPageCard: {
    textAlign: 'center',
    border: 'none !important',
    display: 'block',
    paddingTop: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
    '&:hover $listPageCardBack': { top: 0 },
  },
  listPageCardBack: {
    bottom: 'nset',
    color: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '-100%',
    left: '0',
    backgroundColor: 'rgba(0,0,0,0.85)',
    transition: '.5s ease all',
    zIndex: '11',
    overflow: 'hidden',
    padding: '0 10px',
  },
  listPageCardBackContain: {
    marginTop: '50%',
    transform: 'translateY(-50%)',
    '& > p': { lineHeight: '1.2rem' },
    '& > button': { marginTop: 10 },
  },
}));

export const ItemRadio = ({
  item,
  genre,
  radio_genre_slug: radioGenreSlug,
  noHoverBox,
}) => {
  const classes = useStyles();
  const href = genre
    ? HREF.RADIO.STATION_LIST.replace('{0}', item.slug)
    : HREF.RADIO.STATION_INFO.replace('{0}', radioGenreSlug).replace(
        '{1}',
        item.slug,
      );

  return (
    <Box className={classes.radioThumbnail}>
      <Link
        className={classes.listPageCard}
        to={href}
        style={{ backgroundImage: `url('${item.image}')` }}
      >
        {!noHoverBox && (
          <Box className={classes.listPageCardBack}>
            <Box className={classes.listPageCardBackContain}>
              <Typography variant="body1">{item.name}</Typography>
              {item.description && (
                <Typography>
                  {truncateByLength(item.description, 20)}
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Listen
              </Button>
            </Box>
          </Box>
        )}
      </Link>
    </Box>
  );
};

const useStylesService = makeStyles(() => ({
  serviceItem: {
    background: '#1d1c1c',
    boxShadow: 'none',
    color: 'white',
    minHeight: 280,
    '&:hover $overlay': { opacity: 1 },
  },
  overlay: {
    opacity: 0,
    transition: '.5s ease',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100%',
    height: '100%',
    zIndex: 25,
    borderRadius: 10,
  },
  overlayItem: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': { color: '#009dfe' },
    '& svg': { verticalAlign: 'bottom' },
    '& a': {
      textDecoration: 'none',
      color: 'white',
    },
    '& a:hover': { color: '#009dfe' },
  },
  wrap: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: '100%',
    '& > a': {
      flex: '0 0 auto',
      color: 'white',
      fontSize: '.9rem',
      '&:hover': {
        color: '#008ae0',
      },
    },
  },
  serviceTitleLogo: {
    maxHeight: '100%',
    margin: '0 auto',
  },
  serviceOtherImgWrap: {
    width: 150,
    height: 60,
    margin: '0 auto',
  },
  serviceOtherDescription: {
    lineHeight: '1.02rem',
    height: 50,
    overflow: 'hidden',
  },
}));

export const ItemSubscription = ({ service, toggleSubscription }) => {
  const classes = useStylesService();

  const changeSubscription = () => {
    toggleSubscription(service);
  };

  const renderImg = () => (
    <Box
      className={classes.serviceOtherImgWrap}
      justifyContent="center"
      display="flex"
    >
      <img
        src={service.tile_logo ? service.tile_logo : service.image}
        className={classes.serviceTitleLogo}
        alt={service.name}
      />
    </Box>
  );

  const renderOverlay = () => (
    <Box className={classes.overlay}>
      <Box className={classes.wrap}>
        {service.subscribed ? (
          <Box
            mb={1}
            style={{ color: '#dc3545' }}
            className={classes.overlayItem}
            fontWeight="fontWeightBold"
            onClick={() => changeSubscription(false)}
          >
            <RemoveCircleIcon /> Remove from my services
          </Box>
        ) : (
          <Box
            mb={1}
            style={{ color: '#009dfe' }}
            className={classes.overlayItem}
            fontWeight="fontWeightBold"
            onClick={() => changeSubscription(true)}
          >
            <AddCircleIcon /> Add to my services
          </Box>
        )}
        <Box
          mb={1}
          className={classes.overlayItem}
          fontWeight="fontWeightBold"
          href={'#'}
          onClick={(e) => openPopUp(service.button_url, e)}
        >
          <AttachMoneyIcon /> TRY/BUY THIS SERVICE
        </Box>
        <Box mb={1} className={classes.overlayItem} fontWeight="fontWeightBold">
          <Link
            to={HREF.SUBSCRIPTIONS.SERVICE_INFO.replace('{0}', service.slug)}
          >
            <SearchIcon /> MORE INFORMATION
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Card className={classes.serviceItem}>
      <Box p={3} style={{ height: 280 }} position="relative">
        {renderImg()}

        <Box
          mt={2}
          fontSize="h5.fontSize"
          fontWeight="fontWeightBold"
          justifyContent="center"
          display="flex"
        >
          {service.name}
        </Box>
        <Box
          mt={1}
          className="service-other-amount"
          justifyContent="center"
          display="flex"
        >
          {service.service_amount}
        </Box>

        <Box mt={2}>
          <Typography align="center">
            {truncateByLength(service.description, 48)}
          </Typography>
        </Box>

        {renderOverlay()}
      </Box>
    </Card>
  );
};

const useStylesServiceProfile = makeStyles((theme) => ({
  item: {
    background: '#1d1c1c',
    boxShadow: 'none',
    color: 'white',
    border: (item) =>
      item.subscribed ? `solid 2px ${theme.palette.primary.main}` : 'none',
    cursor: 'pointer',
  },
  imgWrapper: {
    '& img': {
      width: 60,
      height: 60,
      display: 'block',
      borderRadius: 5,
      border: `solid 5px white`,
    },
  },
}));

export const ItemSubscriptionProfile = ({ item, onToggleSub }) => {
  const classes = useStylesServiceProfile(item);

  return (
    <Card onClick={() => onToggleSub(item)} className={classes.item}>
      <Box
        mt={2}
        mb={2}
        className={classes.imgWrapper}
        justifyContent="center"
        display="flex"
      >
        <img src={item.image} alt={item.name} />
      </Box>
      <Box mb={2}>
        <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
          {item.name}
        </Typography>
      </Box>
    </Card>
  );
};
