import moment from 'moment';
import axios from 'axios';
import { fillEmptySpaces } from 'global';
import { setGlobal } from '../redux/reducers/global';

import {
  setFreePackageList,
  setFreePackageCategories,
  setPackageInfo,
  setChannelsData,
  setChannelStream,
  setCatchUpList,
  setDvrStatus,
  setDvrList,
  setDvrStream,
  setChannelEpg,
  setUserPackage,
  setKisweToken,
  setFreePackages,
  setPackageCategories,
  setModalVidgoData,
  setPackageChannels,
} from '../redux/reducers/channels';

export const fetchAllFreePackages = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (dispatch) => {
    axios
      .get(`${window.LIVEAPI_BASE_URL}packages/`, { headers })
      .then((response) => {
        dispatch(
          setFreePackageList({
            freePackageList: response.data.sort(
              (a, b) =>
                (b.type === 'internal' ? 1 : 0) -
                (a.type === 'internal' ? 1 : 0),
            ),
          }),
        );
      });
  };
};

export const fetchCategoriesFreePackage = (packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    axios
      .get(`${window.LIVEAPI_BASE_URL}packages/${packageSlug}/categories/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setFreePackageCategories({
            slug: packageSlug,
            freePackageCategories:
              response.data && response.data.length > 0
                ? response.data.sort((a, b) => a.weight - b.weight)
                : [{ slug: packageSlug, parent: null }],
          }),
        );
      });
  };
};

export const fetchFreePackageInfo = (slug, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    if (slug) {
      return axios
        .get(`${window.LIVEAPI_BASE_URL}packages/${slug}/`, {
          headers,
        })
        .then((response) => {
          dispatch(setPackageInfo({ packageInfo: response.data }));
        });
    }
  };
};

export const fetchFreeChannels = (packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    axios
      .get(`${window.LIVEAPI_BASE_URL}packages/${packageSlug}/channels/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setChannelsData({ slug: packageSlug, listData: response.data }),
        );
      });
  };
};

export const getMyIpAddress = () => {
  delete axios.defaults.headers.common.Authorization;
  return (dispatch) => {
    axios.get('https://jsonip.com').then((response) => {
      dispatch(setGlobal({ field: 'ipAddress', value: response.data }));
    });
  };
};

export const fetchChannelStreamInfo = (
  slug,
  packageSlug,
  sotalToken,
  token,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }

  return (dispatch) => {
    dispatch(setChannelStream({ streamData: undefined }));
    axios
      .get(
        `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/channels/${slug}/streams/`,
        { headers },
      )
      .then((response) => {
        dispatch(setChannelStream({ streamData: response.data }));
      });
  };
};

export const fetchCatchUpData = (slug, packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    axios
      .get(
        `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/epgs/${slug}/catchup/`,
        { headers },
      )
      .then((response) => {
        dispatch(setCatchUpList({ slug, catchUpData: response.data }));
      })
      .catch(() => {
        dispatch(setCatchUpList({ slug, catchUpData: [] }));
      });
  };
};

export const fetchDVRStatus = (packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }

  return (dispatch) => {
    axios
      .get(`${window.LIVEAPI_BASE_URL}packages/${packageSlug}/dvr_status/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setDvrStatus({
            dvrStatus: response.data,
          }),
        );
      })
      .catch((error) => {
        console.log('record error', error);
      });
  };
};

export const recordProgram = (epgSlug, packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }

  return (dispatch) => {
    axios
      .get(
        `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/epgs/${epgSlug}/record/`,
        { headers },
      )
      .then(() => dispatch(fetchDVRList(packageSlug, sotalToken, token)))
      .catch((error) => {
        console.log('record error', error);
      });
  };
};

export const removeDVRTask = (dvrSlug, packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    axios
      .get(
        `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/dvrs/${dvrSlug}/remove/`,
        { headers },
      )
      .then(() => dispatch(fetchDVRList(packageSlug, sotalToken, token)))
      .catch((error) => {
        console.log('remove dvr task error', error);
      });
  };
};

export const fetchDVRList = (packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    dispatch(fetchDVRStatus(packageSlug, sotalToken, token));
    axios
      .get(`${window.LIVEAPI_BASE_URL}packages/${packageSlug}/dvrs/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setDvrList({
            dvrList: response.data.sort((a, b) =>
              moment(b.epg.end_time).diff(moment(a.epg.end_time)),
            ),
          }),
        );
      })
      .catch(() => {
        dispatch(
          setDvrList({
            dvrList: [],
          }),
        );
      });
  };
};

export const fetchDVRStream = (dvrSlug, packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  return (dispatch) => {
    axios
      .get(
        `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/dvrs/${dvrSlug}/stream/`,
        { headers },
      )
      .then((response) => {
        dispatch(
          setDvrStream({
            slug: dvrSlug,
            dvrStream: response.data,
          }),
        );
      })
      .catch(() => {
        dispatch(
          setDvrStream({
            slug: dvrSlug,
            dvrStream: [],
          }),
        );
      });
  };
};

export const batchCatchUpEpgData = (slugs, packageSlug, sotalToken, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  if (sotalToken) {
    headers['sotalcloud-token'] = sotalToken;
  }
  let url = `${window.LIVEAPI_BASE_URL}packages/${packageSlug}/epgs/?`;
  slugs.forEach((slug, index) => {
    url += `slug=${slug}`;
    if (index < slugs.length - 1) {
      url += '&';
    }
  });

  return (dispatch) => {
    axios
      .get(url, { headers })
      .then((response) => {
        slugs.forEach((slug) => {
          const result = response.data.find((item) => item.slug === slug);
          if (result && result.epg_programs) {
            const classifedPrograms = result.epg_programs.map(
              (program, index) => {
                const d = {};
                d.start_at = program.start_time;
                d.end_at = program.end_time;
                d.duration = moment
                  .duration(program.duration, 'second')
                  .toISOString();
                d.name = program.title;
                d.index = index;
                d.uuid = `id_${d.duration}_${program.title.replace(' ', '_')}`;
                d.description = program.description;
                d.subtitle = program.subtitle;
                d.catchup = program.catchup;
                d.dvr = program.dvr;
                d.id = program.id;
                d.slug = program.slug;
                d.thumbnail = program.thumbnail;
                return d;
              },
            );

            dispatch(
              setChannelEpg({
                slug,
                epgData: fillEmptySpaces(classifedPrograms),
              }),
            );
          }
        });
      })
      .catch(() => {
        slugs.forEach((slug) => {
          dispatch(
            setChannelEpg({
              slug,
              epgData: [],
            }),
          );
        });
      });
  };
};

export const fetchProgramData = (
  slug,
  areaId,
  kisweToken,
  reset = false,
  apiCall = true,
  isVidgo = false,
) => {
  delete axios.defaults.headers.common.Authorization;
  return (dispatch) => {
    if (reset) {
      dispatch(setChannelEpg(slug, {}, true));
    }
    if (apiCall) {
      const url =
        areaId && kisweToken ? window.API_PREMIUM_ACTIVE : window.API_PREMIUM;
      axios
        .get(
          `${
            isVidgo ? url : window.LIVEAPI_BASE_URL
          }channels/${slug}/programs/?r=${new Date().getTime()}`,
          {
            headers:
              isVidgo && areaId && kisweToken
                ? {
                    areaid: areaId, // eslint-disable-line
                    kiswe_token: kisweToken, // eslint-disable-line
                  } // eslint-disable-line
                : {},
          },
        )
        .then((response) => {
          let { data } = response;
          const now = moment().subtract(0, 'milliseconds').utcOffset(0);
          const started = moment(data.started).utcOffset(0);
          let duration = 0;
          data.programs.forEach((program) => {
            duration += moment.duration(program.duration).asMilliseconds();
          });
          let playedTimes = (now - started) / duration;
          playedTimes = Math.floor(playedTimes);
          let lastTime = moment(started).add(
            duration * playedTimes,
            'milliseconds',
          );
          let calc_duration = 0; // eslint-disable-line camelcase
          data = {
            ...data,
            programs: data.programs.map((item, index) => {
              calc_duration += moment.duration(item.duration).asMilliseconds(); // eslint-disable-line camelcase
              const start_at = moment(lastTime).utcOffset(0).toISOString(); // eslint-disable-line camelcase
              // eslint-disable-next-line camelcase
              const end_at = moment(lastTime)
                .utcOffset(0)
                .add(
                  moment.duration(item.duration).asMilliseconds(),
                  'milliseconds',
                )
                .toISOString();
              lastTime = end_at; // eslint-disable-line camelcase
              let videoLastTime = start_at; // eslint-disable-line camelcase
              return {
                ...item,
                start_at,
                end_at,
                index,
                playlist: item.playlist.map((video) => {
                  // eslint-disable-next-line
                  const pstart_at = moment(videoLastTime)
                    .utcOffset(0)
                    .toISOString();
                  // eslint-disable-next-line
                  const pduration = moment
                    .duration(video.play_till)
                    .subtract(moment.duration(video.play_from));
                  // eslint-disable-next-line
                  const pend_at = moment(videoLastTime)
                    .utcOffset(0)
                    .add(pduration.asMilliseconds(), 'milliseconds')
                    .toISOString();
                  // eslint-disable-next-line camelcase
                  videoLastTime = pend_at;
                  return {
                    ...video,
                    start_at: pstart_at,
                    end_at: pend_at,
                    duration: moment.duration(pduration).toISOString(),
                  };
                }),
              };
            }),
            duration: moment.duration(calc_duration).toISOString(), // eslint-disable-line camelcase
          };

          dispatch(
            setChannelEpg({
              slug,
              epgData: data,
            }),
          );
        })
        .catch(() => {
          dispatch(
            setChannelEpg({
              slug,
              epgData: [],
            }),
          );
        });
    } else {
      dispatch(
        setChannelEpg({
          slug,
          epgData: [],
        }),
      );
    }
  };
};

// for premium
export const getUserSubscriptionPackage = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) =>
    axios
      .get(`${window.LIVEAPI_BASE_URL}user_package/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setUserPackage({
            data: response.data,
          }),
        );
      });
};

// for vidgo
export const vidgoLogin = (aireaId, kisweToken, force, token) => {
  if (!aireaId || !kisweToken || force) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return (dispatch) => {
      axios
        .get(`${window.API_SERVICES_DOMAIN}/vidgo/api/login/`, {
          headers,
        })
        .then((response) => {
          if (
            response.data.status === 'ok' &&
            response.data &&
            response.data.data.username &&
            response.data.data.id &&
            response.data.data.domain
          ) {
            return dispatch(
              vidgoAuthLogin(
                response.data.data.domain,
                response.data.data.username,
                response.data.data.id,
                // token,
              ),
            );
          }
        });
    };
  }
};

export const vidgoAuthLogin = (domain, username, id) => {
  delete axios.defaults.headers.common.Authorization;
  const vidgoHeaders = {
    Accept:
      'application/json; app=vidgo; version=1.11.0; platform=web; os_version=1.2.3;',
    // Authorization: 'Bearer ' + token,
  };
  const bodyRequest = {
    email: username,
    password: id,
    devicetype: 'roku',
    deviceid: '0000',
  };
  return (dispatch) => {
    axios
      .post(`${domain}/thor/auth/vidgo_login/`, bodyRequest, {
        headers: vidgoHeaders,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setKisweToken({
              areaId: response.data.session_data.areaid,
              kisweToken: response.data.thor_token,
            }),
          );
        }
      });
  };
};

export const fetchAllPacakges = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return (dispatch) => {
    axios
      .get(`${window.API_SERVICES_DOMAIN}/vidgo/api/packages/all/`, {
        headers,
      })
      .then((response) => {
        dispatch(
          setFreePackages({
            packageList: response.data.packages,
          }),
        );
      });
  };
};

export const fetchCategoriesPackage = (packageSlug) => {
  delete axios.defaults.headers.common.Authorization;
  return (dispatch) => {
    const url = `${
      window.API_PREMIUM
    }packages/${packageSlug}/categories/?r=${new Date().getTime()}`;
    return axios.get(url).then(
      (data) => {
        dispatch(setPackageCategories({ slug: packageSlug, data: data.data }));
      },
      () => {
        dispatch(setPackageCategories({ slug: packageSlug, data: [] }));
      },
    );
  };
};

export const fetchPackageInfo = (slug) => {
  delete axios.defaults.headers.common.Authorization;
  return (dispatch) => {
    if (slug) {
      const url = `${window.API_PREMIUM}packages/${slug}/`;
      return axios.get(url).then((response) => {
        dispatch(setPackageInfo({ packageInfo: response.data }));
      });
    }
  };
};

export const fetchModalVidgoData = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (dispatch) =>
    axios
      .get(
        `${window.API_SERVICES_DOMAIN}/vidgo/api/custom-text/${window.BRAN_SLUG}/`,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(setModalVidgoData({ data: response.data.data }));
      });
};

export const fetchPackageChannels = (slug, isPremiumActive) => {
  delete axios.defaults.headers.common.Authorization;
  return (dispatch) => {
    dispatch(setPackageChannels({ slug, listData: undefined }));
    axios
      .get(
        `${
          isPremiumActive ? window.API_PREMIUM_ACTIVE : window.API_PREMIUM
        }packages/${slug}/channels/`,
      )
      .then((response) => {
        dispatch(setPackageChannels({ slug, listData: response.data }));
      });
  };
};

export const fetchVidgoForms = (slug, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${window.API_SERVICES_DOMAIN}/vidgo/api/forms/${slug}/`, {
      headers,
    })
    .then((response) => response.data.form)
    .catch((error) => error);
};

export const submitOrderForm = (params, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(
      `${window.API_SERVICES_DOMAIN}/members/api/v0/stvplus/order/`,
      params,
      { headers },
    )
    .then((response) => response.data)
    .catch((error) => error);
};
