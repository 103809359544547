import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'music',
  initialState: {
    radioStations: [],
    stations: [],
    stationListData: [],
    stationInfo: [],
  },
  reducers: {
    onSetMusic: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export default slice.reducer;

const { onSetMusic } = slice.actions;
export const setMusic = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetMusic({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
