import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'genres',
  initialState: {
    radio: [],
    movie: [],
    show: [],
  },
  reducers: {
    onSetGenres: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export default slice.reducer;

const { onSetGenres } = slice.actions;
export const setGenres = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetGenres({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
