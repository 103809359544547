import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, makeStyles, TextField, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDebouncedCallback } from 'use-debounce';
import * as global from 'global';
import { CustomDialog } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  searchTextField: {
    backgroundColor: 'rgba(60, 67, 73, 0.2)',
    borderWidth: 1,
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'grey',
        boxShadow: '0px 0px 5px 1px grey',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
        boxShadow: '0px 0px 5px 1px grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
        boxShadow: '0px 0px 5px 1px grey',
        borderWidth: 1,
      },
    },
  },
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  searchButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(1.5),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  searchButtonModal: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer',
  },
  paper: {
    backgroundColor: '#2B2B2B',
    borderRadius: 0,
    color: 'white',
  },
  dialog: {
    position: 'absolute !important',
    transform: 'translateX(-50%)',
    top: 50,
  },
}));

const CustomPaper = (props) => {
  const classes = useStyles();

  return <Box {...props} className={classes.paper} />;
};

export const SearchBox = ({
  options = [],
  fetchSuggestions,
  fetchContents,
}) => {
  const classes = useStyles();
  const [visibleSearchIcon, setVisibleSearchIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const [debouncedCallback] = useDebouncedCallback((value) => {
    if (fetchSuggestions && value) fetchSuggestions(value);
  }, 500);

  const getAutocomplete = () => (
    <Autocomplete
      freeSolo
      forcePopupIcon={false}
      closeIcon={<Box component={CloseIcon} color="white" fontSize="large" />}
      options={options.map((option) => option.keyword)}
      PaperComponent={CustomPaper}
      onChange={(e, val) => {
        if (fetchContents && val) fetchContents(val);
      }}
      onInputChange={(e, val) => {
        setSearchText(val);
        setVisibleSearchIcon(!val);
        debouncedCallback(val);
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          className={classes.searchTextField}
          {...params}
          placeholder="Search TV Shows, Movies & More..."
          margin="dense"
          value={searchText}
          variant="outlined"
        />
      )}
    />
  );

  const renderModalSearch = () => (
    <CustomDialog
      open={showModal}
      maxWidth={'sm'}
      handleClose={() => setShowModal(false)}
      classes={{ paper: classes.dialog }}
    >
      {getAutocomplete()}
    </CustomDialog>
  );

  return (
    <Box className={classes.root}>
      <Hidden mdDown>
        <Box position="relative">
          {getAutocomplete()}
          {visibleSearchIcon && (
            <img
              alt="searchIcon"
              src={global.staticUrl('images/search-icon-header.png')}
              className={classes.searchButton}
            />
          )}
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box display="flex" justifyContent="center" p={1}>
          <img
            alt="searchIcon"
            aria-hidden="true"
            onClick={() => setShowModal(true)}
            src={global.staticUrl('images/search-icon-header.png')}
            className={classes.searchButtonModal}
          />
        </Box>
        {renderModalSearch()}
      </Hidden>
    </Box>
  );
};
