import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionList: [],
    subscriptionHeader: {},
    subscriptionManagerPage: null,
    toggleSubscriptionDetail: {},
    userSyncedTitles: {},
  },
  reducers: {
    onSetSubscription: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    toggleSubscription: (state, action) => {
      const { slug, subscribed, isDetail } = action.payload;
      if (isDetail) {
        state.service = {
          ...state.service,
          subscribed:
            state.service.slug === slug ? subscribed : state.service.subscribed,
          recommended_services: state.service.recommended_services.map((s) =>
            s.slug === slug ? { ...s, subscribed } : s,
          ),
        };
      } else {
        state.subscriptionList = state.subscriptionList.map((s) =>
          s.slug === slug ? { ...s, subscribed } : s,
        );
      }
    },
  },
});

export default subscriptionSlice.reducer;

const { onSetSubscription, toggleSubscription } = subscriptionSlice.actions;
export const setSubscription = ({ field, value }) => async (dispatch) => {
  try {
    dispatch(onSetSubscription({ field, value }));
  } catch (e) {
    return console.error(e.message);
  }
};
export const setToggleSubscription = (data) => async (dispatch) =>
  dispatch(toggleSubscription(data));
